<template>
  <el-menu-item
    :index="menu.path"
    :route="{path: menu.path}"
  >
    <i :class="menu.icon" />
    <span slot="title">{{ menu.title }}</span>
  </el-menu-item>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
