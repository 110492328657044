import { Message } from 'element-ui'

import { createYLAjax } from '@cmvalue/dc-ajax'

if (process.env.VUE_APP_ENV === 'development') {
  const devToken = 'ZXlKelo0V1ZrSWpvaU1qWTVJaXdpWVd4bklqb2lTRk0xTVRJaUxDSjZhWEFpT2lKSFdrbFFJbjAuSDRzSUFBQUFjQUFBQUFIMVN5MDdDUUJUOWx3bExJQzJVVjNkS2lVRmVUU01tSmsxTW1WNmdXbWFhNlJRQncwWmozTGwxNmNLNDk2dkF6M0E2TFM5UlozWHZPV2Z1NDh6Y296QWFJQjNkMjhqQm1FYUVkeWxwekFLUGdXc2pmZWo0SVdUM3VUYkZ0NGRVeE1lVWVRdHdMUWhweERBSVVwWXotYjRzQ09yVWpUa2JuWmltcHRnb3ZYd0k2eGVXVWk1dXlSNGJ0V0NlMEVaSGx4SThtVjdQZThaWkNWdDlLUng0aTB0Z1VpUnpIREZ4THhrRGI2cWIxYW9raVROSmdlYnE0WDM5LXJSLWVmNzZmRnk5ZmRob0tYaVg0aTZWZ3F0MkoyZFlPU1U5YW5KZjhIM1BsWUtqUVNiT0RkMjF6akFZU2wwbWYyeFNmak5rM0RQZ2V5M054cy1XRE1JbUdRUHplRkk3WFpOUlA5bkVzTGI1MXN5ZGpkUFVHcldpMWtwYVNTbXJTVk1NdmgtTUtZRTk0eWdMMGpsYTV3a2lfZ0VRN29sWF9QVmpBSEVHdmtRNGkyTEFweU5LMG0yU2pscFpLOGhhZ1JPR2Q1VDk0VjBVQW12LXcyM2ZUVXlQc3ZGVzR0dXFJdUp6RVp6V1RSRUNtWXFZUThqalpCWWdYYTBVRkZXcjFWUkZBaE9rbHhXdEtzM05DbC1IQXRDS0Jaa3Z2d0g3bnI3aURBTUFBQS5OVi12ZFQ1ei1BUlNNS3hhNzBhUnBUM3luVm9IVU42NkR6MEI3MkhPZWFtY1g4RDk1Nm04SVJzS0ZYdjVYazBsVk5fNkJLV1oydHZlWDNXZktsTTlwMUE='
  localStorage.setItem('x-b-token', devToken)
}

/**
 * TODO:
 * 以下配置根据项目具体需求修改
 * 具体配置项可点击 http://npm.cmvalue.net/-/web/detail/@cmvalue/dc-ajax 查看。
 */

const YLHttp = createYLAjax({
  requestOptions: {
    apiUrl: '',
    isTransformResponse: false,
    bizOptions: {
      platform: 'pc',
      orgCode: 'CMV',
      isMiddlePlatform: true,
      appCode: process.env.VUE_APP_ENV === 'production' ? 'APP22' : 'APP40'
    },
    env: process.env.VUE_APP_ENV === 'production' ? 'prd' : 'test'
  },
  customFn: {
    // TODO: 传入 Vant 的 Toast 或者 Element Ui 的 Message
    message: Message
    // login: (pa) => {
    //   console.log('登录回调', pa)
    // }
  }
})

export default YLHttp
