import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
// 微信分享相关，需要时开启
import { invokeMethod, setShareConfig } from '@/utils/share'

Vue.use(VueRouter)

export const scrollBehavior = (to, from, savedPosition) => {
  // needSavePaths中填写需要滚动的路由
  const needSavePaths = []
  const needSave = needSavePaths.indexOf(to.name) !== -1
  if (savedPosition && needSave) {
    return savedPosition
  } else if (!needSave) {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: '/survey-bsp-admin',
  routes,
  scrollBehavior
})

// after each
router.afterEach(to => {
  /* eslint-disable */
  // 微信分享相关，需要时开启
  if (to.meta && to.meta.share) {
    const basicConfig = {
      title: 'xxxxxxx',
      desc: 'xxxxxxxx',
      link: `xxxxxxxx`,
      imgUrl: 'xxxxxxxx'
    }
    setShareConfig(basicConfig)
  } else {
    invokeMethod('hideAllNonBaseMenuItem')
  }
})


/**
 * 获取去除 ut 和 miniToken 的 url 地址
 * @param {*} to 
 */
 function getUrlWithoutToken (to) {
  let url = ''
  if (to.query.ut || to.query.miniToken ) {
    delete to.query.ut
    delete to.query.miniToken
    // 重新拼接地址
    url = to.path + '?'
    for (const key in to.query) {
      url += `${key}=${to.query[key]}&`
    }
    url = url.substring(0, url.length - 1) // 去除最后以为的 "?" 或者 "&"
    return url
  }
}

/**
 * 更新 localstorage 的token
 * @param {*} to 
 */

 function setUserToken (newToken) {
  if (newToken !== window.localStorage.getItem('x-b-token')) {
    window.localStorage.setItem('x-b-token', newToken)
  }
}

// before each hooks
router.beforeEach((to, from, next) => {
  const tokenParamsInUrl = to.query.ut || to.query.miniToken
  if (tokenParamsInUrl) {
    setUserToken(tokenParamsInUrl)
    next({
      replace: true,
      path: getUrlWithoutToken(to)
    })
  } else {
    next()
  }
})

export default router
