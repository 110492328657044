<template>
  <!-- eslint-disable vue/no-v-html -->
  <el-dialog
    title="提示"
    :visible.sync="show"
    width="720px"
  >
    <div class="stats_dialog">
      <div class="stats_dialog-title">
        尊敬的{{ username }}医生，您正在操作用户数据的下载。为了确保数据信息的安全，并且符合国家相关法律的要求，请您注意：
      </div>
      <div
        style="line-height:25px;"
        v-html="TYPEMAP[cType] || TYPEMAP.know"
      />
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="show = false">取 消</el-button>
      <el-button
        type="primary"
        @click="confirm"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      show: false,
      cType: 'know',
      TYPEMAP: {
        // 知情同意
        know: `
          1. 您确保本次操作由您本人执行；<br/>
          2. 您确保此次数据下载是仅限您自身基于合法和合理的科研和教学目的；<br/>
          3. 您已经知晓数据安全的重要性，并确认此次下载的必要性；<br/>
          4. 您有能力且会尽您所能确保所下载数据的存储、传输、使用安全；<br/>
          5. 您已充分了解并同意本次所导出的数据在导出后所产生的任何风险和责任都将与咏柳科技及其关联机构无关；<br/>
          6. 您愿意配合咏柳科技为打造安全、可信的平台环境所做的一切努力。<br/>
          《数据安全法》第七条明确「国家保护个人、组织与数据有关的权益，鼓励数据依法合理有效利用， 保障数据依法有序自由流动，促进以数据为关键要素的数字经济发展。」，同时第二条也明确「损害国家、公民合法权益的行为，依法追究法律责任。」<br/>
          数据安全不仅是国家安全战略，也是咏柳科技的企业责任，我们在建立完善的数据安全保护体系的同时， 也谨慎对待我们每一次的产品功能开发，助您科学高效管理临床、科研任务的同时，我们再次提醒您知晓您对数据处理行为所要承担的责任和义务！
          <p>【知晓并确认上述内容】</p>
        `,
        que: `
          1. 您确保本次操作由您本人执行；<br/>
          2. 您确保此次数据下载是仅限您自身基于合法和合理的科研和教学目的；<br/>
          3. 您已经知晓数据安全的重要性，并确认此次下载的必要性；<br/>
          4. 您有能力且会尽您所能确保所下载数据的存储、传输、使用安全；<br/>
          5. 您已充分了解并同意本次所导出的数据在导出后所产生的任何风险和责任都将与咏柳科技及其关联机构无关；<br/>
          6. 您愿意配合咏柳科技为打造安全、可信的平台环境所做的一切努力。<br/>
          《数据安全法》第七条明确「国家保护个人、组织与数据有关的权益，鼓励数据依法合理有效利用， 保障数据依法有序自由流动，促进以数据为关键要素的数字经济发展。」，同时第二条也明确「损害国家、公民合法权益的行为，依法追究法律责任。」<br/>
          数据安全不仅是国家安全战略，也是咏柳科技的企业责任，我们在建立完善的数据安全保护体系的同时， 也谨慎对待我们每一次的产品功能开发，助您科学高效管理临床、科研任务的同时，我们再次提醒您知晓您对数据处理行为所要承担的责任和义务！
          <p>【知晓并确认上述内容】</p>
        `
      }
    }
  },
  computed: {
    ...mapState('app', {
      username: state => state.admin.username
    })
  },
  methods: {
    confirm () {
      this.show = false
      this.$emit('confirm', this.cType)
    },
    setData (data) {
      if (data.ctype) {
        // this.username = data.username
        this.cType = data.ctype
        this.show = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.stats_dialog{
  &-title{
    margin-bottom: 15px;
    line-height: 25px;
    font-size: 18px;
  }
}
</style>
