// 以下是使用例子
// <button v-per="['UC1', 'UC2']">按钮</button>
// <button v-per="'UC1'">按钮</button>
export default {
  install (Vue) {
    Vue.directive('per', {
      inserted: async function (el, binding) {
        if (process.env.NODE_ENV === 'development') {
          return
        }
        const val = binding.value
        let hasPre = false
        let perList = window.localStorage.getItem('per')
        try {
          perList = JSON.parse(perList)
        } catch (error) {
          perList = ''
        }
        if (!perList) {
          return
        }

        if (typeof (val) === 'string') {
          hasPre = perList.includes(val)
        } else if (val.constructor === Array) {
          hasPre = true
          val.forEach(item => {
            if (!perList.includes(item)) {
              hasPre = false
            }
          })
        }
        if (!hasPre) {
          el.remove()
        }
      }
    })
  }
}
