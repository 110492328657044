export function generateSingleHistogramChartOptions ({ text, subtext, name, xData, data }) {
  return {
    // 左上角出现的标题

    title: {
      text,
      subtext
    },
    grid: {
      left: 50,
      right: 50,
      bottom: 135
    },
    toolbox: {
      show: true,
      feature: {
        // dataView: { show: true, readOnly: false },
        mark: { show: true },
        magicType: { show: true, type: ['line', 'bar'] }
        // saveAsImage: { show: true }
      }
    },
    tooltip: {},
    // 图例
    legend: {
      data: [name]
    },
    // dataZoom: [
    //   {
    //     type: 'slider'
    //   }
    // ],
    // x轴
    xAxis: {
      type: 'category',
      name: '(日期)',
      boundaryGap: false,
      data: xData
    },
    // y轴
    yAxis: {
      name: '(数量)'
    },
    // 系列
    series: [
      {
        name: name,
        type: 'line',
        data: data,
        smooth: true,
        // showAllSymbol: true,
        // symbolSize: 0,
        symbol: 'circle',
        showSymbol: true,
        color: '#2080e3', // 改变折线点的颜色
        lineStyle: {
          color: '#2080e3' // 改变折线颜色
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0, color: 'rgba(37,160,248,0.4)' // 0% 处的颜色
            }, {
              offset: 1, color: 'rgba(16,103,238,0.06)' // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }
        }
      }
    ]
  }
}

export function generateDoubleHistogramMergeChartOptions ({ text, subtext, name1, name2, xData, data1, data2 }) {
  return {
    // 左上角出现的标题
    title: {
      text,
      subtext
    },
    grid: {
      left: 50,
      right: 0,
      bottom: 135
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        mark: { show: true },
        // magicType: { show: true, type: ['line', 'bar'] },
        saveAsImage: { show: true }
      }
    },
    tooltip: {},
    // 图例
    legend: {
      data: [name1, name2]
    },
    dataZoom: [
      {
        type: 'slider'
      }
    ],
    // x轴
    xAxis: {
      data: xData,
      axisLabel: {
        // 可以设置为0强制显示所有标签，如果设置为1，表示隔一个标签显示一个标签，如果为3，表示隔3个标签显示一个标签，以此类推
        interval: 0,
        rotate: -90
      }
    },
    // y轴
    yAxis: {},
    // 系列
    series: [
      {
        name: name1,
        type: 'bar',
        data: data1,
        stack: 'days'
      },
      {
        name: name2,
        type: 'bar',
        data: data2,
        stack: 'days'
      }
    ]
  }
}

export function generateDoubleHistogramChartOptions ({ text, subtext, name1, name2, xData, data1, data2 }) {
  return {
    // 左上角出现的标题
    title: {
      text,
      subtext
    },
    grid: {
      left: 50,
      right: 0,
      bottom: 135
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        mark: { show: true },
        saveAsImage: { show: true }
      }
    },
    tooltip: {},
    // 图例
    legend: {
      data: [name1, name2]
    },
    dataZoom: [
      {
        type: 'slider'
      }
    ],
    // x轴
    xAxis: {
      data: xData,
      axisLabel: {
        // 可以设置为0强制显示所有标签，如果设置为1，表示隔一个标签显示一个标签，如果为3，表示隔3个标签显示一个标签，以此类推
        interval: 0,
        rotate: -90
      }
    },
    // y轴
    yAxis: {},
    // 系列
    series: [
      {
        name: name1,
        type: 'bar',
        data: data1
      },
      {
        name: name2,
        type: 'bar',
        data: data2
      }
    ]
  }
}

export function generateCircileChartOtions ({ text, data, name, centerTxt, left, type }) {
  // data 格式 [{name: 'xx', value: 10}]
  const legend = data.map(item => item.name)
  return {
    title: {
      text: text,
      left: left,
      bottom: 0,
      textStyle: {
        fontSize: 14
      }
    },
    color: ['#ee4535', '#2080e3', '#55b837', '#f4cd40', '#FF9F3C', '#853CFF'],
    toolbox: {
      show: true,
      feature: {
        // dataView: { show: true, readOnly: false },
        // mark: { show: true },
        // saveAsImage: { show: true }
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      bottom: 10,
      right: 0,
      data: legend,
      formatter: function (name) {
        const item = data.find(item => item.name === name)
        name = item.name.padEnd(8)
        const num = (item.value + type).padStart(8)
        const prop = (item.percent + '%').padStart(8).padEnd(30)
        return `${name} |${num}${prop}`
      }
    },
    series: [
      {
        name: name,
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        hoverAnimation: false,
        silent: false,
        center: ['18%', '50%'],
        label: {
          normal: {
            show: true,
            position: 'center',
            formatter: function () {
              return centerTxt
            },
            textStyle: {
              color: '#333',
              fontSize: 15
            }
          }

        },
        emphasis: {
          label: {
            show: true,
            fontSize: 15
          }
        },
        labelLine: {
          show: false
        },
        data: data
      }
    ]
  }
}
