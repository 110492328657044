var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data,
            stripe: "",
            "tooltip-effect": "dark",
            "header-cell-style": { background: "#F8F8F8", color: "#333" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: "机构名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.orgName || "/") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "count", label: "问卷数", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "占比", align: "center", prop: "orgName" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.percent + " %") + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }