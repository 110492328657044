var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data,
            stripe: "",
            "tooltip-effect": "dark",
            "header-cell-style": { background: "#F8F8F8", color: "#333" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "doctorId", label: "医生ID", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "doctorName", label: "医生名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "机构", align: "center", prop: "orgName" },
          }),
          _c("el-table-column", {
            attrs: { label: "角色", align: "center", prop: "role" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.role ? _vm.roleMap[scope.row.role] : "--"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createdAt",
              label: "加入时间",
              align: "center",
              width: "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.createdAt || "--") + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              fixed: "right",
              width: "140px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.viewDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        staticStyle: { "margin-left": "12px" },
                        attrs: { title: "确定删除吗？" },
                        on: {
                          confirm: function ($event) {
                            return _vm.deleteItem(scope.row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", type: "text" },
                            slot: "reference",
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }