<template>
  <div class="flex flex-col h-full bisForm">
    <span>检查结果</span>
    <el-form
      ref="bForm"
      label-position="top"
      :model="form"
      :rules="rules"
      class="flex-1"
    >
      <el-form-item
        label="患者皮肤癣菌群面积（BSA）"
        prop="bsa"
      >
        <Uploader
          ref="bsa"
          tip="请上传典型临床图片"
          :size="imgSize"
          :readonly-disabled="disabled"
          @change="imgChange($event, 'bsa')"
        />
      </el-form-item>
      <el-form-item
        label="显微镜直接镜检的结果"
        prop="cmscc"
      >
        <Uploader
          ref="cmscc"
          tip="请上传镜检图片"
          :size="imgSize"
          :readonly-disabled="disabled"
          @change="imgChange($event, 'cmscc')"
        />
      </el-form-item>
      <el-form-item
        label="显微镜荧光镜检的结果"
        prop="fld"
      >
        <Uploader
          ref="fld"
          tip="请上传镜检图片"
          :size="imgSize"
          :readonly-disabled="disabled"
          @change="imgChange($event, 'fld')"
        />
      </el-form-item>
      <el-form-item
        label="是否有对患者的皮肤进行真菌培养？"
        prop="isFungi"
      >
        <el-select
          v-model="form.isFungi"
          :disabled="disabled"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, key) in isFungiOptions"
            :key="key"
            :label="item"
            :value="+key"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="[2].includes(form.isFungi)"
        label="真菌培养的结果是哪种真菌？"
        prop="fungiType"
      >
        <el-select
          v-model="form.fungiType"
          :disabled="disabled"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, key) in fungiTypeOptions"
            :key="key"
            :label="item"
            :value="key"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="[1,2].includes(form.isFungi)"
        label="请上传真菌培养结果的图片"
        prop="fungi"
      >
        <Uploader
          ref="fungi"
          tip="请上传培养图片"
          :size="imgSize"
          :readonly-disabled="disabled"
          @change="imgChange($event, 'fungi')"
        />
      </el-form-item>
      <el-form-item
        label="请上传患者的完整病例"
        prop="cases"
      >
        <Uploader
          ref="cases"
          :size="imgSize"
          :readonly-disabled="disabled"
          @change="imgChange($event, 'cases')"
        />
      </el-form-item>
    </el-form>
    <div
      v-if="haveBtn"
      class="sticky-bottom"
    >
      <el-button
        type="primary"
        size="small"
        style="margin-right: 16px;"
        @click="onSubmit('bForm', 'SAVE')"
      >
        保存
      </el-button>
      <el-popconfirm
        title="确认保存并提交吗?提交后不可修改!"
        @confirm="onSubmit('bForm', 'SUBMIT')"
      >
        <el-button
          slot="reference"
          type="primary"
          size="small"
        >
          <!-- @click="onSubmit" -->
          保存并提交
        </el-button>
      </el-popconfirm>
    </div>
  </div>
  <!-- </ButtonBot> -->
</template>

<script>
import Uploader from '@/components/upload-img/index.vue'
import apiPfjq from '@/api/project/pys-pfjq.js'

export default {
  components: {
    Uploader
  },
  provide () {
    return {
      /**
       * 由于后端区分项目建表,图片上传接口无法统一;
       * 新项目都需要更新接口
       *
      */
      fatherUpload: apiPfjq.uploadImg
    }
  },
  data () {
    const required = { required: true, message: '必填项', trigger: ['blur', 'change'] }

    return {
      imgSize: 3 * 10 * 1024,
      isFungiOptions: Object.freeze({
        0: '未做',
        1: '阴性',
        2: '阳性'
      }),
      fungiTypeOptions: Object.freeze({
        红色毛癣菌: '红色毛癣菌',
        须癣毛癣菌: '须癣毛癣菌',
        断发毛癣菌: '断发毛癣菌',
        紫色毛癣菌: '紫色毛癣菌',
        犬小孢子菌: '犬小孢子菌',
        其他: '其他'
      }),
      form: {
        slug: '',
        isFungi: 0, // 默认设置为0
        fungiType: '',
        fungiTypeOther: '',
        bsa: '',
        cmscc: '',
        fld: '',
        fungi: '',
        cases: ''
      },
      disabled: false,
      haveBtn: false,
      rules: {
        fungiType: [required],
        isFungi: [required],
        bsa: [required],
        fungi: [required],
        cases: [required]
      }
    }
  },
  watch: {
    'form.isFungi' (n, o) { // isFungi影响两个属性
      // eslint-disable-next-line eqeqeq
      if (n == 1) { // 阴性
        this.$set(this.form, 'fungiType', '')
      // eslint-disable-next-line eqeqeq
      } else if (n == 0) { // 未做
        this.$set(this.form, 'fungiType', '')
        this.$set(this.form, 'fungi', '')
      }
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    uploadImg (form) {
      // 上传格式是表单, 跟后端商量好
      console.log('父组件的方法接受', form)
      return apiPfjq.uploadImg(form)
    },
    imgChange (args1, args2) {
      if (args1 && Array.isArray(args1)) {
        this.form[args2] = args1.map(it => it.url)
        this.$refs.bForm.validateField(args2)
      } else {
        this.form[args2] = ''
        this.$refs.bForm.validateField(args2)
      }
      // if (imgList?.length) {
      //   this.form.fileId = imgList[0].id
      //   this.form.sign = imgList[0].url
      // } else {
      //   this.form.fileId = ''
      //   this.form.sign = ''
      // }
      // this.$refs.bForm.validateField('sign')
    },
    setData (data, disabled) {
      console.log('setData', data)
      this.form = { ...this.form, ...data, isFungi: data.isFungi || 0 }
      this.form.slug = data.slug
      this.disabled = disabled
      this.haveBtn = !disabled
      // isFungi判断, 上传组件不一定马上出现; 赋值就会有问题
      this.$nextTick(this.handleUploader)
    },
    handleUploader () {
      Object.keys(this.form).forEach((it, id) => {
        if (Array.isArray(this.form[it])) {
          const arr = this.form[it].map(itt => ({ url: itt, id: itt }))
          // eslint-disable-next-line no-unused-expressions
          this.$refs[it]?.setImages(arr)
        }
      })
    },
    onSubmit (form, type) {
      if (type === 'SUBMIT') {
        this.$refs[form].validate(valid => {
          if (valid) {
            this.postData(type)
          }
        })
      } else {
        this.postData(type)
      }
    },
    postData (type) {
      const data = { ...this.form, type }
      apiPfjq.postImageData(data).then(res => {
        this.$emit('success', type === 'SUBMIT')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bisForm {
  & > span {
    // width: 56px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 22px;
  }
}

.sticky-bottom {
  position: fixed;
  display: flex;
  align-items: center;
  height: 60px;
  bottom: 0px;
  right: 16px;
}
</style>
