import CommonSurveyList from '@/views/common/survey-list'
import CommonSurveyDetail from '@/views/common/stat-data-detail'
import CommonWholeDataStat from '@/views/common/whole-data-detail'

export default [
  {
    path: '/common',
    name: 'CommonSurveyList',
    component: CommonSurveyList,
    async beforeEnter (to, from, next) {
      document.title = '调查列表'
      next()
    }
  },
  {
    path: '/common/survey-detail',
    name: 'CommonSurveyDetail',
    component: CommonSurveyDetail,
    async beforeEnter (to, from, next) {
      document.title = '调查详情'
      next()
    }
  },
  {
    path: '/common/data-stat',
    name: 'CommonWholeDataStat',
    component: CommonWholeDataStat,
    async beforeEnter (to, from, next) {
      document.title = '数据统计'
      next()
    }
  }
]
