<template>
  <div class="stats_wrap">
    <div class="stats_overview">
      <div class="stats_overview_title">
        <span>机构</span>
        <el-select
          v-model="curOrg"
          style="width: 300px;"
          @change="switchCurSearchOrg"
        >
          <el-option
            v-for="item in orgList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </div>
    </div>
    <div class="stat-total">
      <div
        class="overview"
        @click="() => goToSurveyList()"
      >
        <div>
          <span class="title">累计收集问卷</span>
          <span class="sub-title">本周新增 <span class="primary">+{{ totalStat.weekTotal }}</span></span>
        </div>
        <p>
          <span class="primary-text">{{ totalStat.total }}</span><span>份</span>
        </p>
      </div>
      <div
        class="overview"
        @click="() => goToSurveyList(1)"
      >
        <div>
          <span class="title">已完成</span>
          <span class="sub-title">本周新增 <span class="primary">+{{ totalStat.weekComplete }}</span></span>
        </div>
        <p>
          <span class="primary-text">{{ totalStat.complete }}</span><span>份</span>
        </p>
      </div>
      <div
        class="overview"
        @click="() => goToSurveyList(0)"
      >
        <div>
          <span class="title">进行中</span>
          <span class="sub-title">本周新增 <span class="primary">+{{ totalStat.weekIncomplete }}</span></span>
        </div>
        <p>
          <span class="primary-text">{{ totalStat.incomplete }}</span><span>份</span>
        </p>
      </div>
      <div
        class="overview"
        @click="filterStatDataList"
      >
        <div>
          <span class="title">问卷平均完成时长</span>
        </div>

        <p>
          {{ totalStat.averageTime }}
        </p>
      </div>
      <stat-data
        ref="statsTable"
        :org-code="curOrg"
      />
    </div>

    <div class="blank" />

    <div
      v-if="['admin', 'expert'].includes(curRole)"
      class="form-stat-detail"
    >
      <div class="form-stat-detail-title">
        答题情况统计：
      </div>
      <stat-report ref="FormStatsReport" />
    </div>
  </div>
</template>
<script>
import apiCommon from '../../api/common'

import { formatSeconds } from '@/utils/time.js'
import StatData from './components/stat-data.vue'
import StatReport from './components/stat-report.vue'

export default {
  components: {
    StatData,
    StatReport
  },
  data () {
    return {
      totalStat: {
        total: 0,
        weekTotal: 0,
        complete: 0,
        weekComplete: 0,
        incomplete: 0,
        weekIncomplete: 0,
        averageTime: 0
      },
      orgList: {},
      curOrg: '',
      overviewList: [],
      formVersionList: [],
      activeName: 'FormStatsData',
      formCode: '',
      formVersion: '',
      fullscreenLoading: false,
      curRole: ''
    }
  },
  created () {
    this.getUserAuth()
    this.getOrgList()
    this.getStatOverview()
  },
  methods: {
    async getUserAuth () {
      const resp = await apiCommon.getUserRole()
      if (resp.success) {
        const { role } = resp.data
        this.curRole = role
      }
    },
    async getOrgList () {
      const resp = await apiCommon.getOrgList()
      if (resp.success) {
        this.orgList = resp.data
      }
    },
    filterStatDataList () {
      if (this.$refs.FormStatsData) {
        this.$refs.FormStatsData.pageInfo.page = 1
        this.$refs.FormStatsData.setData({
          formVersion: this.formVersion,
          formCode: this.formCode,
          completeStatus: 1
        })
      }
    },
    goToSurveyList (status) {
      this.$router.push({
        name: 'CommonSurveyList',
        query: {
          status,
          org: this.curOrg
        }
      })
    },
    switchCurSearchOrg (curOrgCode) {
      this.curOrg = curOrgCode
      this.$nextTick(() => {
        this.getStatOverview()
        this.$refs.statsTable.fetchTableData()
      })
    },
    async getStatOverview () {
      const params = {
        orgCode: this.curOrg === 'all' ? null : this.curOrg
      }
      const resp = await apiCommon.getSurveyTotalStat(params)
      if (resp.success) {
        this.totalStat = {
          total: resp.data.total,
          weekTotal: resp.data.weekTotal,
          complete: resp.data.complete,
          weekComplete: resp.data.weekComplete,
          incomplete: resp.data.incomplete,
          weekIncomplete: resp.data.weekIncomplete,
          averageTime: formatSeconds(resp.data.averageTime)
        }
      }
    },
    handleClick () {
      this.$refs[this.activeName].setData({
        formVersion: this.formVersion,
        formCode: this.formCode
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.stats_wrap {
  padding: 20px 24px;
  margin-top: 20px;
  background: #fff;
}

.stats_overview {
  display: flex;
  justify-content: space-between;
}

.stats_overview_title {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #333;

  span {
    margin-right: 17px;
  }
}

.overview:last-child {
  margin-right: 0;
}

.download_stats_data {
  width: 100px;
  height: 38px;
  font-size: 14px;
  line-height: 38px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #2080e3;
  border: 1px solid #2080e3;
  border-radius: 7px;
  opacity: 1;
}

.overview_active {
  p {
   cursor: pointer;
   color: #409eff!important;
   span{
     color: #333;
   }
 }
}
.overview {
  display: inline-block;
  width: 220px;
  padding: 15px;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #f7f8fa;
  border-radius: 2px;

  .title {
    font-weight: 500;
  }

  .sub-title {
    font-size: 12px;
  }

  .primary {
    color: #409EFF;
  }

  div {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: #333;
      text-align: left;
    }
  }

  p {
    padding: 0;
    margin: 0;
    margin-top: 18px;
    font-size: 30px;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;

    .primary-text {
      color: #409EFF;
      font-size: 30px;
    }

    span {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #333;
    }
  }
}

.form-stat-detail {
  // margin-top: 20px;
}

.form-stat-detail-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.blank {
  height: 40px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #f7f8fa;
}
</style>
