const files = require.context('./modules', false, /\.routes.js$/)
const mRoutes = []
files.keys().forEach(key => {
  mRoutes.push(...files(key).default)
})

console.log(mRoutes)
export default mRoutes

/**
 * 根据项目来区分路由模块,
 * 新增一个项目就增加一个路由模块
 * 以.routes.js为后缀
 * 如
 * 协和项目mf-ctcl
 * mf-ctcl.routes.js
*/
