var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col h-full" },
    [
      _c(
        "div",
        { staticClass: "plain-form flex-1" },
        [
          _c("div", { staticClass: "plain-item" }, [
            _c("div", { staticClass: "plain-item-k" }, [_vm._v(" 问卷ID： ")]),
            _c("div", { staticClass: "plain-item-v" }, [
              _vm._v(" " + _vm._s(_vm.form.slug) + " "),
            ]),
          ]),
          _c("div", { staticClass: "plain-item" }, [
            _c("div", { staticClass: "plain-item-k" }, [_vm._v(" 参与人： ")]),
            _c("div", { staticClass: "plain-item-v" }, [
              _vm._v(" " + _vm._s(_vm.form.pName) + " "),
            ]),
          ]),
          _c("div", { staticClass: "plain-item" }, [
            _c("div", { staticClass: "plain-item-k" }, [
              _vm._v(" 身份证号： "),
            ]),
            _c("div", { staticClass: "plain-item-v" }, [
              _vm._v(" " + _vm._s(_vm.form.idCard) + " "),
            ]),
          ]),
          _c("div", { staticClass: "plain-item" }, [
            _c("div", { staticClass: "plain-item-k" }, [
              _vm._v(" 联系电话： "),
            ]),
            _c("div", { staticClass: "plain-item-v" }, [
              _vm._v(" " + _vm._s(_vm.form.cellphone) + " "),
            ]),
          ]),
          _c("div", { staticClass: "plain-item" }, [
            _c("div", { staticClass: "plain-item-k" }, [
              _vm._v(" 填写方式： "),
            ]),
            _c("div", { staticClass: "plain-item-v" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.form.entryType == 1 ? "患者填写" : "医生录入") +
                  " "
              ),
            ]),
          ]),
          _vm.form.status
            ? _c("div", { staticClass: "plain-item" }, [
                _c("div", { staticClass: "plain-item-k" }, [
                  _vm._v(" 问卷状态： "),
                ]),
                _c("div", { staticClass: "plain-item-v" }, [
                  _c("span", { class: _vm._f("cFilter")(_vm.form.status) }, [
                    _vm._v(
                      _vm._s(_vm.STATUS_MAP[_vm.form.status] || "状态错误")
                    ),
                    _c("span"),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.form.status === _vm.STATUS_ENUM.CHECK_FAIL
            ? _c("div", { staticClass: "plain-item" }, [
                _c("div", { staticClass: "plain-item-k" }, [
                  _vm._v(" 驳回原因： "),
                ]),
                _c("div", { staticClass: "plain-item-v" }, [
                  _vm._v(" " + _vm._s(_vm.form.remark) + " "),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _vm.form.sign
                ? _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      src: _vm.form.sign,
                      "preview-src-list": [_vm.form.sign],
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "state-text" }, [
                _vm._v(" 知情同意书图片 "),
              ]),
            ],
            1
          ),
          _vm.showLog
            ? [
                _c(
                  "div",
                  {
                    staticClass: "plain-item-k",
                    staticStyle: { margin: "16px 0 4px" },
                  },
                  [_vm._v(" 审核记录 ")]
                ),
                _c("LogBox", { attrs: { "log-id": _vm.form.id } }),
              ]
            : _vm._e(),
          _vm.form.status === _vm.STATUS_ENUM.WAIT_ARRAIGNED
            ? [
                _c(
                  "div",
                  {
                    staticClass: "plain-item-k",
                    staticStyle: { margin: "16px 0 12px" },
                  },
                  [_vm._v(" 备注说明： ")]
                ),
                _c(
                  "div",
                  [
                    _c("el-input", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "textarea",
                        rows: 5,
                        placeholder: "请输入备注说明",
                      },
                      model: {
                        value: _vm.postAuditRemark,
                        callback: function ($$v) {
                          _vm.postAuditRemark = $$v
                        },
                        expression: "postAuditRemark",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      !_vm.btnInvisible
        ? _c(
            "div",
            { staticClass: "sticky-bottom" },
            [
              _vm.form.status === _vm.STATUS_ENUM.WAIT_ARRAIGNED
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "per",
                          rawName: "v-per",
                          value: "surveya008",
                          expression: "'surveya008'",
                        },
                      ],
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.postAudit },
                    },
                    [_vm._v(" 提交审核 ")]
                  )
                : _vm._e(),
              _vm.form.status === _vm.STATUS_ENUM.UNCHECKED
                ? _c(
                    "el-popconfirm",
                    {
                      directives: [
                        {
                          name: "per",
                          rawName: "v-per",
                          value: "surveya007",
                          expression: "'surveya007'",
                        },
                      ],
                      attrs: { title: "确认审核通过吗？通过后不可修改！" },
                      on: {
                        confirm: function ($event) {
                          return _vm.auditFun(1)
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            size: "small",
                          },
                          slot: "reference",
                        },
                        [_vm._v(" 审核通过 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.status === _vm.STATUS_ENUM.UNCHECKED
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "per",
                          rawName: "v-per",
                          value: "surveya007",
                          expression: "'surveya007'",
                        },
                      ],
                      staticStyle: { "margin-left": "8px" },
                      attrs: { type: "danger", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [_vm._v(" 审核驳回 ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "填写审核驳回原因",
            visible: _vm.dialogVisible,
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "100%" },
            attrs: { type: "textarea", rows: 5, placeholder: "请输入内容" },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.textarea },
                  on: {
                    click: function ($event) {
                      return _vm.auditFun(0)
                    },
                  },
                },
                [_vm._v(" 提交 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }