import {
  addZero
} from '@cmvalue/dc-utils'

const filters = {
  addZero
}

export default Vue => {
  for (const name in filters) {
    const filter = filters[name]
    Vue.filter(name, filter)
  }
}
