<template>
  <el-submenu
    :index="menu.path + menu.title"
  >
    <template slot="title">
      <i :class="menu.icon" />
      <span slot="title">{{ menu.title }}</span>
    </template>
    <!-- <el-menu-item-group title="分组2"> -->
    <el-menu-item
      v-for="opt in menu.children"
      :key="opt.path"
      :index="opt.path"
      :route="{path: opt.path}"
    >
      {{ opt.title }}
    </el-menu-item>
    <!-- </el-menu-item-group> -->
  </el-submenu>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
