var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-img-com" },
    [
      _vm._l(_vm.imgList, function (item, index) {
        return _c(
          "div",
          { key: item.id, staticClass: "img-item" },
          [
            _vm.imgNote
              ? _c("el-image", {
                  staticClass: "el-img-class",
                  attrs: {
                    src: item.url,
                    fit: "cover",
                    disabled: _vm.readonlyDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setNote(index)
                    },
                  },
                })
              : _c("el-image", {
                  staticClass: "el-img-class",
                  attrs: {
                    src: item.url,
                    fit: "cover",
                    disabled: _vm.readonlyDisabled,
                    "preview-src-list": _vm.previewList,
                  },
                }),
            !_vm.readonlyDisabled
              ? _c("i", {
                  staticClass: "el-icon-error close-icon",
                  on: {
                    click: function ($event) {
                      return _vm.deleteImg(index)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.isCrop
        ? [
            _c(
              "el-upload",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showUpload,
                    expression: "showUpload",
                  },
                ],
                attrs: {
                  limit: _vm.limit,
                  action: "/bsp/api/admin/attachment/image",
                  "list-type": _vm.self ? "text" : "picture-card",
                  "http-request": _vm.upload,
                  name: "attachment",
                  accept: "image/png,image/jpg,image/gif",
                  "show-file-list": false,
                  "auto-upload": false,
                  disabled: _vm.readonlyDisabled,
                  multiple: _vm.multiple,
                  "on-change": _vm.changeUpload,
                },
              },
              [
                !_vm.self
                  ? _c("i", { staticClass: "el-icon-plus" })
                  : _vm._t("default"),
                _c("div", {
                  staticClass: "upload-tip",
                  attrs: { slot: "tip" },
                  domProps: { innerHTML: _vm._s(_vm.tip) },
                  slot: "tip",
                }),
              ],
              2
            ),
          ]
        : [
            _c(
              "el-upload",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showUpload,
                    expression: "showUpload",
                  },
                  {
                    name: "loading",
                    rawName: "v-loading.lock",
                    value: _vm.loading,
                    expression: "loading",
                    modifiers: { lock: true },
                  },
                ],
                attrs: {
                  limit: _vm.limit,
                  disabled: _vm.disabled || _vm.readonlyDisabled,
                  action: "/bsp/api/admin/attachment/image",
                  "list-type": _vm.self ? "text" : "picture-card",
                  "http-request": _vm.upload,
                  name: "attachment",
                  accept: "image/png,image/jpg,image/gif",
                  "show-file-list": false,
                  multiple: _vm.multiple,
                },
              },
              [
                !_vm.self
                  ? _c("i", { staticClass: "el-icon-plus" })
                  : _vm._t("default"),
                _c("div", {
                  staticClass: "upload-tip",
                  attrs: { slot: "tip" },
                  domProps: { innerHTML: _vm._s(_vm.tip) },
                  slot: "tip",
                }),
              ],
              2
            ),
          ],
      _c("img-note", {
        ref: "imgNote",
        attrs: { value: _vm.imgItem },
        on: { input: _vm.noteDone },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片剪裁",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "append-to-body": "",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "upload-dialog" }, [
            _c("div", { staticClass: "cropper-content" }, [
              _c(
                "div",
                {
                  staticClass: "cropper",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("vueCropper", {
                    ref: "cropper",
                    attrs: {
                      img: _vm.option.img,
                      "output-size": _vm.option.size,
                      "output-type": _vm.option.outputType,
                      info: true,
                      full: _vm.option.full,
                      "can-move": _vm.option.canMove,
                      "can-move-box": _vm.option.canMoveBox,
                      original: _vm.option.original,
                      "auto-crop": _vm.option.autoCrop,
                      fixed: _vm.option.fixed,
                      "fixed-number": _vm.option.fixedNumber,
                      "center-box": _vm.option.centerBox,
                      "info-true": _vm.option.infoTrue,
                      "fixed-box": _vm.option.fixedBox,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "cropper-switch" },
              [
                _c("p", [_vm._v("可按各个尺寸剪裁")]),
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.sizeChange },
                    model: {
                      value: _vm.cropperSize,
                      callback: function ($$v) {
                        _vm.cropperSize = $$v
                      },
                      expression: "cropperSize",
                    },
                  },
                  [
                    _c(
                      "el-radio",
                      { staticClass: "cropper-radio", attrs: { label: 1 } },
                      [_vm._v(" 图文封面 (3:2) ")]
                    ),
                    _c(
                      "el-radio",
                      { staticClass: "cropper-radio", attrs: { label: 2 } },
                      [_vm._v(" 图文封面 (1:1) ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.finish },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }