// 存放一些静态的页面
import FourZeroFour from '@/views/common/404'
import WhiteList from '@/views/common/white-list/white'
export default [
  {
    path: '/404',
    name: 'FourZeroFour',
    component: FourZeroFour,
    async beforeEnter (to, from, next) {
      document.title = '不存在'
      next()
    }
  },
  // {
  //   path: '/common-survey-list',
  //   name: 'CommonSurveyList',
  //   component: CommonSurveyList,
  //   async beforeEnter (to, from, next) {
  //     document.title = '调查列表'
  //     next()
  //   }
  // },
  // {
  //   path: '/common-survey-detail',
  //   name: 'CommonSurveyDetail',
  //   component: CommonSurveyDetail,
  //   async beforeEnter (to, from, next) {
  //     document.title = '调查详情'
  //     next()
  //   }
  // },
  // TODO: 白名单列表, 用路由动态参数来处理项目路径的问题
  {
    path: '/:project/white',
    name: 'WhiteList',
    component: WhiteList,
    async beforeEnter (to, from, next) {
      document.title = '白名单'
      next()
    }
  }
]
