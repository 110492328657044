import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'tailwindcss/tailwind.css'

import App from './App.vue'
import router from './router'
import store from './store'
import initGlobalFilter from './filters'
// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'
import '@/styles/main.scss'

import per from './directive/per'

// echart 图表
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart,
  LineChart,
  PieChart
} from 'echarts/charts'
import {
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components'

// 剪贴板js包
import VueClipboard from 'vue-clipboard2'

// import 'echarts/lib/chart/bar'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/chart/pie'
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/polar'
// import 'echarts/lib/component/title'
// import 'echarts/lib/component/toolbox'
// import 'echarts/lib/component/dataZoom'
// import 'echarts/lib/component/legend'

// 为了把移动端的表单拉过来
import Vant from 'vant'
import 'vant/lib/index.css'
import '@vant/touch-emulator'

import VueCropper from 'vue-cropper'

use([
  CanvasRenderer,
  LineChart,
  BarChart,
  PieChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
])

Vue.use(VueCropper)
Vue.use(VueClipboard)
Vue.use(ElementUI)
Vue.use(Vant)
Vue.use(per)
Vue.component('v-chart', ECharts)

if (process.env.VUE_APP_ENV === 'production') {
  // TODO: 替换sentry地址
  // Sentry.init({
  //   // eslint-disable-next-line
  //   release: process.env.VUE_APP_RELEASE_VERSION,
  //   dsn: 'sentry地址',
  //   integrations: [
  //     new Integrations.Vue({
  //       Vue,
  //       attachProps: true
  //     })
  //   ]
  // })
}
Vue.config.productionTip = false

// register global utility filters
initGlobalFilter(Vue)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
