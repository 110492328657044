<template>
  <div>
    <el-dialog
      width="600px"
      :show-close="false"
      :visible.sync="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :center="true"
      append-to-body
    >
      <div class="result">
        <div class="result-t">
          <span> {{ successCount }} 条导入成功，{{ failCount }} 条导入失败 </span>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-show="failCount > 0"
          plain
          @click="downloadFail"
        >
          下载失败数据
        </el-button>
        <el-button
          plain
          @click="handleClose"
        >
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'UploadResult',
  props: {
    successCount: {
      type: Number,
      default: 0
    },
    failCount: {
      type: Number,
      default: 0
    },
    failDataFile: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    downloadFail () {
      window.location.href = this.failDataFile
    },
    handleClose () {
      this.show = false
      this.$parent.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
.result {
  &-t {
    margin: 0 auto;
    text-align: center;
  }
}

</style>
