import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import mdy from './modules/mdy' // 动态表单

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    route: {},
    orgCode: undefined
  },
  mutations: {
    SET_DATA (state, obj) {
      for (const [key, value] of Object.entries(obj)) {
        state[key] = value
      }
    }
  },
  actions: {
    setData ({ commit }, obj) {
      commit('SET_DATA', obj)
    }
  },
  modules: {
    app,
    mdy
  },
  strict: process.env.NODE_ENV !== 'production'
})
