var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-stat-data" },
    [
      _c(
        "page-header",
        {
          staticStyle: { "margin-bottom": "0px" },
          attrs: { title: "数据详情" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "stat-data-detail" },
        [
          _c(
            "div",
            { staticClass: "description-text" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                    _c("div", { staticClass: "detail-item" }, [
                      _vm._v(" 填写人："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.detail.userId && _vm.detail.userId[0]) ||
                              "匿名"
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail-item" }, [
                      _vm._v(" 答题状态："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("emptyText")(
                              _vm.detail.completeStatus &&
                                _vm.detail.completeStatus[0]
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail-item" }, [
                      _vm._v(" 结束时间："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("emptyText")(
                              _vm.detail.fillEndTime &&
                                _vm.detail.fillEndTime[0]
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                    _c("div", { staticClass: "detail-item" }, [
                      _vm._v(" 答题序号："),
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("emptyText")(_vm.detail.dataId))),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail-item" }, [
                      _vm._v(" 开始时间："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("emptyText")(
                              _vm.detail.fillStartTime &&
                                _vm.detail.fillStartTime[0]
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "detail-item" }, [
                      _vm._v(" 答题时长："),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("emptyText")(
                                _vm.formatDuration(_vm.detail.duration)
                              )
                            )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "stat-data-detail__data" },
            _vm._l(_vm.metas, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "detail-item" },
                [
                  item.dynamicMeta
                    ? [
                        _c("p", [
                          _vm._v(
                            _vm._s(item.sequence) +
                              ". " +
                              _vm._s(item.metaLabel)
                          ),
                        ]),
                        _vm.detail[item.metaKey]
                          ? _c(
                              "p",
                              { staticClass: "img-wrap" },
                              _vm._l(
                                _vm.detail[item.metaKey],
                                function (v, vindex) {
                                  return _c("span", { key: vindex }, [
                                    item["uiWidget"] === "UploadWidget"
                                      ? _c(
                                          "div",
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                                "margin-right": "15px",
                                              },
                                              attrs: {
                                                src: v,
                                                fit: "fit",
                                                "preview-src-list":
                                                  _vm.detail[item.metaKey],
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm._f("emptyText")(v)) +
                                              " "
                                          ),
                                        ]),
                                  ])
                                }
                              ),
                              0
                            )
                          : _c("p", [_vm._v(" / ")]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }