var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-submenu",
    { attrs: { index: _vm.menu.path + _vm.menu.title } },
    [
      _c("template", { slot: "title" }, [
        _c("i", { class: _vm.menu.icon }),
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v(_vm._s(_vm.menu.title)),
        ]),
      ]),
      _vm._l(_vm.menu.children, function (opt) {
        return _c(
          "el-menu-item",
          {
            key: opt.path,
            attrs: { index: opt.path, route: { path: opt.path } },
          },
          [_vm._v(" " + _vm._s(opt.title) + " ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }