<template>
  <div
    class="home"
  >
    <van-notice-bar
      v-if="previewCode"
      left-icon="volume-o"
      text="当前处于预览模式～～"
      scrollable
    />
    <div
      v-if="formProps.isShowProgress"
      class="step-status"
    >
      <span class="step-status--cur">当前 第{{ formProps.paginationInfo && formProps.paginationInfo.curPage + 1 }}页</span>
      <span class="step-status--total">共{{ formProps.paginationInfo && formProps.paginationInfo.total }}页</span>
    </div>
    <YLMobileForm
      v-model="formData"
      :schema="schema"
      :form-props="formProps"
      :form-footer="formFooter"
      :error-schema="errorSchema"
      @on-submit="submitForm"
      @on-validation-failed="scrollToFirstError"
      @on-page-change="handleCurPageChange"
      @on-form-values-change="handleFormValuesChange"
    />
  </div>
</template>

<script>
import YLMobileForm from '@cmvalue/yl-mobile-form'
import apiMf from '@/api/project/mf-ctcl.js'
import { isEmptyObject } from '@/utils/object'
import { setTimeout, clearTimeout } from 'timers'
import { mapState } from 'vuex'

import _ from 'lodash'
// 防止初次进入时生成两次快照
const throttledPostFormDraftData = _.throttle(apiMf.postFormDraftData, 50, { trailing: false })

export default {
  name: 'SurveyPage',
  components: {
    YLMobileForm
  },
  props: {
    formSlug: {
      type: String,
      default: ''
    },
    formCode: {
      type: String,
      default: ''
    },
    mType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      prevDraftPage: 0, // 上次退出的草稿页码
      curPage: 0,
      totalPage: 0,
      formData: {},
      schema: {},
      formProps: {},
      formFooter: {},
      errorSchema: {},
      formVersion: '',
      formName: '',
      previewCode: '',
      formType: '',
      formActionResource: '',
      resume: true,
      isEnableResume: false,
      onlyView: false,
      timer: null,
      lock: false
    }
  },
  computed: {
    ...mapState('mdy', {
      // formCode: state => state.code,
      patientId: state => state.patientId,
      orgCode: state => state.orgCode
    }),
    hasNextAction () {
      return !!this.$store.state.app.formActionResource
    }
  },
  async created () {
    this.formVersion = this.getFormVersion()
    this.onlyView = (!!this.formSlug && !this.formType) || (!!this.formSlug && this.formType === 'see') // 如果有slug，则是查看详情
    this.initDynamicForm()
  },
  async mounted () {

  },
  methods: {
    triggerEdit () {
      this.formProps.disabled = !this.formProps.disabled
    },
    // ...mapActions([
    //   'fetchFormDetail'
    // ]),
    async initDynamicForm () { // 为了将form结构数据和data数据同步传入
      let formData = {}
      // const prevDraftPage = 0
      const { schema, formProps, formFooter } = await this.fetchDetailFormData()

      if (this.formSlug) {
        // 详情答案
        formData = await this.getFormAnswer(this.formSlug)
      } else if (this.resume) { // 断点续答,默认开启
        // 判断是否有快照
        const fomrDataObj = await this.fetchFormDraftData()
        formData = fomrDataObj.formData
        if (formProps.paginationInfo) {
          formProps.paginationInfo.curPage = fomrDataObj.prevDraftPage
        }
      }
      this.schema = schema
      this.formData = formData
      this.formProps = formProps
      this.formFooter = formFooter
    },
    handleFormValuesChange (curFormData) {
      // 查看的时候不能编辑
      // console.warn('handleFormValuesChange', this.onlyView, isEmptyObject(curFormData), JSON.stringify(curFormData), !this.isEnableResume)

      if (this.onlyView || isEmptyObject(curFormData) || JSON.stringify(curFormData) === '{}' || !this.isEnableResume) return

      // 提交问卷之后,不能再交快照
      if (this.lock) return

      this.timer = setTimeout(async () => {
        const payload = {
          formCode: this.formCode,
          formVersion: this.formVersion,
          formData: JSON.stringify(curFormData),
          extConfig: JSON.stringify(this.formProps.paginationInfo),
          patientId: this.patientId,
          orgCode: this.orgCode
        }
        if (!this.formType || this.formType === 'again') {
          // 断点续答，列表过来的时候不要请求快照
          await throttledPostFormDraftData(payload)
        }
      }, 500)
    },
    handleCurPageChange (pageInfo) {
      // 当页码变化时，自动滚动到页面顶部
      this.scrollToTop()
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    getFormVersion () {
      return localStorage.getItem('formVersion')
    },
    async fetchFormDraftData () {
      try {
        const payload = {
          formCode: this.formCode,
          formVersion: this.formVersion,
          patientId: this.patientId,
          orgCode: this.orgCode
        }
        const resp = await apiMf.fetchFormDraft(payload)
        if (resp.success && resp.data) {
          let prevDraftPage = 0
          const formData = JSON.parse(resp.data.formData)
          if (resp.data.extConfig) {
            prevDraftPage = JSON.parse(resp.data.extConfig).curPage
          }
          return { formData, prevDraftPage }
        }

        return { formData: {}, prevDraftPage: 0 }
      } catch (e) {
        console.log('错误', e)
      }
    },
    scrollToFirstError () {
      setTimeout(() => {
        const isErrorEle = document.getElementsByClassName('formItemErrorBox')[0]
        isErrorEle.scrollIntoView({
        // 值有start,center,end，nearest，当前显示在视图区域中间
          block: 'center',
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: 'smooth'
        })
      }, 17)
    },
    async getFormAnswer (slug) {
      const an = await apiMf.getFormDataDetail({ slug })
      if (an.success) {
        // this.formData =
        return JSON.parse(an.data.formData)
      }
      return {}
    },
    async submitForm (data) {
      if (this.previewCode) {
        this.$toast.fail('当前处于预览模式，不能进行提交～')
        return
      }
      try {
        let payload = {
          code: this.formCode,
          content: JSON.stringify(this.formData),
          version: this.formVersion,
          patientId: this.patientId,
          orgCode: this.orgCode
        }
        // 自定义参数
        const tail = {}
        if (sessionStorage.getItem('customizationQueryJson')) {
          tail.customization = sessionStorage.getItem('customizationQueryJson')
        }

        payload = {
          ...tail,
          ...payload,
          extConfig: JSON.stringify(this.formProps.paginationInfo)
        }

        if (this.formProps.disabled) { // 查看的状态，
          window.history.go(-1)
          return
        }
        /**
         * 清除定时器
         * 数字输入框的触发是blur事件, 会出现问题
         * (在数字输入框中输入->点击提交. 这种情况下会提交表单后再提交快照
        */
        clearTimeout(this.timer)
        this.lock = true // 锁定不能再提交快照
        if (this.formSlug) {
          this.postFormModify({
            slug: this.formSlug,
            content: JSON.stringify(this.formData),
            type: this.mType ? this.mType : undefined // 'ENTRY_CHECK_LIST'
          })
        } else {
          this.postFormDetailData(payload)
        }
      } catch (e) {}
    },
    async postFormDetailData (payload) {
      //  问卷提交
      const resp = await apiMf.postFormDetailData(payload)
      if (resp.success) {
        const { userId } = resp.data
        this.$store.dispatch('mdy/setData', {
          userId
        })
        this.$emit('submited')
      } else {
        this.$toast.fail(resp.msg)
      }
    },
    async postFormModify (payload) {
      // 用户问卷记录修改
      const resp = await apiMf.postFormModify(payload)
      if (resp.success) {
        this.$emit('submited')
      } else {
        this.$toast.fail(resp.msg)
      }
    },
    async fetchDetailFormData () {
      try {
        const params = {
          orgCode: this.orgCode,
          patientId: this.patientId
        }
        let resp
        if (this.previewCode) {
          params.previewCode = this.previewCode
          resp = await apiMf.fetchPreviewData(params)
        } else {
          params.code = this.formCode
          resp = {
            success: Object.keys(this.$store.state.mdy.formContent).length !== 0,
            data: { formContent: this.$store.state.mdy.formContent }
          }
        }
        if (resp.success) {
          if (this.previewCode) {
            const { scheme: previewScheme } = resp.data
            const { schema, formProps, formFooter } = JSON.parse(previewScheme)

            const newProps = { ...formProps, labelSuffix: '', disabled: this.onlyView }

            const newFooter = { ...formFooter, ...formProps.formFooter }
            document.title = '预览模式'
            return { schema, formProps: newProps, formFooter: newFooter }
          } else { // 正式的情
            const { formContent } = resp.data
            this.formActionResource = formContent.formActionResource
            this.isEnableResume = formContent.resumeOnBreak
            const schemaExt = JSON.parse(formContent.schemaExt)
            const { schema, formProps, formFooter } = JSON.parse(formContent.scheme)
            this.formVersion = formContent.version
            this.formName = formContent.name
            const formProps2 = {
              ...formProps,
              disabled: this.onlyView
            }
            const formFooter2 = { ...formFooter, ...formProps.formFooter, okBtn: schemaExt.formFooter.okBtn }

            // document.title = this.formName
            return { schema, formFooter: formFooter2, formProps: formProps2 }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
<style lang="scss">
.home {
  min-height: 100vh;
  min-height: calc(100vh - content(safe-area-inset-bottom));
  min-height: calc(100vh - env(safe-area-inset-bottom));
  background: #f4f4f4;

  .layoutColumn .fieldGroupWrap_box > .genFormItem {
    margin-bottom: 10px;
  }

  .mobile-form .mobile-form-item {
    &.formFooter_item {
      background: transparent;
      border-top: 0;
    }

    &.form-item__label--top {
      // padding-bottom: 0;
    }

    .el-button {
      width: 343px;
      height: 44px;
      font-size: 16px;

      &.is-plain {
        color: #2080e3;
        background: #fff;
      }

      &.inline {
        display: inline-block;
        width: 110px;

        &:last-child {
          width: 210px;
          margin-left: 15px;
        }
      }
    }

    [class*=van-hairline]::after {
      display: none;
    }

    .mobile-form-item__content .van-cell-group .van-cell {
      padding: 14px 12px;
    }

    .mobile-form .mobile-form-item .mobile-form-item__content .van-cell.other-radio__input {
      padding: 16px;
    }
  }

  .van-radio-group {
    .van-cell:last-child {
      &::after {
        display: none !important;
      }
    }
  }

  .van-cell__title {
    font-weight: 400;
    color: #333;
  }

  .mobile-form .genFormLabel {
    font-weight: bold;
  }

  .step-status {
    display: flex;
    padding: 7px 17px;
    font-size: 14px;
    color: #999;

    &--total {
      margin-left: auto;
    }
  }
}

</style>
