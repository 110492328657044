<template>
  <div class="form-stat-data">
    <page-header
      title="数据详情"
      style="margin-bottom: 0px;"
    >
      <el-button
        size="small"
        @click="$router.go(-1)"
      >
        返回
      </el-button>
    </page-header>
    <div class="stat-data-detail">
      <div class="description-text">
        <el-row :gutter="20">
          <el-col
            :span="12"
            :offset="0"
          >
            <div class="detail-item">
              填写人：<span>{{ ( detail.userId && detail.userId[0])|| '匿名' }}</span>
            </div>
            <div class="detail-item">
              答题状态：<span>{{ (detail.completeStatus && detail.completeStatus[0]) | emptyText }}</span>
            </div>
            <div class="detail-item">
              结束时间：<span>{{ (detail.fillEndTime && detail.fillEndTime[0]) | emptyText }}</span>
            </div>
          </el-col>
          <el-col
            :span="12"
            :offset="0"
          >
            <div class="detail-item">
              答题序号：<span>{{ detail.dataId | emptyText }}</span>
            </div>
            <div class="detail-item">
              开始时间：<span>{{ (detail.fillStartTime && detail.fillStartTime[0]) | emptyText }}</span>
            </div>
            <div class="detail-item">
              答题时长：<span> {{ formatDuration(detail.duration) | emptyText }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-divider />
      <div class="stat-data-detail__data">
        <div
          v-for="(item, index) in metas"
          :key="index"
          class="detail-item"
        >
          <template v-if="item.dynamicMeta">
            <p>{{ item.sequence }}. {{ item.metaLabel }}</p>
            <p
              v-if="detail[item.metaKey]"
              class="img-wrap"
            >
              <span
                v-for="(v, vindex) in detail[item.metaKey]"
                :key="vindex"
              >
                <div
                  v-if="item['uiWidget'] === 'UploadWidget'"
                >
                  <el-image
                    style="width: 100px; height: 100px; margin-right: 15px;"
                    :src="v"
                    fit="fit"
                    :preview-src-list="detail[item.metaKey]"
                  />
                </div>
                <span v-else>
                  {{ v | emptyText }}
                </span>
              </span>
            </p>
            <p v-else>
              /
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/page-header'
import { formatSeconds } from '@/utils/time'
import apiCommon from '@/api/common'
export default {
  name: 'FormStatsDataDetail',
  components: {
    PageHeader
  },
  filters: {
    emptyText: function (value) {
      return !value ? '/' : value
    }
  },
  data () {
    return {
      detail: {},
      metas: [],
      formCode: '',
      formVersion: '',
      slug: ''
    }
  },
  created () {
    this.formCode = this.$route.query.formCode || ''
    this.formVersion = this.$route.query.formVersion || ''
    this.slug = this.$route.query.slug || ''
    this.getStatMetas()
    this.getStatDataDetail()
  },
  methods: {
    formatDuration (duration) {
      if (duration) {
        return formatSeconds(duration)
      }
      return '0'
    },
    async getStatMetas () {
      const params = {
        formCode: this.formCode,
        formVersion: this.formVersion
      }
      const res = await apiCommon.getStatMetas(params)
      if (res.success) {
        this.metas = res.data || []
      }
    },
    async getStatDataDetail () {
      const res = await apiCommon.getStatDataDatail({ slug: this.slug })
      if (res.success) {
        const data = res.data || {}
        this.detail = {
          ...data.dataMeta,
          dataId: data.dataId,
          duration: data.duration,
          username: data.username,
          slug: [data.slug]
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.img-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form-stat-data{
  padding: 20px 20px 20px 100px;
  background: #ffffff;
  min-height: 660px;
  min-width: 900px;
}
.stat-data-detail {
  padding: 20px 100px 20px 0;
}

.detail-item {
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
  color: rgba($color: #000, $alpha: 0.85);

  span {
    color: rgba($color: #000, $alpha: 0.65);
  }
}

.description-text {
  &__title {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
  }

  &__term {
    display: table-cell;
    padding-bottom: 16px;
    margin-right: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;

    &::after {
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
      content: ':';
    }
  }

  &__detail {
    display: table-cell;
    width: 100%;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;

    a {
      color: #409eff;
    }
  }
}
</style>
