<template>
  <el-dialog
    v-if="show"
    v-loading="loading"
    width="410px"
    :title="disabled ? '白名单详情' : '新增白名单'"
    :visible.sync="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :center="true"
    custom-class="doc-form-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item
        label="医生ID"
        prop="doctorId"
      >
        <el-input
          v-model="form.doctorId"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item
        label="姓名"
        prop="doctorName"
      >
        <el-input
          v-model="form.doctorName"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item
        label="机构编码"
        prop="orgCode"
      >
        <el-input
          v-model="form.orgCode"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item
        label="机构名称"
        prop="orgName"
      >
        <el-input
          v-model="form.orgName"
          placeholder="请输入"
        />
      </el-form-item>
      <el-form-item
        label="医生角色"
        prop="role"
      >
        <el-select
          v-model="form.role"
          placeholder="请选择角色"
          style="width: 100%;"
        >
          <el-option
            v-for="(val, key) in roleMap"
            :key="key"
            :label="val"
            :value="key"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.role"
        label="角色编码"
      >
        <el-input
          v-model="form.role"
          style="display: inline-block;width: 80%;"
          disabled
        />
        <el-button
          v-clipboard:copy="form.role"
          v-clipboard:success="handleCopySuccess"
          type="text"
          style="margin-left: 10px;"
        >
          复制
        </el-button>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="show = false">取 消</el-button>
      <el-button
        type="primary"
        @click="onSubmit"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import apiCommon from '@/api/common.js'

export default {
  data () {
    const required = { required: true, message: '必填项', trigger: ['blur', 'change'] }
    return {
      loading: false,
      disabled: false,
      show: false,
      form: {
        doctorId: '',
        doctorName: '',
        orgCode: '',
        orgName: '',
        role: ''
      },
      rules: {
        doctorId: [required],
        doctorName: [required],
        orgCode: [required],
        orgName: [required]
        // role: ''
      }
    }
  },
  computed: {
    ...mapState('app', {
      roleMap: state => state.white.roleMap
    })
  },
  methods: {
    handleCopySuccess () {
      this.$message.success('复制成功')
    },
    async onSubmit () {
      // if (this.disabled) {
      //   this.show = false
      // } else {

      // }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData()
        }
      })
    },
    async postData () {
      let resp = {}
      if (this.disabled) {
        resp = await apiCommon.modifyWhiteItem(this.form)
      } else {
        resp = await apiCommon.createWhiteItem(this.form)
      }
      if (resp.success) {
        this.$emit('close')
        this.show = false
      }
    },
    setData (data) {
      let disabled = false
      if (data) {
        this.form = { ...data }
        disabled = true
      } else {
        this.form = {
          doctorId: '',
          doctorName: '',
          orgCode: '',
          orgName: '',
          role: ''
        }
      }
      this.disabled = disabled
      this.show = true
    }
  }
}
</script>
<style lang="scss" scoped>
.doc-form-dialog {
  margin-bottom: -20px;
  /deep/ .el-dialog__body {
    padding-bottom: 10px !important;
  }
}
</style>
