import ajax from '@/libs/ajax'

const apiMf = {
  // 获取患者列表
  getParticipantList: params => ajax.request({
    url: '/pjzjdc/api/admin/list',
    method: 'GET',
    params
  }),

  // 获取机构列表
  getOrgList: params => ajax.request({
    url: '/pjzjdc/api/admin/org/list',
    method: 'get',
    params
  }),

  // 保存/提交检查结果
  postImageData: data => ajax.request({
    url: '/pjzjdc/api/admin/result/submit',
    method: 'post',
    data
  }),

  // 患者数据详情
  getDetailData: params => ajax.request({
    url: '/pjzjdc/api/admin/data-detail',
    method: 'get',
    params
  }),

  // 获取统计数据概览
  getDataOverview: params => ajax.request({
    url: '/pjzjdc/api/admin/data/overview',
    method: 'get',
    params
  }),
  // 机构统计数据
  getOrgData: params => {
    return ajax.request({
      url: '/pjzjdc/api/admin/data/org',
      method: 'get',
      params
    })
  },
  // 调查员统计数据
  getDoctorData: params => ajax.request({
    url: '/pjzjdc/api/admin/data/doctor',
    method: 'get',
    params
  }),
  // 表单填写情况数据
  getFormStatisticData: params => ajax.request({
    url: '/pjzjdc/api/admin/data/form',
    method: 'get',
    params
  }),

  // 上传图片
  uploadImg: data => ajax.request({
    url: '/pjzjdc/api/admin/attachment/image',
    method: 'post',
    timeout: 50000,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  }),

  // 导出图片
  exportImgs: params => ajax.request({
    url: '/pjzjdc/api/admin/export-image',
    method: 'get',
    params
  }),

  // 导出
  exportAgreement: params => ajax.request({
    url: '/pjzjdc/api/admin/export-agreement',
    method: 'get',
    params
  }),

  // 导出问卷数据
  exportSurveyData: params => ajax.request({
    url: '/pjzjdc/api/admin/export-data',
    method: 'get',
    params
  }),

  // 获取地址
  getSurveyDataLink: params => {
    // debugger
    return ajax.request({
      url: '/pjzjdc/api/admin/export-check',
      method: 'get',
      params
    }, {
      isShowErrorMessage: false,
      isSkipAllResponseTransform: true
    // isReturnNativeResponse: true
    })
  }
}

export default apiMf
