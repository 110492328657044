<template>
  <el-dialog
    title="下载成功"
    :visible.sync="visible"
    width="30%"
    center
  >
    <span>温馨提示：请注意数据安全，保护好患者隐私！</span>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
      <el-button
        type="primary"
        @click="visible = false"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    setData () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
