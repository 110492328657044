/**
 * 格式化秒到时分秒
 * @param {*} seconds
 * @returns
 */
export function formatSeconds (seconds) {
  let diff = parseInt(seconds)
  const hour = 3600
  const minute = 60

  const h = parseInt(diff / hour)
  diff = diff % hour

  const m = parseInt(diff / minute)
  diff = diff % minute

  const s = diff

  return (
    (h < 10 ? '0' + h : h) +
    ':' +
    (m < 10 ? '0' + m : m) +
    ':' +
    (s < 10 ? '0' + s : s)
  )
}
