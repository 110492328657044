<template>
  <div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      width="410px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :center="true"
      :show-close="!loading"
    >
      <div
        v-loading="loading"
        class="upload-content"
      >
        <el-upload
          drag
          :http-request="upload"
          action="/bsp/api/admin/attachment/excel"
          accept=".xls,.xlsx"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em>
          </div>
          <div
            slot="tip"
            class="el-upload__tip"
          >
            请上传.xls或.xlsx格式的文件
          </div>
        </el-upload>
        <div>
          <el-button
            type="text"
            @click="downloadTemp"
          >
            下载上传模板
          </el-button>
        </div>
      </div>
    </el-dialog>
    <upload-result
      ref="uploadRes"
      :success-count="successCount"
      :fail-count="failCount"
      :fail-data-file="failDataFile"
    />
  </div>
</template>

<script>
import apiCommon from '@/api/common'
import uploadResult from './components/upload-result'
export default {
  name: 'UploadList',
  components: {
    uploadResult
  },
  data () {
    return {
      loading: false,
      show: false,
      slug: '',
      status: 0,
      successCount: 0,
      failCount: 0,
      failDataFile: '',
      templatePath: ''
      // {
      //   white: 'https://cmvalue-web-cdn.oss-cn-hangzhou.aliyuncs.com/mini-repo/case-exchange/flie/whitelist_import_live.xlsx',
      //   black: 'https://cmvalue-web-cdn.oss-cn-hangzhou.aliyuncs.com/mini-repo/case-exchange/flie/blacklist_import_live.xlsx',
      //   hospital: 'https://cmvalue-web-cdn.oss-cn-hangzhou.aliyuncs.com/file/xlsx/hospital-upload-list.xlsx'
      // }
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    upload (params) {
      this.loading = true
      const file = params.file
      const form = new FormData()
      form.append('attachment', file)

      apiCommon.uploadWhiteLst(form).then(res => {
        if (res.success) {
          this.slug = res.data.slug
          this.getUploadResult()
        }
      })
    },
    getUploadResult () {
      var getUploadResInterval = setTimeout(() => {
        clearTimeout(getUploadResInterval)
        apiCommon.getUploadUserLst({ slug: this.slug }).then(res => {
          if (res.success) {
            if (res.data.status === 1) {
              this.status = res.data.status
              this.successCount = res.data.successCount
              this.failCount = res.data.failCount
              this.failDataFile = res.data.failDataFile
              this.$refs.uploadRes.show = true
            } else if (res.data.status === 2) {
              this.$message.error('导入失败')
            } else {
              this.getUploadResult()
            }
          }
          this.loading = false
        })
      }, 1500)
    },
    setData (template) {
      this.templatePath = template
      this.show = true
    },
    downloadTemp () {
      const templatePath = this.templatePath
      window.location.href = templatePath
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-content {
  display: flex;
  flex-direction: column;
}
</style>
