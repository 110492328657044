
// 状态
const STATUS_MAP = Object.freeze({
  ONGOING: '进行中',
  UNCONFIRMED: '待确认',
  WAIT_ARRAIGNED: '待提审',
  UNCHECKED: '待审核',
  CHECK_SUCCESS: '审核通过',
  CHECK_FAIL: '审核驳回',
  ENTRY_CHECK_LIST: '待录入检查信息'
})

const STATUS_ENUM = Object.freeze({
  ONGOING: 'ONGOING',
  UNCONFIRMED: 'UNCONFIRMED',
  WAIT_ARRAIGNED: 'WAIT_ARRAIGNED',
  UNCHECKED: 'UNCHECKED',
  CHECK_SUCCESS: 'CHECK_SUCCESS',
  CHECK_FAIL: 'CHECK_FAIL',
  ENTRY_CHECK_LIST: 'orange'
})

module.exports = {
  STATUS_MAP,
  STATUS_ENUM
}
