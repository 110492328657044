import wx from 'weixin-js-sdk'
import axios from 'axios'
import { Base64 } from 'js-base64'

/**
 * 格式化对象到字符串 a=b&c=d
 * @param  {Object} paramObj 数据对象
 * @return {String}          字符串
 */

export function formatParams (paramObj) {
  var arr = []
  var name = ''
  for (name in paramObj) {
    // eslint-disable-next-line
    if (paramObj.hasOwnProperty(name)) {
      arr.push(encodeURIComponent(name) + '=' +
        encodeURIComponent(paramObj[name]))
    }
  }

  return arr.join('&')
}

/**
 * JSONP获取数据
 * @param  {String} url     数据url
 * @param  {Object} options 选项
 */
export function jsonp (url, options) {
  var defaults = {
    /** @type {Object} 数据对象 */
    data: {},
    /** @type {String} 获取数据的类型 */
    dataType: 'json',
    /** @type {String} callback参数的名称 */
    callbackName: 'callbackMethod',
    /** @type {String} callback函数的名称，留空则随机生成 */
    fnName: '',
    /** @type {Number} 超时设置 */
    timeout: 0,
    /** er
     * 成功回调
     * @param  {Object} data 获取的数据
     */
    success: function (data) {
    },
    /**
     * 失败回调
     * @param  {Object} resultObj 失败后的结果对象
     */
    fail: function (resultObj) {
    },
    /** 结束回调 */
    always: function () {
    }
  }

  var config = {
    ...defaults,
    ...options
  }

  if (!url) {
    throw (new Error('无效的url'))
  }

  var fnName = config.fnName || ('jsonp_' + Math.random()).replace('.', '')
  config.data[config.callbackName] = fnName
  var params = formatParams(config.data)

  var scriptElement = document.createElement('script')
  var documentHead = document.getElementsByTagName('head')[0]

  documentHead.appendChild(scriptElement)
  window[fnName] = function (data) {
    clearTimeout(scriptElement.timer)
    if (typeof data === 'string' && config.dataType === 'json') {
      data = JSON.parse(data)
    }

    window[fnName] = null

    if (typeof config.success === 'function') {
      config.success(data)
    }

    if (typeof config.always === 'function') {
      config.always()
    }
  }
  // 如果传入的url有参数，则用&连接callback,否则使用?
  if (url.split('?').length > 1) {
    scriptElement.src = url + '&' + params
  } else {
    scriptElement.src = url + '?' + params
  }
  if (config.timeout) {
    scriptElement = setTimeout(function () {
      window[fnName] = null
      documentHead.removeChild(scriptElement)

      if (typeof config.fail === 'function') {
        config.fail({ message: '超时', success: false })
      }

      if (typeof config.always === 'function') {
        config.always()
      }
    })
  }
}
/** @type {Array} 微信分享函数列表 */
const SHARE_METHODS = [
  'updateAppMessageShareData',
  'updateTimelineShareData',
  'updateMenuShareQQ',
  'updateMenuShareWeibo',
  'updateMenuShareQZone'
]

/**
 * 输出错误信息
 * @param  {String} errorInfo 错误信息
 */
const consoleError = (errorInfo) => {
  throw new Error(`wechat-sdk 错误提示：${JSON.stringify(errorInfo)}`)
}

function getSignature ({ isProd, signId, isJsonp }) {
  const SIGN_URL = `https://${isProd ? 'mcp.cmvalue.com' : 'mcptest.cmvalue.com'}/wechat/jsapi/sign/${signId}`
  if (isJsonp) {
    return new Promise((resolve, reject) => {
      jsonp(`${SIGN_URL}?baseEncodedPageUrl=${Base64.encodeURI(window.location.href)}&wechatAccountId=${signId}`, {
        success: (data) => {
          resolve(data.data)
        },
        fail: (res) => {
          consoleError(`signUrl 请求失败。详情：${res}`)
          reject(res)
        }
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      axios.get(SIGN_URL, {
        params: {
          baseEncodedPageUrl: Base64.encodeURI(window.location.href),
          wechatAccountId: signId
        }
      }).then(res => {
        resolve(res.data.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
/**
 * 获取后端分享签名
 */
function getSignIdByHost () {
  const HOST_MAP_SIGN_ID_ARRAY = [{
    host: 'mcp.cmvalue.com',
    signId: 1
  }, {
    host: 'mcptest.cmvalue.com',
    signId: 3
  }, {
    host: 'etest.cmvalue.com',
    signId: 3
  }, {
    host: 'e.cmvalue.com',
    signId: 1
  }]

  const matchedHost = HOST_MAP_SIGN_ID_ARRAY.find(({ host }) => new RegExp(host).test(location.host))

  return matchedHost ? matchedHost.signId : 3
}

/**
 * 初始化微信SDK
 * @desc 详细参数参见 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html
 * @param {非必填} {String} signId 公众号对应的ID
 * @param {非必填} {Boolean} debug 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
 * @param {非必填} {Function} onLoad 初始化成功后执行回调
 * @param {非必填} {Function} onError 初始化失败后执行回调
 * /**
 * 初始化微信SDK
 * @desc 详细参数参见 http://t.cn/RXTdBFZ
 * @param {非必填} {String} signId 公众号对应的 SIM ID
 * @param {非必填} {Boolean} debug 是否开启 debug 模式
 * @param {非必填} {Object} additionJsApiList jsApiList 附加参数
 * @param {非必填} {Function} onLoad 初始化成功后执行回调
 * @param {非必填} {Function} onError 初始化失败后执行回调
 * @param {非必填} {Boolean} isProd 是否为生产环境 (生产环境使用 wechat.dxy.cn，开发环境使用 simqa.dxy.cn)
 *
 * initWechatSDK({
 *   signId: 166,
 *   debug: false,
 *   additionJsApiList: [
 *     'previewImage'
 *   ],
 *   onLoad: (wx) => {
 *    console.log('校验完毕，成功或失败都会执行这个函数');
 *    },
 *  onError: (error) => {
 *     console.log(`校验失败：${error}`);
 *   }
 * });
 */
export async function initWechatShare ({ signId = getSignIdByHost(), debug = false, additionJsApiList, onLoad, onError, isProd = true, isJsonp = false }) {
  try {
    const { timestamp, nonce, appId, signature } = await getSignature({ signId, isProd, isJsonp })
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: appId, // 必填，公众号的唯一标识
      timestamp: timestamp, // 必填，生成签名的时间戳
      nonceStr: nonce, // 必填，生成签名的随机串
      signature: signature, // 必填，签名
      jsApiList: [
        ...SHARE_METHODS,
        'chooseImage',
        'uploadImage',
        'showAllNonBaseMenuItem',
        'hideAllNonBaseMenuItem',
        ...additionJsApiList
      ] // 必填，需要使用的JS接口列表
    })

    wx.ready(() => {
      if (typeof onLoad === 'function') {
        onLoad(wx)
      }
    })

    wx.error((res) => {
      if (typeof onError === 'function') {
        onError(res)
      } else {
        consoleError(res)
      }
    })
  } catch (err) {
    consoleError(`分享配置请求失败。详情：${err}`)
  }
}

/**
 * 设置分享
 * @desc 详细参数参见 http://t.cn/RqQ8Dg9
 * @param  {Object} options.commonConfig 设置通用参数
 * @param  {Object} options.additionConfig 设置附加参数
 * setShareConfig({
 *  title: 'xxxx',
 *  desc: 'xxxx',
 *  link: 'xxxx',
 *  imgUrl: 'xxxxx'
 * }, {
 *  onMenuShareAppMessage: {
 *   type: 'music'
 *  }
 *  });
 */
export function setShareConfig (commonConfig, additionConfig) {
  wx.ready(() => {
    SHARE_METHODS.forEach((methodName) => {
      let methodConfig = commonConfig

      if (additionConfig && additionConfig[methodName]) {
        methodConfig = {
          ...commonConfig,
          ...additionConfig[methodName]
        }
      }

      invokeMethod(methodName, methodConfig)
    })
  })
}

/**
 * 上传图片
 * @param {Object.Array} uploadedServerIds 已上传图片 id
 * @param {Object.Array} localIds 需要上传的图片的本地 id，由 chooseImage 接口获得
 * @param {Object.Number} isShowProgressTips 默认为 1，显示进度提示
 * @param {Object.Function} callbackFuncEvery 每次上传图片成功后的回调函数
 * @param {Object.Function} callbackFuncFinal 所有图片上传成功后的回调函数
 * invokeMethod('chooseImage', {
 * success(res) {
 *     wechatSDK.uploadImages({
 *         localIds: res.localIds,
 *         callbackFuncEvery(res) {
 *             console.log('callbackFuncEvery', res);
 *         },
 *         callbackFuncFinal(res) {
 *             console.log('callbackFuncFinal', res);
 *         }
 *     });
 * }
 * });
 */
export const uploadImages = ({
  uploadedServerIds = [],
  localIds = [],
  isShowProgressTips = 1,
  callbackFuncEvery = () => {},
  callbackFuncFinal = () => {}
} = {}) => {
  if (localIds.length <= 0) {
    callbackFuncFinal(uploadedServerIds)
    return
  }

  const localId = localIds.pop()

  wx.uploadImage({
    localId,
    isShowProgressTips,
    success (res) {
      uploadedServerIds.push(res.serverId)

      callbackFuncEvery(res)
      uploadImages({
        uploadedServerIds,
        localIds,
        isShowProgressTips,
        callbackFuncEvery,
        callbackFuncFinal
      })
    }
  })
}

/**
 * 调用微信 Bridge 函数
 * @param  {String} methodName   函数名称
 * @param  {Object} methodConfig 函数参数
 */
export const invokeMethod = (methodName, methodConfig) => {
  wx[methodName](methodConfig)
}
