<template>
  <div>
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      tooltip-effect="dark"
      style="width: 100%;"
      :header-cell-style="{background:'#F8F8F8',color:'#333'}"
    >
      <el-table-column
        prop="id"
        label="调查员姓名"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.username || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="count"
        label="问卷数"
        align="center"
      />
      <el-table-column
        label="占比"
        align="center"
        prop="orgName"
      >
        <template slot-scope="scope">
          {{ `${scope.row.percent} %` }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { STATUS_MAP } from '@/config/constant.js'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    formCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      STATUS_MAP
    }
  },
  methods: {
    viewDetail (row) {
      this.$emit('view', row)
    },
    togglePhone (row) {
      this.$set(row, 'showPhone', !row.showPhone)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-btn {
  color: #409eff;
  cursor: pointer;
}
.edit {
  color: #666;
}

.eye-open-icon {
  display: inline-block;
  vertical-align: text-bottom;
  width: 14px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  background: url("~@/assets/eye-open-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.eye-close-icon {
  display: inline-block;
  vertical-align: text-bottom;
  width: 14px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  background: url("~@/assets/eye-close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}

</style>
