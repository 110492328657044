import ajax from '@/libs/ajax'
const apiAuth = {
  getNavListAndPermission: () => ajax.request({
    url: '/uc/api/admin/present-user/menu-and-permission'
  }),
  getUserInfo: () => ajax.request({
    url: '/uc/api/admin/present-user'
  })
}
export default apiAuth
