var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "stats_report_card" },
        _vm._l(_vm.reportList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "stats_report_card__box" },
            [
              _c("div", [
                _c("span", [
                  _vm._v(_vm._s(item.sequence) + "." + _vm._s(item.metaLabel)),
                ]),
                !item.activeMetaKey
                  ? _c("span", { staticClass: "expire" }, [
                      _vm._v(" 该题目在最新问卷中已删除 "),
                    ])
                  : _vm._e(),
              ]),
              _c("p", [_vm._v("回答人数 " + _vm._s(item.headCount))]),
              _c("p", [_vm._v("回答次数 " + _vm._s(item.count))]),
              _c(
                "div",
                { staticClass: "stats_report_card__box__table" },
                [
                  item.extType === 1
                    ? [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              "element-loading-text": "拼命加载中",
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(0, 0, 0, 0.8)",
                              data: item.data.dataMetas,
                              "header-cell-style": {
                                background: "#F8F8F8",
                                color: "#333",
                              },
                            },
                          },
                          _vm._l(
                            item.data.tableHead,
                            function (head, headIndex) {
                              return _c("el-table-column", {
                                key: headIndex,
                                attrs: { prop: head.prop, label: head.label },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row[head.prop]) +
                                                " "
                                            ),
                                            headIndex == 0 &&
                                            !scope.row.activeDataMeta
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "delete-color",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "选项在最新问卷中已删除"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        }),
        0
      ),
      _vm.page <= _vm.total
        ? _c(
            "div",
            { staticClass: "load_more" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium", plain: "" },
                  on: { click: _vm.loadMore },
                },
                [_vm._v(" 加载更多 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }