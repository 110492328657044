export function getFormDraftNumber (formDraft) {
  // debugger
  if (!Object.keys(formDraft).length || !formDraft) return 0
  let draftNumber = 0
  // 表单2.0有新的组件: 容器(object_)、自增题(array_); 这里面可以嵌套
  // TODO: 第一期需求仅有 array,string, number 几种类型，先如此做处理把
  for (const key in formDraft) {
    if (key.includes('array_') || key.includes('object_')) {
      // 处理自增组件、容器中的题的默认值问题, 需要遍历所有值
      draftNumber += currySum(formDraft[key])
    } else if (key.includes('string_') && formDraft[key]) {
      draftNumber += 1
    } else if (key.includes('number_') && typeof formDraft[key] === 'number') {
      draftNumber += 1
    }
  }
  return draftNumber
}

function count (val) {
  if (val) {
    return 1
  }
  return 0
}

// 计数
function currySum (obj) {
  let num = 0
  // 区分对象还是数组
  if (Array.isArray(obj)) {
    obj.forEach(it => {
      if (typeof it === 'object') { // 数据或对象
        num += currySum(it)
      } else {
        num += count(it)
      }
    })
  // 对象处理
  } else if (typeof obj === 'object') {
    const newArr = Object.keys(obj)
    newArr.forEach(it => {
      if (typeof obj[it] === 'object') { // 数据或对象
        num += currySum(obj[it])
      } else {
        num += count(obj[it])
      }
    })
  }

  return num
}
