<template>
  <div>
    <div class="stats-data-list__table">
      <div
        slot="header"
        style="margin-bottom: 20px; margin-top: 20px;"
      >
        <div
          class="flex search-bar"
        >
          <div class="search-item">
            <span
              class="search-item__label"
              style="width: 60px;"
            >用户</span>
            <el-input
              v-model="search.userName"
              clearable
              size="small"
              placeholder="请输入"
            />
          </div>
          <div class="search-item">
            <span
              class="search-item__label"
              style="width: 60px;"
            >问卷状态</span>
            <el-select
              v-model="search.completeStatus"
              size="small"
              style="width: 120px;"
              clearable
            >
              <el-option
                v-for="(item, key) in STATUS_MAP"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
          <div class="search-item">
            <span
              class="search-item__label"
              style="width: 60px;"
            >提交时间</span>
            <el-date-picker
              v-model="searchFilterTime"
              type="daterange"
              align="right"
              size="small"
              unlink-panels
              clearable
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="handleTimePicker"
            />
          </div>
          <div class="flex justify-end flex-auto">
            <el-button
              size="small"
              @click="clearSearch"
            >
              重置
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleSearch"
            >
              查询
            </el-button>
          </div>
        </div>
      </div>

      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="机构分布"
          name="orgDistribution"
        >
          <el-table
            v-loading="tableLoading"
            :data="orgDisList"
            :header-cell-style="{ background: '#F8F8F8', color: '#333' }"
          >
            <el-table-column
              label="机构名"
              align="center"
              prop="orgName"
            >
              <template slot-scope="scope">
                {{ scope.row.orgName || '/' }}
              </template>
            </el-table-column>

            <el-table-column
              label="录入问卷数"
              align="center"
              prop="count"
            />

            <el-table-column
              label="录入问卷数占比"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.proportion + '%' }}
              </template>
            </el-table-column>
          </el-table>
          <div class="stats-data-list__table__pagination">
            <el-pagination
              :current-page.sync="pageInfo[activeName].page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageInfo[activeName].size"
              layout="total, sizes, prev, pager, next"
              :total="pageInfo[activeName].total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="用户分布"
          name="userDistribution"
        >
          <el-table
            v-loading="tableLoading"
            :data="userDisList"
            :header-cell-style="{ background: '#F8F8F8', color: '#333' }"
          >
            <el-table-column
              label="用户姓名"
              align="center"
              prop="username"
            >
              <template slot-scope="scope">
                {{ scope.row.username || '/' }}
              </template>
            </el-table-column>

            <el-table-column
              label="录入问卷数"
              align="center"
              prop="count"
            />

            <el-table-column
              label="录入问卷数占比"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.proportion + '%' }}
              </template>
            </el-table-column>
          </el-table>
          <div class="stats-data-list__table__pagination">
            <el-pagination
              :current-page.sync="pageInfo[activeName].page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageInfo[activeName].size"
              layout="total, sizes, prev, pager, next"
              :total="pageInfo[activeName].total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="问卷状态分布"
          name="roleDistribution"
        >
          <div style="width: 600px;">
            <DataPieChart
              title=""
              :options="sexPieData"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>

import apiCommon from '@/api/common'
import DataPieChart from './data-pie-chart'
import { generateCircileChartOtions } from '../utils/charts.js'

const API_MAP = {
  orgDistribution: 'getOrgDistribution',
  userDistribution: 'getUserDistribution',
  roleDistribution: 'getSurveyDistribution'
}

const TABLE_DATA_MAP = {
  orgDistribution: 'orgDisList',
  userDistribution: 'userDisList',
  roleDistribution: 'roleDisList'
}

export default {
  components: {
    DataPieChart
  },
  props: {
    orgCode: {
      type: String,
      default: 'all'
    }
  },
  data () {
    return {
      sexPieData: {},
      activeName: 'orgDistribution',
      tableLoading: false,
      orgDisList: [],
      userDisList: [],
      roleDisList: [],
      searchFilterTime: '',
      search: {
        completeStatus: null,
        orgCode: '',
        userName: ''
      },
      pageInfo: {
        orgDistribution: {
          size: 40,
          page: 1,
          total: 0
        },
        userDistribution: {
          size: 40,
          page: 1,
          total: 0
        },
        roleDistribution: {
          size: 40,
          page: 1,
          total: 0
        }
      },
      STATUS_MAP: {
        0: '进行中',
        1: '已完成'
      }
    }
  },
  computed: {
  },
  created () {
    this.fetchTableData()
  },
  methods: {
    handleSearch () {
      this.fetchTableData()
    },
    clearSearch () {
      this.search = {
        completeStatus: null,
        orgCode: 'all',
        userName: '',
        fillStartTime: '',
        fillEndTime: ''
      }
      this.searchFilterTime = ''
      this.fetchTableData()
    },
    handleTimePicker (e) {
      this.search = {
        ...this.search,
        fillStartTime: e ? e[0] : null,
        fillEndTime: e ? e[1] : null
      }
    },
    setChartData (statusData) {
      this.sexPieData = generateCircileChartOtions({
        text: '',
        name: '',
        centerTxt: `${statusData.reduce((acc, cur) => acc + cur.count, 0)}`,
        left: '',
        type: '',
        data: statusData.map(it => ({ name: it.completeStatus, value: it.count, percent: it.proportion }))
      })
    },
    async fetchTableData () {
      const tableType = this.activeName
      const api = API_MAP[tableType]
      try {
        if (api) {
          this.tableLoading = true
          const resp = await apiCommon[api]({
            ...this.search,
            orgCode: this.orgCode === 'all' ? null : this.orgCode,
            page: tableType === 'roleDistribution' ? null : this.pageInfo[tableType].page,
            size: tableType === 'roleDistribution' ? null : this.pageInfo[tableType].size
          })

          if (resp.success) {
            this[TABLE_DATA_MAP[tableType]] = resp.data
            if (tableType === 'roleDistribution') {
              this.setChartData(resp.data)
            } else {
              this.pageInfo[tableType].total = resp.paging.total
              this.pageInfo[tableType].page = resp.paging.page
            }
          }
        }
      } catch (e) {} finally {
        this.tableLoading = false
      }
    },
    handleClick (tab) {
      this.activeName = tab.name
      this.$nextTick(() => {
        this.fetchTableData()
      })
    },
    handleSizeChange (curSize) {
      this.pageInfo.page = 1
      this.pageInfo.size = curSize
      this.fetchTableData()
    },
    handleCurrentChange (curPage) {
      this.pageInfo.page = curPage
      this.fetchTableData()
    }
  }
}
</script>
<style lang="scss">
.stats-data-list__table__pagination {
  float: right;
  margin-top: 25px;
}

.stats-data-list__table .el-table__fixed-right {
  z-index: 1;
}

.stats-data-list__table {
  .search-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .search-item-name {
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 20px;
    }
  }
}

.table-item__upload {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.img-total__text {
  margin-left: 12px;
}
</style>
