var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col h-full bisForm" },
    [
      _c("span", [_vm._v("检查结果")]),
      _c(
        "el-form",
        {
          ref: "bForm",
          staticClass: "flex-1",
          attrs: { "label-position": "top", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "患者皮肤癣菌群面积（BSA）", prop: "bsa" } },
            [
              _c("Uploader", {
                ref: "bsa",
                attrs: {
                  tip: "请上传典型临床图片",
                  size: _vm.imgSize,
                  "readonly-disabled": _vm.disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.imgChange($event, "bsa")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "显微镜直接镜检的结果", prop: "cmscc" } },
            [
              _c("Uploader", {
                ref: "cmscc",
                attrs: {
                  tip: "请上传镜检图片",
                  size: _vm.imgSize,
                  "readonly-disabled": _vm.disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.imgChange($event, "cmscc")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "显微镜荧光镜检的结果", prop: "fld" } },
            [
              _c("Uploader", {
                ref: "fld",
                attrs: {
                  tip: "请上传镜检图片",
                  size: _vm.imgSize,
                  "readonly-disabled": _vm.disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.imgChange($event, "fld")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否有对患者的皮肤进行真菌培养？",
                prop: "isFungi",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { disabled: _vm.disabled, placeholder: "请选择" },
                  model: {
                    value: _vm.form.isFungi,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isFungi", $$v)
                    },
                    expression: "form.isFungi",
                  },
                },
                _vm._l(_vm.isFungiOptions, function (item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item, value: +key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          [2].includes(_vm.form.isFungi)
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "真菌培养的结果是哪种真菌？",
                    prop: "fungiType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.disabled, placeholder: "请选择" },
                      model: {
                        value: _vm.form.fungiType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "fungiType", $$v)
                        },
                        expression: "form.fungiType",
                      },
                    },
                    _vm._l(_vm.fungiTypeOptions, function (item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item, value: key },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          [1, 2].includes(_vm.form.isFungi)
            ? _c(
                "el-form-item",
                { attrs: { label: "请上传真菌培养结果的图片", prop: "fungi" } },
                [
                  _c("Uploader", {
                    ref: "fungi",
                    attrs: {
                      tip: "请上传培养图片",
                      size: _vm.imgSize,
                      "readonly-disabled": _vm.disabled,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.imgChange($event, "fungi")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "请上传患者的完整病例", prop: "cases" } },
            [
              _c("Uploader", {
                ref: "cases",
                attrs: { size: _vm.imgSize, "readonly-disabled": _vm.disabled },
                on: {
                  change: function ($event) {
                    return _vm.imgChange($event, "cases")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.haveBtn
        ? _c(
            "div",
            { staticClass: "sticky-bottom" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "16px" },
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit("bForm", "SAVE")
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-popconfirm",
                {
                  attrs: { title: "确认保存并提交吗?提交后不可修改!" },
                  on: {
                    confirm: function ($event) {
                      return _vm.onSubmit("bForm", "SUBMIT")
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        type: "primary",
                        size: "small",
                      },
                      slot: "reference",
                    },
                    [_vm._v(" 保存并提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }