let authUrl = ''
// 你也可以不配development，在ajax.js中自己配一个token
if (process.env.VUE_APP_ENV === 'test') {
  authUrl = 'https://authtest.cmvalue.com'
} else if (process.env.VUE_APP_ENV === 'preview') {
  authUrl = 'https://authuat.cmvalue.com'
} else if (process.env.VUE_APP_ENV === 'development') {
  authUrl = 'https://authtest.cmvalue.com'
} else {
  authUrl = 'https://auth.cmvalue.com'
}
export default authUrl
