<script>
import { mapState } from 'vuex'
// import routes from '@/router/routers'
// import MENU from '@/config/menu'
import MenuItem from './menu-item'
import MenuSubItem from './menu-sub-item'

export default {
  components: {
    MenuItem,
    MenuSubItem
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    },
    menuList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      // menuList: MENU
    }
  },
  computed: {
    ...mapState('app', {
      // userRole: state => state.user.role
      menuResources: state => state.menuResources
    })
  },
  watch: {
  },
  async created  () {
    // this.menuList = await setMenuNav()
  },
  methods: {
  },
  render (h) {
    const self = this
    const generateMenuItemSimple = menu => {
      return h('menu-item', {
        props: {
          menu
        }
      })
    }
    const generateMenuItemSub = menu => {
      return h('menu-sub-item', {
        props: {
          menu
        }
      })
    }
    const menuItems = []
    self.menuList.forEach(element => {
    // self.navList.forEach(element => {// 有子元素就调用创建menu-sub-item
      if (element.children && element.children.length > 0) {
        menuItems.push(generateMenuItemSub(element))
      } else {
        menuItems.push(generateMenuItemSimple(element))
      }
    })
    // 最外层包上el-menu，可以看elementui例子
    const menuEle = h('el-menu', {
      props: {
        router: true,
        collapse: self.isCollapse,
        backgroundColor: '#545c64',
        textColor: '#fff',
        activeTextColor: '#ffd04b',
        defaultActive: self.$route.path
      },
      class: ['el-menu-vertical-demo']
    }, [menuItems])

    return h('div', {
      class: 'menu-nav'
    }, [menuEle])
  }
}
</script>

<style lang="scss">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

.el-menu {
  border-right: none;
}

</style>
