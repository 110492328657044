var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.data,
            stripe: "",
            "tooltip-effect": "dark",
            "header-cell-style": { background: "#F8F8F8", color: "#333" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "slug", label: "问卷ID", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.slug || "/") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "doctorName", label: "调查员", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "机构", align: "center", prop: "orgName" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userInfo",
              label: "用户信息",
              "min-width": "160",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "问卷状态", align: "center", "min-width": "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "status" }, [
                      _c("div", {
                        class:
                          "status__dot status__dot--" +
                          _vm.STATUS_ENUM[scope.row.status],
                      }),
                      _c("div", { staticClass: "status__txt" }, [
                        _vm._v(
                          " " + _vm._s(_vm.STATUS_MAP[scope.row.status]) + " "
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createdAt",
              label: "提交时间",
              align: "center",
              width: "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.createdAt || "-") + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updatedAt",
              label: "更新时间",
              align: "center",
              width: "100px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              fixed: "right",
              width: "120px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: scope.row.status == "ONGOING",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.viewDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }