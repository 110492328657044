var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mf-ctcl log-dialog-body", on: { scroll: _vm.scroll } },
    [
      _vm.lists.length
        ? _c(
            "el-steps",
            { attrs: { direction: "vertical" } },
            _vm._l(_vm.lists, function (it) {
              return _c("el-step", { key: it.id }, [
                _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v(
                    " " +
                      _vm._s(it.operatorName) +
                      " " +
                      _vm._s(_vm.ActionTypeMap[it.operateType]) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { attrs: { slot: "description" }, slot: "description" },
                  [
                    it.remark
                      ? _c("p", [_vm._v(" 备注: " + _vm._s(it.remark) + " ")])
                      : _vm._e(),
                    _c("span", { staticStyle: { color: "#999" } }, [
                      _vm._v(_vm._s(it.createdAt)),
                    ]),
                  ]
                ),
              ])
            }),
            1
          )
        : _c("el-empty", {
            staticStyle: { "padding-right": "30px" },
            attrs: { "image-size": 100, description: "暂无记录" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }