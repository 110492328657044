/**
 * 创建 Blob 实现 post 下载文件
 *
 * @param {*} res     接收到的请求
 */

function download (res, config = {}) {
  if (res.status !== 200) {
    return console.log('cuowu', res)
  }
  const disposition = res.headers.filename
  const fileName = disposition

  const { type = 'application/vnd.ms-exce', encoding = 'UTF-8' } = config

  const blob = new Blob([res.data], { type: type, encoding: encoding })
  if ('download' in document.createElement('a')) {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = fileName
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  } else { // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

// 轮训下载
function downloadSubscribe (asyncfunc, { params, time }) {
  // setTimeout(asyncfunc, time)

  return new Promise((resolve, reject) => {
    function polling (promisefunc, config) {
      const timer = setTimeout(async () => {
        clearTimeout(timer)
        // try {
        const res = await promisefunc(config.params)
        // console.log('----', res)
        if (res.status === 200) {
          // eslint-disable-next-line eqeqeq
          if (res.data.code == 200) {
            return resolve(res.data)
          } else {
            return polling(promisefunc, config)
          }
        } else {
          reject(res)
        }
        // } catch (e) {
        //   console.log('出错', e)
        //   // TODO: 对于异常的接口, 这个处理是有问题的,需要根据状态码来判断
        //   return polling(promisefunc, config)
        // }
      }, config.time || 1000)
    }
    try {
      polling(asyncfunc, { params, time })
    } catch (e) {
      reject(e)
    }
  })
}

function downloadExcel (res) {
  download(res, { type: 'application/vnd.ms-exce' })
}

function downloadStream (res) {
  download(res, { type: 'application/octet-stream' })
}

export {
  downloadExcel,
  downloadStream,
  downloadSubscribe
}
