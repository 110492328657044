import ajax from '@/libs/ajax'

const getUserInfo = () => ajax.request({
  url: '/uc/api/admin/present-user'
})

const getAvailableOrg = () => ajax.request({
  url: '/uc/api/admin/present-user/available-org'
})

const switchOrg = orgCode => ajax.request({
  url: '/uc/api/admin/present-user/switch-org',
  method: 'post',
  data: {
    orgCode
  }
})

export default {
  getUserInfo,
  getAvailableOrg,
  switchOrg
}
