var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header__left" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "org_select" },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                on: { change: _vm.switchOrg },
                model: {
                  value: _vm.project,
                  callback: function ($$v) {
                    _vm.project = $$v
                  },
                  expression: "project",
                },
              },
              _vm._l(_vm.projectList, function (item) {
                return _c("el-option", {
                  key: item.code,
                  attrs: { label: item.name, value: item.code },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "header__right" }, [
        _c("div", { staticClass: "user-info" }, [
          _c("p", { staticClass: "name" }, [
            _vm._v(" " + _vm._s(_vm.admin.username || "没有姓名") + " "),
          ]),
          _c(
            "p",
            {
              staticClass: "name",
              on: {
                click: function ($event) {
                  return _vm.logout()
                },
              },
            },
            [_vm._v(" 退出 ")]
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "main", class: { "main-collapse": _vm.isCollapse } },
      [
        _c(
          "div",
          { staticClass: "aside" },
          [
            _c("menu-list", {
              attrs: {
                "is-collapse": _vm.isCollapse,
                "menu-list": _vm.menuList,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "content-box" }, [
          _vm.breadcrumb.length !== 0
            ? _c(
                "div",
                { staticClass: "bcp-content-breadcrumb" },
                [
                  _c(
                    "el-breadcrumb",
                    _vm._l(_vm.breadcrumb, function (item, index) {
                      return _c(
                        "el-breadcrumb-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.label !== "内容管理",
                              expression: "item.label!=='内容管理'",
                            },
                          ],
                          key: index,
                          attrs: { to: item.path, replace: "" },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "page-system" },
            [
              _vm.$route.meta.keepAlive
                ? _c("keep-alive", [_c("router-view")], 1)
                : _vm._e(),
              !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cmv_img" }, [
      _c("img", {
        attrs: {
          src: "https://cmvalue-web-cdn.oss-cn-hangzhou.aliyuncs.com/mini-repo/case-exchange/images/cmv-logo.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }