<template>
  <div>
    <el-dialog
      title=""
      width="80%"
      :visible.sync="show"
    >
      <div class="dialog-content">
        <!-- <div class="img-view"> -->
        <div
          class="img-view"
        >
          <el-image
            style="width: 100%; height: 100%;"
            fit="contain"
            :src="value.url"
          />
        </div>
        <!-- </div> -->
        <div class="img-note">
          <span>添加注释</span>
          <div>
            <el-input
              v-model="textarea2"
              type="textarea"
              :autosize="{ minRows: 14, maxRows: 16}"
              placeholder="请输入内容"
            />
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="removeNote">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="saveNote"
        >
          保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import apiCommon from '@/api/common'

export default {
  props: {
    value: {
      type: Object,
      default () {
        return {
          url: '',
          note: ''
        }
      }
    }
  },
  data () {
    return {
      show: false,
      textarea2: ''
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    showLog (value) {
      this.textarea2 = value.note || value.description
      this.show = true
    },
    removeNote () {
      this.show = false
    },
    async saveNote () {
      const resp = await apiCommon.uploadImgNote({ attachmentId: this.value.id, description: this.textarea2 })
      if (resp.success) {
        this.show = false
        this.$emit('input', { ...this.value, note: this.textarea2 })
      } else {
        this.$message({
          message: resp.msg,
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss">
.dialog-content {
  display: flex;
  //   height: 80vh;

  .img-view {
    flex: 0 0 70%;
    width: 70%;
    height: 650px;
  }

  .img-note {
    flex: 1;
    margin-left: 50px;
  }
}
</style>
