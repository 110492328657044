const app = {
  namespaced: true,
  state: {
    admin: {
      active: false,
      cellphone: '',
      company: '',
      sex: '',
      supportStaff: '',
      userId: '',
      username: ''
    },
    white: { // 白名单需要
      roleMap: {}
    }
  },
  getter: {},
  mutations: {
    SET_DATA (state, obj) {
      for (const [key, value] of Object.entries(obj)) {
        state[key] = value
      }
    }
  },
  actions: {
    setData ({ commit }, obj) {
      commit('SET_DATA', obj)
    }
  }
}

export default app
