<template>
  <div class="data-detail">
    <el-card>
      <div
        class="flex items-center mb-5"
        style="justify-content: space-between"
      >
        <span class="text-base mr-2">数据统计</span>
        <el-button
          size="small"
          @click="$router.go(-1)"
        >
          返回
        </el-button>
      </div>
      <div class="data-overview flex flex-wrap">
        <div
          v-for="(it, idx) in overList"
          :key="idx"
          class="overview-item"
        >
          <div class="overview-item-title">
            {{ it.name }}
          </div>
          <div class="number-unit">
            <span class="number">{{ it.number }}</span>
            <span class="unit"> {{ it.unit }}</span>
            <span class="unit"> 本周新增 </span>
            <span class="highlight-number"> +{{ it.increase }}</span>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="mt-4">
      <div>
        <!-- slot="header" -->
        <div
          class="flex mb-5 search-bar"
        >
          <div
            v-if="currentType !== 'OTHER'"
            class="search-item"
          >
            <span
              class="search-item__label"
            >机构</span>
            <el-select
              v-model="search.orgCode"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, key) in orgnizations"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
          <div class="search-item flex">
            <span
              class="search-item__label"
              style="flex: 0 0 60px;"
            >调查员</span>
            <el-input
              v-model="search.doctorName"
              clearable
              size="small"
              placeholder="请输入"
              @keyup.enter.native="searchList"
            />
          </div>
          <div class="search-item">
            <span class="search-item__label">性别</span>
            <el-select
              v-model="search.sex"
              size="small"
              style="width: 100px;"
              clearable
            >
              <el-option
                v-for="(item, key) in SexEnum"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
          <div class="search-item">
            <span class="search-item__label">年龄</span>
            <el-select
              v-model="search.age"
              size="small"
              style="width: 120px;"
              clearable
            >
              <el-option
                v-for="(item, key) in AgeEnum"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
          <div class="search-item">
            <span
              class="search-item__label"
              style="flex: 0 0 60px;"
            >问卷状态</span>
            <el-select
              v-model="search.status"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, key) in STATUS_MAP"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
        </div>
        <div
          class="flex search-bar"
        >
          <div class="search-item">
            <span class="search-item__label">提交时间</span>
            <el-date-picker
              v-model="createTime"
              type="daterange"
              align="right"
              size="small"
              unlink-panels
              clearable
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="PickerOptions"
              value-format="yyyy-MM-dd"
              @change="timePicker($event, 'createTime')"
            />
            <!-- @change="timePicker" -->
          </div>
          <div class="search-item">
            <span class="search-item__label">更新时间</span>
            <el-date-picker
              v-model="updateTime"
              type="daterange"
              align="right"
              size="small"
              unlink-panels
              clearable
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="PickerOptions"
              value-format="yyyy-MM-dd"
              @change="timePicker($event, 'updateTime')"
            />
            <!-- @change="timePicker" -->
          </div>
          <div class="flex flex-auto justify-end">
            <el-button
              size="small"
              @click="clearSearch"
            >
              重置
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="searchList"
            >
              查询
            </el-button>
          </div>
        </div>
      </div>
      <el-tabs
        v-model="activeName"
        style="margin-top: 20px"
        @tab-click="searchList"
      >
        <el-tab-pane
          v-if="currentType !== 'OTHER'"
          label="机构"
          name="first"
        />
        <el-tab-pane
          label="调查员"
          name="second"
        />
        <el-tab-pane
          label="问卷状态 / 性别 / 年龄"
          name="third"
        />
      </el-tabs>
      <TableList1
        v-if="activeName === 'first'"
        :data="tableData1"
        :loading="loading"
      />
      <TableList2
        v-if="activeName === 'second'"
        :data="tableData2"
        :loading="loading"
      />
      <PieChart
        v-if="activeName === 'third'"
        ref="formpie"
      />
    </el-card>
  </div>
</template>

<script>
import apiPfjq from '@/api/project/pys-pfjq.js'
import TableList1 from './components/table-list-1'
import TableList2 from './components/table-list-2'
import PieChart from './components/pie-chart'
import { PickerOptions, SexEnum } from '@/config/constant.js'
import { STATUS_MAP, AgeEnum } from '../config/constant.js'

export default {
  name: 'Home',
  components: {
    TableList1,
    TableList2,
    PieChart
  },
  data () {
    return {
      fullscreenLoading1: false,
      fullscreenLoading2: false,
      orgnizations: {},
      activeName: 'second',
      currentType: 'OTHER',
      formCode: '',
      STATUS_MAP,
      SexEnum,
      AgeEnum,
      tableData1: [],
      tableData2: [],
      loading: false,
      versionList: [],
      search: {
        orgCode: undefined,
        doctorName: '',
        sex: undefined,
        age: undefined,
        status: undefined
      },
      createTime: [],
      updateTime: [],
      overList: [],
      PickerOptions,
      pageBean: {
        page: 1,
        size: 20,
        total: 0
      }
    }
  },
  async created () {
    this.getOrgs()
    this.getOverview()
    this.searchList()
  },
  methods: {
    handleClick (active) {
      console.log('handleClick', active)
    },
    timePicker (v, prop) {
      console.log('timePicker', v, prop)
      this[prop] = v || []
    },
    clearSearch () {
      this.search.orgCode = undefined
      this.search.doctorName = ''
      this.search.sex = undefined
      this.search.age = undefined
      this.search.status = undefined

      this.createTime = []
      this.updateTime = []
      this.page = 1
      this.searchList()
    },
    async getOverview () {
      const resp = await apiPfjq.getDataOverview({})
      const list = []
      if (resp.success) {
        this.currentType = resp.data.type
        if (resp.data.type === 'OTHER') {
          list.push({
            name: '累计录入',
            number: resp.data.currentAllCount,
            unit: '份',
            increase: resp.data.currentWeekCount
          })
        } else {
          list.push({
            name: '本单位-累计录入',
            number: resp.data.currentAllCount,
            unit: '份',
            increase: resp.data.currentWeekCount
          })
          list.push({
            name: '项目整体-累计录入',
            number: resp.data.allCount,
            unit: '份',
            increase: resp.data.weekCount
          })
        }
        this.overList = list
      }
    },
    getSearchParams () {
      return {
        ...this.search,
        // formCode: this.formCode,
        // page: this.pageBean.page,
        // size: this.pageBean.size,
        createStartTime: this.createTime?.[0],
        createEndTime: this.createTime?.[1],
        updateStartTime: this.updateTime?.[0],
        updateEndTime: this.updateTime?.[1]
      }
    },
    async searchList () {
      this.loading = true
      const serch = this.getSearchParams()

      try {
        switch (this.activeName) {
          case 'first':
            await this.getOrgTableList(serch)
            break
          case 'second':
            await this.getDoctorTableList(serch)
            break
          case 'third':
            await this.getFormPieChart(serch)
            break
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    async getOrgTableList (search) {
      // getOrgData
      const resp = await apiPfjq.getOrgData(search)
      if (resp.success) {
        this.tableData1 = resp.data
      }
    },
    async getDoctorTableList (search) {
      const resp = await apiPfjq.getDoctorData(search)
      if (resp.success) {
        this.tableData2 = resp.data
      }
    },
    async getFormPieChart (search) {
      const resp = await apiPfjq.getFormStatisticData(search)
      if (resp.success) {
        // TODO: 先拿到数据
        this.$refs.formpie.setData(resp.data)
      }
    },
    async getOrgs () {
      const resp = await apiPfjq.getOrgList()
      if (resp.success) {
        this.orgnizations = resp.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-detail {
  padding: 20px;
  background-color: #F7F8FA;
  .version-switch {
    // margin-bottom: 21px;
  }
  .data-overview {
    .overview-item {
      box-sizing: border-box;
      padding: 16px 14px;
      background: #f7f8fa;
      border: 1px solid #ffffff;
      border-radius: 4px;
      flex: 1;
      margin: 0 12px 12px 0;
      width: calc((100% - 36px) / 3);
      min-width: calc((100% - 36px) / 3);
      max-width: calc((100% - 36px) / 3);
      &:nth-child(3) {
        margin-right: 0;
      }

      &-title {
        // width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 22px;
      }

      .number-unit {
        .number {
          // width: 51px;
          // height: 38px;
          font-size: 30px;
          font-family: D, D-Bold;
          font-weight: 700;
          text-align: left;
          color: rgba(0,0,0,0.85);
          line-height: 38px;
        }
        .unit {
          // width: 14px;
          // height: 22px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0,0,0,0.65);
          line-height: 22px;
        }

        .highlight-number {
          // width: 38px;
          height: 22px;
          font-size: 14px;
          font-family: HelveticaNeue, HelveticaNeue-Regular;
          font-weight: 400;
          text-align: left;
          color: #2080e3;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
