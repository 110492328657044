import Home from '@/views/mf-ctcl/index.vue'
// import About from '@/views/mf-ctcl/about'

export default [
  {
    path: '/mf-ctcl',
    name: 'MFHome',
    component: Home,
    async beforeEnter (to, from, next) {
      document.title = '数据详情'
      next()
    }
  }
]
