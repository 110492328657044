<template>
  <div class="charts-data">
    <span class="charts-data_nav">{{ title }}</span>
    <v-chart
      v-if="JSON.stringify(options) !== '{}'"
      style="height: 203px;"
      autoresize
      :option="options"
    />
    <div
      v-else
      class="charts-data_none"
    >
      <div>0</div>
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.charts-data {
  &_none {
    display: flex;
    justify-content: space-between;
    width: 400px;
    div {
      font-size: 14px;
      text-align: center;
      color: #bebfbf;
      background: #fff;
      line-height: 88px;
      border-radius: 100%;
      width: 88px;
      height: 88px;
      border: 28px solid rgba(226, 228, 229, 0.9);
    }
    span {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.25);
      display: block;
      height: 148px;
      line-height: 148px;
      width: 148px;
      text-align: center;
    }
  }
  &_nav {
    display: block;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
  }
}
</style>
