<template>
  <el-drawer
    title="详情"
    size="800px"
    :destroy-on-close="true"
    :close-on-press-escape="false"
    :visible.sync="drawer"
    custom-class="hello-motor"
    :wrapper-closable="false"
    :append-to-body="true"
    @close="handleClose"
  >
    <!-- 这部分表单:250px -->
    <div class="drawer-body">
      <span>基本信息</span>
      <div class="basic">
        <div class="basic-item">
          <div class="basic-item-key">
            问卷ID：
          </div>
          <div class="basic-item-val">
            {{ formData.slug }}
          </div>
        </div>
        <div class="basic-item">
          <div
            class="basic-item-key"
          >
            患者：
          </div>
          <div class="basic-item-val">
            {{ formData.userInfo | getName }}
          </div>
        </div>
        <div class="basic-item">
          <div class="basic-item-key">
            问卷状态：
          </div>
          <div class="basic-item-val">
            {{ STATUS_MAP[formData.status] }}
          </div>
        </div>
        <div class="basic-item">
          <div class="basic-item-key">
            提交时间：
          </div>
          <div class="basic-item-val">
            {{ formData.createdAt }}
          </div>
        </div>
      </div>
      <div class="nomal-form">
        <BasicForm
          ref="basicForm"
          @success="submitted"
        />
        <!-- <PlainForm
          :form="form"
        /> -->
      </div>
      <span>问卷信息</span>
      <div
        v-for="it in formDetailData"
        :key="it.key"
        class="form-data-item"
      >
        <div class="form-data-item-que">
          {{ it.label }}
        </div>
        <span class="form-data-item-ans">{{ it.value || '/' }}</span>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import apiPfjq from '@/api/project/pys-pfjq.js'
import BasicForm from './basic-form'
// import PlainForm from './plain-form-data'
import { isEmptyObject } from '@/utils/object'
import { STATUS_MAP } from '../../config/constant.js'

export default {
  components: {
    BasicForm
    // PlainForm
  },
  filters: {
    getName (val) {
      if (typeof val === 'string') {
        return val.split('·')[0]
      }
      return val
    }
  },
  data () {
    return {
      drawer: false,
      fromDetail: false,
      formSlug: '',
      STATUS_MAP,
      formData: {
        slug: '',
        userInfo: '',
        status: '',
        createdAt: ''
      },
      formDetailData: [],
      form: {
        id: '',
        slug: '', // 拿数据的
        pName: '',
        entryType: 2,
        idCard: '',
        status: '',
        isExpert: '', // 协和专家
        cellphone: '',
        sign: '',
        remark: ''
      },
      dyForm: {}
    }
  },
  computed: {
  },
  methods: {
    handleClose () {
      this.drawer = false
      this.$emit('close')
    },
    async setData (data = {}) {
      if (!isEmptyObject(data)) {
        this.formData = { ...data }
        // this.getSign(data.id)
      } else {
        this.form = {
          id: '',
          slug: '', // 拿数据的
          pName: '',
          entryType: 2,
          status: '',
          isExpert: false,
          idCard: '',
          cellphone: '',
          sign: '',
          remark: ''
        }
      }
      this.formSlug = data.slug
      this.getFormDetail(data.slug)
      this.drawer = true
      // this.$nextTick(() => {
      //   this.$refs.basicForm.setData({ slug: data.slug })
      // })
    },
    getSign (id) {
      if (id) {
        apiPfjq.getSignImg({ id }).then(res => {
          if (res.success) {
            this.form.sign = res.data.url
          }
        })
      }
    },
    editDyForm (v) {
      if (v) {
        // 可以编辑
      }
      this.$refs.mdy.triggerEdit()
    },
    async postAudit (data) { // 提交审核
      const resp = await apiPfjq.postAudit(data)
      if (resp.success) {
        this.handleClose()
      }
    },
    async postAuditData (data) { // 审核结果
      const resp = await apiPfjq.postAuditData(data)
      if (resp.success) {
        this.handleClose()
      }
    },
    async submitted (close) {
      if (close) {
        this.handleClose()
      } else {
        this.$message.success('保存成功')
      }
    },
    async getFormDetail (slug) {
      const resp = await apiPfjq.getDetailData({ slug })
      if (resp.success) {
        const { fungiType, isFungi } = resp.data
        if (resp.data.fileArr) {
          // 判断医生上传图片
          this.$refs.basicForm.setData({ slug, isFungi, fungiType, ...resp.data.fileArr }, this.formData.status === 'COMPLETED')
        } else {
          this.$refs.basicForm.setData({ slug, isFungi, fungiType }, this.formData.status === 'COMPLETED')
        }
        const keyVal = resp.data.formData.dataMeta
        if (Array.isArray(resp.data.formMetas)) {
          const list = []
          resp.data.formMetas.forEach((c) => {
            const key = c.metaKey
            const label = c.metaLabel
            const value = keyVal?.[key]

            if (c.dynamicMeta && key && label && value) {
              list.push({
                key,
                label,
                value: Array.isArray(value) ? value.join(',') : value
              })
            }
          })
          this.formDetailData = list
          console.log('----', this.formDetailData)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.drawer-body {
  padding-left: 25px;

  & > span {
    // width: 56px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 22px;
  }

  .basic {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 16px;
    margin-bottom: 16px;
    // width: 726px;
    border-bottom: 1px solid #e6e6e6;
    &-item {
      display: flex;
      flex: 0 0 50%;
      margin-top:  16px;

      &-key {
        flex: 0 0 70px;
        width: 70px;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 14px;
      }

      &-val {
        flex: 1;
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 14px;
      }
    }
  }

  .nomal-form {
    // width: 33.33333%;
    // position: relative;
    // height: calc(100vh - 74px);
    // overflow-y: auto;
  }

  .form-data-item {
    margin: 18px 0;
    &-que {
      // width: 38px;
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 22px;
    }

    &-ans {
      // width: 24px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 17px;
    }
  }
  .dy-form-outline {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 74px);

    .stady-center {
      width: 375px;
      height: 97%;
      overflow-y: auto;
    }
  }
}
</style>
