const mdy = {
  namespaced: true,
  state: {
    utmSource: 'dynamic-form',
    code: null,
    orgCode: null,
    patientId: '', // 患者ID
    previewCode: null,
    callback: null,
    feedbackText: null,
    subjectNumber: 0,
    agreed: false,
    userId: null,
    formVersion: null,
    formAction: '',
    formActionResource: '',
    isIntroduction: '',
    formDraft: {},
    formContent: {},
    preUrl: '',
    formName: ''
  },
  getter: {},
  mutations: {
    SET_DATA (state, obj) {
      for (const [key, value] of Object.entries(obj)) {
        state[key] = value
      }
    }
  },
  actions: {
    setData ({ commit }, obj) {
      commit('SET_DATA', obj)
    }
  }
}

export default mdy
