<template>
  <div class="data-detail">
    <el-card>
      <!-- <div class="flex items-center mb-5">
        <span class="text-base mr-2">数据概况</span> <el-select
          v-model="search.formVersion"
          style="width: 300px"
          size="small"
          placeholder="请选择"
          @change="changeVersion"
        >
          <el-option
            v-for="item in versionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div> -->
      <div class="data-overview flex flex-wrap">
        <div
          v-for="(it, idx) in overList"
          :key="idx"
          class="overview-item"
        >
          <div class="overview-item-title">
            {{ it.name }}
          </div>
          <div class="number-unit">
            <span class="number">{{ it.number }}</span>
            <span class="unit"> {{ it.unit }}</span>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="mt-4">
      <div
        slot="header"
      >
        <div
          class="flex mb-5 search-bar"
        >
          <div class="search-item flex">
            <span
              class="search-item__label"
              style="width: 60px;"
            >参与人</span>
            <el-input
              v-model="search.pname"
              clearable
              size="small"
              placeholder="请输入"
              @keyup.enter.native="searchList"
            />
          </div>
          <div class="search-item">
            <span
              class="search-item__label"
            >机构</span>
            <el-select
              v-model="search.orgCode"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, key) in orgnizations"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
          <div class="search-item">
            <span class="search-item__label">填写方式</span>
            <el-select
              v-model="search.entryType"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, key) in EntryTypes"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
          <div class="search-item">
            <span class="search-item__label">问卷状态</span>
            <el-select
              v-model="search.status"
              size="small"
              clearable
            >
              <el-option
                v-for="(item, key) in STATUS_MAP"
                :key="key"
                :label="item"
                :value="key"
              />
            </el-select>
          </div>
        </div>
        <div
          class="flex search-bar"
        >
          <div class="search-item">
            <span class="search-item__label">创建时间</span>
            <el-date-picker
              v-model="createTime"
              type="daterange"
              align="right"
              size="small"
              unlink-panels
              clearable
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="PickerOptions"
              value-format="yyyy-MM-dd"
              @change="timePicker($event, 'createTime')"
            />
            <!-- @change="timePicker" -->
          </div>
          <div class="search-item">
            <span class="search-item__label">更新时间</span>
            <el-date-picker
              v-model="updateTime"
              type="daterange"
              align="right"
              size="small"
              unlink-panels
              clearable
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="PickerOptions"
              value-format="yyyy-MM-dd"
              @change="timePicker($event, 'updateTime')"
            />
            <!-- @change="timePicker" -->
          </div>
          <div class="flex flex-auto justify-end">
            <el-button
              size="small"
              @click="clearSearch"
            >
              重置
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="searchList"
            >
              查询
            </el-button>
          </div>
        </div>
      </div>
      <div class="flex item-centers mb-4">
        <div class="flex items-center text-base">
          问卷列表
        </div>
        <div
          class="flex flex-auto justify-end"
          style="flex: 1;"
        >
          <el-button
            v-loading.fullscreen.lock="fullscreenLoading1"
            v-per="'surveya001'"
            type="primary"
            size="small"
            @click="exportPdf()"
          >
            导出问卷协议
          </el-button>
          <el-button
            v-per="'surveya002'"
            v-loading.fullscreen.lock="fullscreenLoading2"
            type="primary"
            size="small"
            @click="exportForm()"
          >
            导出问卷
          </el-button>
          <el-button
            v-per="'surveya003'"
            type="primary"
            size="small"
            @click="inputForm()"
          >
            录入问卷
          </el-button>
        </div>
      </div>
      <table-list
        :data="tableData"
        :loading="loading"
        @view="openDrawer"
      />
      <div class="pagination">
        <el-pagination
          :current-page="pageBean.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageBean.size"
          layout="total, sizes, prev, pager, next"
          :total="pageBean.total"
          @size-change="changePageSize($event, 'answerList')"
          @current-change="changePage($event, 'answerList')"
        />
      </div>
    </el-card>
    <DetailDrawer
      ref="detail"
      @close="changeVersion"
    />
    <!-- 导出数据确认框 -->
    <ExportDataDialog
      ref="export"
      @confirm="exportConfirm"
    />
  </div>
</template>

<script>
import apiMf from '@/api/project/mf-ctcl.js'
import apiCommon from '@/api/common.js'
import TableList from './components/table-list'
import DetailDrawer from './components/detail-drawer/index'
import ExportDataDialog from '@/components/export-data-confirm-dialog/index'

// import { STATUS_MAP } from './config/constant.js'
import { PickerOptions } from '@/config/constant.js'
import { downloadSubscribe } from '@/utils/download.js'

export default {
  name: 'Home',
  components: {
    TableList,
    DetailDrawer,
    ExportDataDialog
  },
  data () {
    return {
      fullscreenLoading1: false,
      fullscreenLoading2: false,
      orgnizations: {},
      formCode: '',
      formRole: '',
      STATUS_MAP: {},
      tableData: [],
      loading: false,
      EntryTypes: Object.freeze({
        1: '患者填写',
        2: '医生录入'
      }),
      versionList: [],
      search: {
        // formVersion: '',
        pname: undefined,
        orgCode: undefined,
        entryType: undefined,
        status: undefined
      },
      createTime: [],
      updateTime: [],
      overList: [],
      PickerOptions,
      pageBean: {
        page: 1,
        size: 20,
        total: 0
      }
    }
  },
  async created () {
    this.getFormInfo()
    this.getOrgs()
    this.getStatus()
  },
  methods: {
    getFormInfo () {
      apiMf.getFormInfo().then(resp => {
        if (resp.success) {
          this.formCode = resp.data.formCode
          this.formRole = resp.data.role
          this.$store.dispatch('mdy/setData', {
            code: this.formCode,
            orgCode: resp.data.orgCode
            // 不要在store中放太多东西, mdy是给表单用的, 应该与单个项目数据分开
            // role: resp.data.role
          })
          this.changeVersion()
        }
      })
    },
    getFormVersion () {
      apiCommon.getFormVersions({ formCode: this.formCode }).then(resp => {
        if (resp.success) {
          const len = resp.data.length
          this.versionList = resp.data.map((item, idx) => {
            if (idx === 0) {
              // this.search.formVersion = item.version
            }
            return {
              value: item.version,
              label: `版本 ${len - idx} 创建于 ${item.createTime}`
            }
          })
          this.changeVersion()
        }
      })
    },
    timePicker (v, prop) {
      console.log('timePicker', v, prop)
      this[prop] = v || []
    },
    clearSearch () {
      this.search.pname = undefined
      this.search.orgCode = undefined
      this.search.entryType = undefined
      this.search.status = undefined
      this.search.pname = undefined
      this.createTime = []
      this.updateTime = []
      this.page = 1
      this.searchList()
    },
    changeVersion (v) {
      this.getOverview()
      this.searchList()
    },
    async getStatus () {
      const resp = await apiMf.getStatusList()
      if (resp.success) {
        this.STATUS_MAP = resp.data
      }
    },
    async getOverview () {
      const resp = await apiMf.getDataOverview({ formCode: this.formCode })
      const list = []
      if (resp.success) {
        list.push({
          name: '今日新增',
          number: resp.data.todayCount,
          unit: '份'
        })
        list.push({
          name: '进行中',
          number: resp.data.ongoingCount,
          unit: '份'
        })
        list.push({
          name: '待确认',
          number: resp.data.unconfirmedCount,
          unit: '份'
        })
        list.push({
          name: '待提审',
          number: resp.data.waitArraignedCount,
          unit: '份'
        })
        list.push({
          name: '待审核',
          number: resp.data.uncheckedCount,
          unit: '份'
        })
        list.push({
          name: '审核通过',
          number: resp.data.checkSuccessCount,
          unit: '份'
        })
        list.push({
          name: '审核驳回',
          number: resp.data.checkFailCount,
          unit: '份'
        })
        this.overList = list
      }
    },
    getSearchParams () {
      return {
        ...this.search,
        formCode: this.formCode,
        page: this.pageBean.page,
        size: this.pageBean.size,
        createStartTime: this.createTime?.[0],
        createEndTime: this.createTime?.[1],
        updateStartTime: this.updateTime?.[0],
        updateEndTime: this.updateTime?.[1]
      }
    },
    async searchList () {
      this.loading = true
      const serch = this.getSearchParams()

      try {
        const resp = await apiMf.getParticipantList(serch)
        if (resp.success) {
          this.tableData = resp.data
          this.pageBean.total = resp.paging.total
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    exportConfirm (type) {
      switch (type) {
        case 'know':
          this.exportPdf(1)
          break
        case 'que':
          this.exportForm(1)
          break
      }
    },
    async exportPdf (f) {
      if (f) {
        this.fullscreenLoading1 = true
        const res = await apiMf.exportAgreement(this.getSearchParams())
        try {
          const resp = await downloadSubscribe(apiMf.getSurveyDataLink, {
            time: 3000,
            params: { downloadKey: res.data.downloadKey }
          })
          if (resp.data && resp.data.downloadUrl) {
            window.open(resp.data.downloadUrl)
          }
        } catch (e) {
        // console.log('object', e)
          this.$message.error(e || '下载失败')
        } finally {
          this.fullscreenLoading1 = false
        }
      } else {
        // 弹窗, 知情同意
        this.$refs.export.setData({ ctype: 'know', username: '' })
      }
    },
    async exportForm (f) {
      if (f) {
        this.fullscreenLoading2 = true
        const res = await apiMf.exportSurveyData(this.getSearchParams())
        try {
          const resp = await downloadSubscribe(apiMf.getSurveyDataLink, {
            time: 3000,
            params: { downloadKey: res.data.downloadKey }
          })
          if (resp.data && resp.data.downloadUrl) {
            window.open(resp.data.downloadUrl)
          }
        } catch (e) {
          console.log('object', e)
        // this.$message.error(e)
        } finally {
          this.fullscreenLoading2 = false
        }
      } else {
        // 弹窗、问卷数据
        this.$refs.export.setData({ ctype: 'que', username: '' })
      }
    },
    inputForm () {
      // 有时候代码没有生效, 不知道是不是这样配置项目(多个项目集成在一起)的问题
      this.$refs.detail.setData()
    },
    openDrawer (row, flag) {
      this.$refs.detail.setData({
        ...row,
        fromDetail: true,
        id: row.id, // 拿数据的
        slug: row.slug,
        pName: row.pname,
        status: row.status,
        entryType: row.entryType,
        remark: row.remark,
        idCard: row.idCard,
        cellphone: row.cellphone,
        isExpert: row.isExpert,
        role: this.formRole,
        formCode: row.status === 'ONGOING' ? this.formCode : row.formCode
      }, flag)
    },
    async getOrgs () {
      const resp = await apiMf.getOrgList()
      if (resp.success) {
        this.orgnizations = resp.data
      }
    },
    changePageSize (size, type) {
      this.pageBean.size = size
      this.pageBean.page = 1
      // if (type) {
      //   sessionStorage.setItem(`${type}_size`, size)
      //   sessionStorage.setItem(`${type}_page`, this.page)
      // }
      this.searchList()
    },
    changePage (page, type) {
      // if (type) {
      //   sessionStorage.setItem(`${type}_page`, page)
      // }
      this.pageBean.page = page
      this.searchList()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-detail {
  padding: 20px;
  background-color: #F7F8FA;
  .version-switch {
    // margin-bottom: 21px;
  }
  .data-overview {
    .overview-item {
      box-sizing: border-box;
      padding: 16px 14px;
      background: #f7f8fa;
      border: 1px solid #ffffff;
      border-radius: 4px;
      flex: 1;
      margin: 0 12px 12px 0;
      width: calc((100% - 36px) / 4);
      min-width: calc((100% - 36px) / 4);
      max-width: calc((100% - 36px) / 4);
      &:nth-child(4) {
        margin-right: 0;
      }

      &-title {
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 22px;
      }

      .number-unit {
        .number {
          // width: 51px;
          // height: 38px;
          font-size: 30px;
          font-family: D, D-Bold;
          font-weight: 700;
          text-align: left;
          color: rgba(0,0,0,0.85);
          line-height: 38px;
        }
        .unit {
          // width: 14px;
          // height: 22px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0,0,0,0.65);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
