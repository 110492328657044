var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu-item",
    { attrs: { index: _vm.menu.path, route: { path: _vm.menu.path } } },
    [
      _c("i", { class: _vm.menu.icon }),
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.menu.title)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }