<template>
  <div id="app">
    <div
      class="header"
    >
      <div class="header__left">
        <div class="cmv_img">
          <img src="https://cmvalue-web-cdn.oss-cn-hangzhou.aliyuncs.com/mini-repo/case-exchange/images/cmv-logo.png">
        </div>
        <div class="org_select">
          <el-select
            v-model="project"
            placeholder="请选择"
            @change="switchOrg"
          >
            <el-option
              v-for="item in projectList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </div>
      </div>
      <div class="header__right">
        <div class="user-info">
          <p class="name">
            {{ admin.username || '没有姓名' }}
          </p>
          <p
            class="name"
            @click="logout()"
          >
            退出
          </p>
          <!-- <div class="user-info_img">
              <img src="https://cmvalue-web-cdn.oss-cn-hangzhou.aliyuncs.com/mini-repo/case-exchange/images/cmv-logo.png">
            </div> -->
          <!-- <div class="user-info_text">
              <p>{{ admin.username }}</p>
              <span>{{ adminOrg.name }}</span>
            </div>
            <i class="el-icon-arrow-down" /> -->
        </div>
      </div>
    </div>
    <div
      class="main"
      :class="{'main-collapse': isCollapse}"
    >
      <div
        class="aside"
      >
        <menu-list
          :is-collapse="isCollapse"
          :menu-list="menuList"
        />
      </div>
      <div
        class="content-box"
      >
        <!-- :class="!windowIf?'':'content-box_none'" -->
        <div
          v-if="breadcrumb.length!==0"
          class="bcp-content-breadcrumb"
        >
          <el-breadcrumb>
            <el-breadcrumb-item
              v-for="(item, index) in breadcrumb"
              v-show="item.label!=='内容管理'"
              :key="index"
              :to="item.path"
              replace
            >
              {{ item.label }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="page-system">
          <keep-alive v-if="$route.meta.keepAlive">
            <router-view />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { mapState } from 'vuex'
import { getUrlParamValue } from '@cmvalue/dc-utils'
import { Base64 } from 'js-base64'
import apiAuth from '@/api/auth'
import authUrl from '@/config/auth'
import headerApi from '@/api/header'
import MenuList from '@/components/menu-list'

const isPrd = process.env.NODE_ENV === 'production'

/**
 * 生产和测试混起来,省的区分环境了; 最好还是前面是生产, 后面是测试
 */
// eslint-disable-next-line no-unused-vars
const OrgMenuMap = Object.freeze({
  'P51-P54': 'surveyd002', // 'MF-CTCL项目' P51-生产环境; P54-测试环境
  'P53-P55': 'surveyd003', // '皮肤真菌' P53-生产环境; P55-测试环境
  'P129-P88': 'surveyd005' // '调查列表' P129-生产环境; P88-测试环境
})

export default {
  components: {
    MenuList
  },
  data () {
    return {
      orgProMap: {},
      isCollapse: false,
      isShowPageBrief: false,
      menuMaps: {},
      pathProMap: {},
      routeBreadcrumb: [],
      menuList: [],
      showOrg: false,
      admin: {},
      adminOrg: {},
      projectList: [],
      project: '',
      orgbk: null,
      options: [],
      windowIf: false,
      routerList: [],
      diagnosis: window.localStorage.getItem('diagnosis')
    }
  },
  computed: {
    ...mapState({
      breadcrumb: state => state.route.breadcrumb || []
    })
  },
  watch: {
    // $route: {
    //   handler: 'getNewRoute',
    //   immediate: true
    // }
  },
  created () {
    this.init()
  },
  methods: {
    async getProjects () {
      const menuList = []
      const res = await apiAuth.getNavListAndPermission()
      try {
        if (res.success) {
          localStorage.setItem('per', JSON.stringify(res.data.actionCodes))
          const len = res.data.menuResources.length
          if (len) {
            // this.projectList =
            let gotWhite = false
            let basePath = ''
            let whiteObjList = []
            res.data.menuResources[0].children.map(it => {
              if (this.orgProMap[this.project] === it.code) {
                it.children.map(iii => {
                  menuList.push({
                    ...iii,
                    title: iii.name,
                    path: `${it.path}${iii.path}`
                  })
                })
                basePath = it.path
              } else if (it.code === 'surveyd004') { // 医生管理要加到边上
                gotWhite = true
                whiteObjList = it.children
              }
              return {
                ...it,
                title: it.name
                // path: `${this.projectList[0].path}${it.path}`
              }
            })
            if (gotWhite) {
              whiteObjList.forEach(wit => {
                menuList.push({
                  ...wit,
                  title: wit.name,
                  path: `${basePath || '/only'}${wit.path || '/white'}?t=${new Date().getTime()}`
                })
              })
            }
            this.menuList = menuList

            this.$router.replace({
              path: this.menuList[0].path
            })
          } else {
            this.$router.replace({
              path: '/404'
            })
          }
        }
      } catch (e) {
        console.error('dddd', e)
      }
    },
    init () {
      // TODO: 请求项目权限, 分配机构;菜单栏可以配置
      this.getOrgInfo()
      this.getUserInfo()
    },
    // 处理路由跟项目的映射, 页面手动刷新问题
    pathMapToProject () {
      let curPro = this.projectList[0].code // 默认第一个
      Object.keys(this.pathProMap).forEach(it => {
        if (new RegExp(it).test(location.pathname)) {
          curPro = this.pathProMap[it]
        }
      })
      // 需要确定的值 当前项目代码project
      this.project = curPro
      // this.switchOrg(curPro)
      // this.menuList = this.menuMaps[this.project]
    },
    switchOrg (v, auto) {
      // this.menuList = this.menuMaps[v] // 是一个数组, 取第一个的路径
      // this.$router.replace({
      //   path: this.menuList[0].path
      // })
      headerApi.switchOrg(v).then(res => {
        if (res.success) {
          if (res.data.refreshed) {
            localStorage.setItem('x-b-token', res.data.freshToken)
            // location.href = `//${location.host}/content-admin`
            this.getProjects()
          } else if (!auto) { // 刷新的请款下不用弹窗
            this.org = this.orgbk
            this.$message.error('切换机构失败')
          } else {
            this.getProjects()
          }
        } else {
          this.org = this.orgbk
          this.$message.error('切换机构失败')
        }
      })
    },
    getOrgInfo () {
      headerApi.getAvailableOrg().then(res => {
        if (res.success) {
          // this.projectList = res.data
          const projectList = []
          Object.keys(OrgMenuMap).forEach(orgCode => {
            const which = isPrd ? orgCode.split('-')[0] : orgCode.split('-')[1]
            res.data.forEach(orgIt => {
              if (orgIt.code === which) {
                projectList.push({ ...orgIt, rootCode: OrgMenuMap[orgCode] })
                this.orgProMap[which] = OrgMenuMap[orgCode]
              }
            })
            // process.env.NODE_ENV !== 'production'
          })
          this.projectList = projectList
          this.project = projectList[0].code
          this.switchOrg(this.project, true)
          // this.getProjects()
        }
      })
    },
    logout () {
      localStorage.removeItem('x-b-token')
      let targeturl = decodeURIComponent(window.location.href)
      const ut = getUrlParamValue('ut')
      if (ut) {
        targeturl = targeturl.replace(`ut=${ut}`, '')
      }
      const callbackUrl = Base64.encodeURI(targeturl)
      window.location.href = this.windowIf
        ? `${authUrl}/login?callbackWeb=${callbackUrl}`
        : `${authUrl}/login/login-mg?callbackWeb=${callbackUrl}&appCode=${authUrl === 'https://auth.cmvalue.com' ? 'APP22' : 'APP40'}`
    },
    getUserInfo () {
      apiAuth.getUserInfo().then(res => {
        if (res.success) {
          this.admin = res.data.adminOutDto
          this.$store.dispatch('app/setData', {
            admin: res.data.adminOutDto
          })
          this.adminOrg = res.data.organization
          this.org = res.data.organization.code
          this.$store.dispatch('setData', {
            orgCode: res.data.organization.code
          })
          this.orgbk = res.data.organization.code
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "./styles/main.scss";
@import "~@/styles/table.scss";
/* google、safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none !important;
}

/* 火狐 */
input[type="number"] {
  -moz-appearance: textfield;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f8fa;
}

.classNone .el-scrollbar:first-child {
  .el-checkbox__input {
    display: none;
  }
}

// 组织选择
.org_select {
  display: flex;

  &::before {
    display: inline-block;
    height: 22px;
    margin-top: 5px;
    margin-right: 24px;
    margin-left: 15px;
    border: 1px solid #ccc;
    content: "";
  }

  .el-input {
    // height: 28px;
    min-width: 110px;
    margin-top: -5px;
    overflow: hidden;
    // line-height: 28px;
    border-radius: 25px;
    opacity: 1;
  }

  .el-input input {
    width: 100%;
    border: 1px solid #d5d5d5;
    // height: 28px;
    border-radius: 25px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  }
}
// see https://www.w3cplus.com/mobile/vw-layout-in-vue.html
img {
  width: 100%;
  height: auto;
  vertical-align: top;
  content: normal !important;
}

[aspectratio] {
  position: relative;
}

[aspectratio]::before {
  display: block;
  width: 1px;
  height: 0;
  margin-left: -1px;
  content: "";
}

[aspectratio-content] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss" scoped>

.bcp-content-breadcrumb {
  padding-top: 10px;
  padding-left: 16px;
}
// 左边部分
.aside {
  position: fixed;
  top: 70px;
  left: 0;
  width: auto;
  width: 200px;
  height: calc(100vh - 55px);
  overflow-y: scroll;
  background: #545c63;
  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  z-index: 999;
  display: flex;
  width: 100vw;
  height: 70px;
  color: #333;
  background: #feffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;

    .cmv_img {
      img {
        width: 140px;
      }
    }

    .collapse-icon {
      margin-left: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    .cmvalue-icon {
      height: 22px;
      padding-left: 20px;
      margin-left: 10px;
      font-size: 16px;
      line-height: 22px;
      color: #333;
      border-left: 1px solid #ccc;
    }
  }

  &__right {
    margin-top: 18px;
    margin-right: 30px;

    .user-info {
      display: flex;

      .name {
        margin-right: 10px;
        line-height: 40px;
      }

      i {
        line-height: 35px;
        color: #999;
      }

      &_img {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border-radius: 50%;

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }

      &_text {
        margin-right: 40px;

        p {
          padding: 0;
          margin-bottom: 5px;
          font-size: 14px;
          color: #333;
        }

        span {
          font-size: 12px;
          color: #999;
        }
      }
    }

    .logout {
      line-height: 55px;
    }
  }
}
// 内容部分
.content-box {
  width: calc(100vw - 205px);
  // padding: 15px 10px;
  // padding-right: 30px;
  margin-top: 70px;
  margin-bottom: 20px;
  margin-left: 200px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.content-box_none {
  width: 100%;
  padding: 0 !important;
  padding-right: 0 !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
}
// 左上角的展开隐藏按钮效果
.main-collapse {
  .aside {
    width: 65px !important;
    transition: all 0.3s ease-in-out;
  }

  .content-box {
    width: calc(100vw - 65px) !important;
    margin-left: 65px;
    transition: all 0.3s ease-in-out;
  }
}

// 面包屑调整
.el-breadcrumb {
  margin-bottom: 15px;
}

.el-image-viewer__close {
  font-size: 100px;
  color: #fff;
}

.el-tabs__header {
  z-index: 1;
}
</style>
