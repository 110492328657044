<template>
  <!--  eslint-disable vue/no-v-html  -->
  <div class="upload-img-com">
    <div
      v-for="(item, index) in imgList"
      :key="item.id"
      class="img-item"
    >
      <!-- 需要加注释的图片 -->
      <el-image
        v-if="imgNote"
        :src="item.url"
        class="el-img-class"
        fit="cover"
        :disabled="readonlyDisabled"
        @click="setNote(index)"
      />
      <el-image
        v-else
        :src="item.url"
        class="el-img-class"
        fit="cover"
        :disabled="readonlyDisabled"
        :preview-src-list="previewList"
      />
      <i
        v-if="!readonlyDisabled"
        class="el-icon-error close-icon"
        @click="deleteImg(index)"
      />
    </div>
    <template v-if="isCrop">
      <el-upload
        v-show="showUpload"
        :limit="limit"
        action="/bsp/api/admin/attachment/image"
        :list-type="self ? 'text': 'picture-card'"
        :http-request="upload"
        name="attachment"
        accept="image/png,image/jpg,image/gif"
        :show-file-list="false"
        :auto-upload="false"
        :disabled="readonlyDisabled"
        :multiple="multiple"
        :on-change="changeUpload"
      >
        <i
          v-if="!self"
          class="el-icon-plus"
        />
        <slot v-else />
        <div
          slot="tip"
          class="upload-tip"
          v-html="tip"
        />
      </el-upload>
    </template>
    <template v-else>
      <el-upload
        v-show="showUpload"
        v-loading.lock="loading"
        :limit="limit"
        :disabled="disabled||readonlyDisabled"
        action="/bsp/api/admin/attachment/image"
        :list-type="self ? 'text': 'picture-card'"
        :http-request="upload"
        name="attachment"
        accept="image/png,image/jpg,image/gif"
        :show-file-list="false"
        :multiple="multiple"
      >
        <i
          v-if="!self"
          class="el-icon-plus"
        />
        <slot v-else />
        <div
          slot="tip"
          class="upload-tip"
          v-html="tip"
        />
      </el-upload>
    </template>
    <img-note
      ref="imgNote"
      :value="imgItem"
      @input="noteDone"
    />
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog
      title="图片剪裁"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      width="800px"
    >
      <div class="upload-dialog">
        <div class="cropper-content">
          <div
            class="cropper"
            style="text-align: center;"
          >
            <vueCropper
              ref="cropper"
              :img="option.img"
              :output-size="option.size"
              :output-type="option.outputType"
              :info="true"
              :full="option.full"
              :can-move="option.canMove"
              :can-move-box="option.canMoveBox"
              :original="option.original"
              :auto-crop="option.autoCrop"
              :fixed="option.fixed"
              :fixed-number="option.fixedNumber"
              :center-box="option.centerBox"
              :info-true="option.infoTrue"
              :fixed-box="option.fixedBox"
            />
          </div>
        </div>
        <div class="cropper-switch">
          <p>可按各个尺寸剪裁</p>
          <el-radio-group
            v-model="cropperSize"
            @change="sizeChange"
          >
            <el-radio
              class="cropper-radio"
              :label="1"
            >
              图文封面 (3:2)
            </el-radio>
            <el-radio
              class="cropper-radio"
              :label="2"
            >
              图文封面 (1:1)
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="finish"
        >
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import apiCommon from '@/api/common'
// import VueCropper from 'vue-cropper'
export default {
  components: {
    ImgNote: () => import('./components/img-note.vue')
    // VueCropper
  },
  inject: ['fatherUpload'], // 每个调用上传组件的都需要这个方法
  props: {
    limit: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 10240
    },
    private: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    self: {
      type: Boolean,
      default: false
    },
    count: {
      // 图片限制数量
      type: Number,
      default: 1000
    },
    tip: {
      type: String,
      default: ''
    },
    imgNote: {
      type: Boolean,
      default: false
    },
    // 默认使用图片剪裁功能
    isCrop: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonlyDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentIdx: null,
      imgItem: {},
      imgList: [],
      dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputType: 'png',
        outputSize: 0.8, // 裁剪生成图片的质量
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [3, 2], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMove: false, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      cropperSize: 1,
      picsList: [], // 页面显示的数组
      // 防止重复提交
      loading: false
    }
  },
  computed: {
    previewList () {
      return this.imgList.map(item => item.url)
    },
    showUpload () {
      // 不显示上传图标
      return this.count > this.imgList.length && !this.readonlyDisabled
    }
  },
  methods: {
    noteDone (item) {
      // 地址引用，先将值传
      this.imgList[this.currentIdx] = item
      this.currentIdx = null
    },
    setNote (idx) {
      this.currentIdx = idx
      this.imgItem = this.imgList[idx]
      this.$refs.imgNote.showLog(this.imgList[idx])
    },
    async upload (params) {
      const file = params.file
      const fileType = file.type
      const isImage = fileType.includes('image')
      const isOversize = file.size >= this.size * 1024
      if (!isImage) {
        this.$message.error('只能上传图片格式png、jpg、gif!')
        return
      }
      if (isOversize) {
        this.$message.error('图片大小超出限制')
        return
      }
      const form = new FormData()
      form.append('attachment', file)
      if (this.private) {
        form.append('public', 0)
      }
      try {
        this.loading = true
        const res = await this.fatherUpload(form)
        if (res.success) {
          const obj = {
            url: res.data.url,
            id: res.data.attachmentId
          }
          this.imgList.push(obj)
          this.handleEmit()
        } else {
          throw new Error('请求失败')
        }
        // .then(res => {
        //   this.loading = false
        // }).catch(e => {
        //   this.loading = false
        //   console.error('上传图片出错', e)
        // })
      } catch (e) {
        console.error('出错,是不是父组件没有提供方法', e)
      } finally {
        this.loading = false
      }
      // TODO: 如果未来设置了统一的接口, 可以补在下方作为缺省选项
    },
    // 将事件抛出去，必填项
    handleEmit () {
      this.$emit('done', this.imgList && this.imgList.length)
      this.$emit('change', this.imgList)
    },
    getImages () {
      return this.imgList
    },
    setImages (data) {
      this.imgList = [...data]
    },
    deleteImg (index) {
      if (this.readonlyDisabled) {
        return
      }
      this.imgList.splice(index, 1)
      this.handleEmit()
    },
    sizeChange (val) {
      switch (val) {
        case 1:
          this.option.fixedNumber = [3, 2]
          break
        case 2:
          this.option.fixedNumber = [1, 1]
          break
        default:
          break
      }
      setTimeout(() => {
        this.$refs.cropper.goAutoCrop()
      }, 200)
    },
    // 上传按钮   限制图片大小
    changeUpload (file, fileList) {
      const isOversize = file.size >= this.size * 1024
      if (isOversize) {
        this.$message.error('图片大小超出限制')
      }
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.option.img = file.url
        this.dialogVisible = true
      })
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish () {
      this.$refs.cropper.getCropBlob(data => {
        this.loading = true
        const form = new FormData()
        form.append('attachment', data)

        if (this.private) {
          form.append('public', 0)
        }
        try {
          this.fatherUpload(form).then(res => {
            if (res.success) {
              const obj = {
                url: res.data.url,
                id: res.data.attachmentId
              }
              this.imgList.push(obj)
              this.handleEmit()
            }
          }).catch(e => {
            this.loading = false
            console.error('上传图片接口出错', e)
          })
        } catch (e) {
          this.loading = false
          console.error('出错,是不是父组件没有提供方法', e)
        }
        // TODO: 如果未来设置了统一的接口, 可以补在下方作为缺省选项
        // apiCommon
        //   .uploadImg(form)
        //   .then(res => {
        //     if (res.success) {
        //       const obj = {
        //         url: res.data.url,
        //         id: res.data.attachmentId
        //       }
        //       this.imgList.push(obj)
        //       this.handleEmit()
        //       this.loading = false
        //       this.dialogVisible = false
        //     }
        //   })
        //   .catch(err => {
        //     console.log(err)
        //     this.loading = false
        //   })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-img-com {
  display: flex;
  flex-wrap: wrap;

  /deep/.el-loading-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    // .el-loading-spinner {
    //   transform: translate(-21px, -21px)
    //   // margin: auto;
    // }
  }

  .img-item {
    position: relative;
  }

  .close-icon {
    position: absolute;
    top: -7px;
    right: 1px;
    width: 22px;
    height: 22px;
    font-size: 22px;
    cursor: pointer;
    background: #fff;
    border-radius: 100%;
  }

  /deep/ .el-upload--picture-card {
    line-height: 80px;
    width: 80px;
    height: 80px;

    .el-icon-plus {
      vertical-align: middle;
    }
  }

}

.el-img-class {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.upload-tip {
  margin-top: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #666;
}
// 截图
.upload-dialog {
  display: flex;

  .cropper-content {
    flex: 4;
    margin-right: 20px;

    .cropper {
      width: 100%;
      height: 300px;
    }
  }

  .cropper-switch {
    flex: 1;
  }

  .cropper-radio {
    margin-top: 20px;
  }
}
</style>
