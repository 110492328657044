<template>
  <div
    class="page-header"
    :class="border ? 'border' : ''"
  >
    <div class="page-header__title">
      {{ title }}
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.border {
  border-bottom: 1px solid #ededed;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &__title {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
