<template>
  <div>
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      tooltip-effect="dark"
      style="width: 100%;"
      :header-cell-style="{background:'#F8F8F8',color:'#333'}"
    >
      <el-table-column
        prop="id"
        label="问卷ID"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.slug || '/' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="pname"
        label="参与人"
        align="center"
      />
      <el-table-column
        prop="cellphone"
        label="联系电话"
        align="center"
        width="130"
      >
        <template slot-scope="scope">
          {{ scope.row.showPhone ? scope.row.cellphone : scope.row.cellphone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') }} <i
            :class="{
              'eye-open-icon': scope.row.showPhone,
              'eye-close-icon': !scope.row.showPhone
            }"
            style="cursor: pointer;"
            @click="togglePhone(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="机构"
        align="center"
        prop="orgName"
      />
      <el-table-column
        prop="entryType"
        label="填写方式"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.entryType && (scope.row.entryType == 1 ? '患者填写' : '医生录入') }}
        </template>
      </el-table-column>
      <el-table-column
        label="问卷状态"
        align="center"
      >
        <template slot-scope="scope">
          <div
            class="status"
          >
            <div :class="`status__dot status__dot--${STATUS_ENUM[scope.row.status]}`" />
            <div class="status__txt">
              {{ STATUS_MAP[scope.row.status] }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdAt"
        label="创建时间"
        align="center"
        width="100px"
      />
      <!-- pageBean -->
      <el-table-column
        prop="updatedAt"
        label="更新时间"
        align="center"
        width="100px"
      />
      <el-table-column
        label="操作"
        align="center"
        fixed="right"
        width="120px"
      >
        <template slot-scope="scope">
          <!-- v-if="scope.row.canModify===1"
          v-per="'BSP2'" -->
          <!-- 进行中未提交的问卷--患者填写/不属于本医生的, 按钮不可用 -->
          <el-button
            type="text"
            :disabled="(scope.row.entryType == 1 || !scope.row.isBelong) && !scope.row.slug"
            @click="viewDetail(scope.row)"
          >
            详情
          </el-button>
          <el-button
            v-if="scope.row.status == 'ENTRY_CHECK_LIST'"
            v-per="'surveya015'"
            type="text"
            @click="viewDetail(scope.row, 1)"
          >
            录入检查信息
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { STATUS_MAP, STATUS_ENUM } from '../config/constant.js'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      STATUS_MAP,
      STATUS_ENUM
    }
  },
  methods: {
    viewDetail (row, flag) {
      this.$emit('view', row, flag)
    },
    togglePhone (row) {
      this.$set(row, 'showPhone', !row.showPhone)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-btn {
  color: #409eff;
  cursor: pointer;
}
.edit {
  color: #666;
}

.eye-open-icon {
  display: inline-block;
  vertical-align: text-bottom;
  width: 14px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  background: url("~@/assets/eye-open-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.eye-close-icon {
  display: inline-block;
  vertical-align: text-bottom;
  width: 14px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  background: url("~@/assets/eye-close-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
}

</style>
