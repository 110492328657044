var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pie-chart" }, [
    _c(
      "div",
      { staticClass: "pie-chart-item" },
      [
        _c("DataPieChart", {
          attrs: { title: "性别分布", options: _vm.SexPieData },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pie-chart-item" },
      [
        _c("DataPieChart", {
          attrs: { title: "年龄分布", options: _vm.AgePieData },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pie-chart-item" },
      [
        _c("DataPieChart", {
          attrs: { title: "问卷状态", options: _vm.StatusPieData },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }