var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stats_wrap" }, [
    _c("div", { staticClass: "stats_overview" }, [
      _c(
        "div",
        { staticClass: "stats_overview_title" },
        [
          _c("span", [_vm._v("机构")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "300px" },
              on: { change: _vm.switchCurSearchOrg },
              model: {
                value: _vm.curOrg,
                callback: function ($$v) {
                  _vm.curOrg = $$v
                },
                expression: "curOrg",
              },
            },
            _vm._l(_vm.orgList, function (item) {
              return _c("el-option", {
                key: item.code,
                attrs: { label: item.name, value: item.code },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "stat-total" },
      [
        _c(
          "div",
          {
            staticClass: "overview",
            on: {
              click: function () {
                return _vm.goToSurveyList()
              },
            },
          },
          [
            _c("div", [
              _c("span", { staticClass: "title" }, [_vm._v("累计收集问卷")]),
              _c("span", { staticClass: "sub-title" }, [
                _vm._v("本周新增 "),
                _c("span", { staticClass: "primary" }, [
                  _vm._v("+" + _vm._s(_vm.totalStat.weekTotal)),
                ]),
              ]),
            ]),
            _c("p", [
              _c("span", { staticClass: "primary-text" }, [
                _vm._v(_vm._s(_vm.totalStat.total)),
              ]),
              _c("span", [_vm._v("份")]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "overview",
            on: {
              click: function () {
                return _vm.goToSurveyList(1)
              },
            },
          },
          [
            _c("div", [
              _c("span", { staticClass: "title" }, [_vm._v("已完成")]),
              _c("span", { staticClass: "sub-title" }, [
                _vm._v("本周新增 "),
                _c("span", { staticClass: "primary" }, [
                  _vm._v("+" + _vm._s(_vm.totalStat.weekComplete)),
                ]),
              ]),
            ]),
            _c("p", [
              _c("span", { staticClass: "primary-text" }, [
                _vm._v(_vm._s(_vm.totalStat.complete)),
              ]),
              _c("span", [_vm._v("份")]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "overview",
            on: {
              click: function () {
                return _vm.goToSurveyList(0)
              },
            },
          },
          [
            _c("div", [
              _c("span", { staticClass: "title" }, [_vm._v("进行中")]),
              _c("span", { staticClass: "sub-title" }, [
                _vm._v("本周新增 "),
                _c("span", { staticClass: "primary" }, [
                  _vm._v("+" + _vm._s(_vm.totalStat.weekIncomplete)),
                ]),
              ]),
            ]),
            _c("p", [
              _c("span", { staticClass: "primary-text" }, [
                _vm._v(_vm._s(_vm.totalStat.incomplete)),
              ]),
              _c("span", [_vm._v("份")]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "overview", on: { click: _vm.filterStatDataList } },
          [
            _vm._m(0),
            _c("p", [_vm._v(" " + _vm._s(_vm.totalStat.averageTime) + " ")]),
          ]
        ),
        _c("stat-data", {
          ref: "statsTable",
          attrs: { "org-code": _vm.curOrg },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "blank" }),
    ["admin", "expert"].includes(_vm.curRole)
      ? _c(
          "div",
          { staticClass: "form-stat-detail" },
          [
            _c("div", { staticClass: "form-stat-detail-title" }, [
              _vm._v(" 答题情况统计： "),
            ]),
            _c("stat-report", { ref: "FormStatsReport" }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "title" }, [_vm._v("问卷平均完成时长")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }