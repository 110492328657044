<template>
  <div class="pie-chart">
    <div class="pie-chart-item">
      <DataPieChart
        title="性别分布"
        :options="SexPieData"
      />
    </div>
    <div class="pie-chart-item">
      <DataPieChart
        title="年龄分布"
        :options="AgePieData"
      />
    </div>
    <div class="pie-chart-item">
      <DataPieChart
        title="问卷状态"
        :options="StatusPieData"
      />
    </div>
  </div>
</template>

<script>
import DataPieChart from './data-pie-chart'
import { generateCircileChartOtions } from '../utils/charts.js'

export default {
  components: {
    DataPieChart
  },
  data () {
    return {
      SexPieData: {},
      AgePieData: {},
      StatusPieData: {}
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    generatePieOption () {
      generateCircileChartOtions({ text: '', data: '', name: '', centerTxt: '', left: '', type: '' })
    },
    setData ({ ageReturn, sexResult, statusReturn, allCount }) {
      // try {
      this.SexPieData = generateCircileChartOtions({
        text: '',
        name: '',
        centerTxt: `${allCount} 人`,
        left: '',
        type: '人',
        data: sexResult.map(it => ({ name: it.sexName, value: it.count, percent: it.percent }))
      })
      this.AgePieData = generateCircileChartOtions({
        text: '',
        name: '',
        centerTxt: `${allCount} 人`,
        left: '',
        type: '人',
        data: ageReturn.map(it => ({ name: `${it.ageRange} 岁`, value: it.count, percent: it.percent }))
      })
      this.StatusPieData = generateCircileChartOtions({
        text: '',
        name: '',
        centerTxt: `${allCount} 人`,
        left: '',
        type: '人',
        data: statusReturn.map(it => ({ name: it.statusName, value: it.count, percent: it.percent }))
      })
      // } catch (e) {
      //   console.error(e)
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
.pie-chart {
  display: flex;
  flex-wrap: wrap;
  &-item {
    flex: 1;
    margin: 0 12px 12px 0;
    width: calc((100% - 12px) / 2);
    min-width: calc((100% - 12px) / 2);
    max-width: calc((100% - 12px) / 2);
    &:nth-child(2) {
      margin-right: 0;
    }
  }
}
</style>
