// 状态
const STATUS_MAP = Object.freeze({
  ONGOING: '进行中',
  COMPLETED: '已完成',
  WAIT_UPLOAD: '待上传实验室检查'
})

const STATUS_ENUM = Object.freeze({
  ONGOING: 'lightblue',
  COMPLETED: 'blue',
  WAIT_UPLOAD: 'orange'
})

const AgeEnum = Object.freeze({
  1: '0-17岁',
  2: '18-29岁',
  3: '30-39岁',
  4: '40-49岁',
  5: '50-59岁',
  6: '60-70岁',
  7: '70岁以上'
})

module.exports = {
  STATUS_MAP,
  STATUS_ENUM,
  AgeEnum
}
