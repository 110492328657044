<template>
  <el-drawer
    title="我是标题"
    size="800px"
    :destroy-on-close="true"
    :close-on-press-escape="false"
    :visible.sync="drawer"
    :before-close="handleClose"
    custom-class="hello-motor"
    :wrapper-closable="false"
    :append-to-body="true"
  >
    <div
      slot="title"
      class="flex items-end"
    >
      <div>{{ fromDetail ? '问卷详情' : '录入问卷' }}</div>
      <div class="flex-1" />
      <div
        v-if="hasPermission"
        v-per="'surveya004'"
        class="flex items-center pr-7"
      >
        <span class="mr-2">编辑问卷内容 </span>
        <el-switch
          v-model="dyEdit"
          @change="editDyForm"
        />
      </div>
    </div>
    <!-- 这部分表单:250px -->
    <div class="drawer-body flex">
      <div class="nomal-form w-1/3">
        <BasicForm
          v-if="stepOne"
          :btn-invisible="theRestBtnHide"
          @success="postData"
        />
        <PlainForm
          v-else
          :form="form"
          :btn-invisible="theRestBtnHide"
          @post="postAudit"
          @audit="postAuditData"
        />
      </div>
      <div class="dy-form-outline">
        <div
          v-if="!stepOne"
          class="stady-center shadow-md"
          :class="{ fixedBottomBtn: theRestBtnHide }"
        >
          <MobileForm
            ref="mdy"
            :m-type="mType"
            :form-code="formCode"
            :form-slug="formSlug"
            @submited="submited"
          />
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import apiMf from '@/api/project/mf-ctcl.js'
import BasicForm from './basic-form'
import PlainForm from './plain-form-data'
import MobileForm from '../mobile-form/index'
import { getFormDraftNumber } from '@/utils/form'
import { isEmptyObject } from '@/utils/object'
import { STATUS_ENUM } from '../../config/constant.js'
import { setTimeout } from 'timers'

export default {
  components: {
    BasicForm,
    PlainForm,
    MobileForm
  },
  data () {
    return {
      drawer: false,
      dyEdit: false,
      stepOne: true,
      fromDetail: false,
      theRestBtnHide: false, // 其它按钮隐藏, 默认不隐藏
      formSlug: '',
      formCode: '',
      mType: '', // 编辑时需要确定是否为专员信息录入
      STATUS_ENUM,
      form: {
        id: '',
        slug: '', // 拿数据的
        role: '', // 确定角色
        pName: '',
        entryType: 2,
        idCard: '',
        status: '',
        isExpert: '', // 协和专家
        cellphone: '',
        sign: '',
        remark: ''
      }
    }
  },
  computed: {
    hasPermission () {
      if (this.fromDetail) {
        // 后台配置按钮权限之后还需要做这些处理
        /**
         * ONGOING: '进行中',
         * UNCONFIRMED: '待确认',
         * WAIT_ARRAIGNED: '待提审',
         * UNCHECKED: '待审核',
         * CHECK_SUCCESS: '审核通过',
         * CHECK_FAIL: '审核驳回'
        */
        // 超管拥有两种角色的所有权限
        if (this.form.role === 'admin') {
          return [STATUS_ENUM.UNCHECKED, STATUS_ENUM.WAIT_ARRAIGNED, STATUS_ENUM.CHECK_FAIL].includes(this.form.status)
        }
        if (!this.form.isExpert) { // 分中心负责人可以编辑的情况; 待提审、审核驳回
          return [STATUS_ENUM.WAIT_ARRAIGNED, STATUS_ENUM.CHECK_FAIL].includes(this.form.status)
        } else {
          // 协和人员可以编辑的情况; 表单-待审核
          return [STATUS_ENUM.UNCHECKED].includes(this.form.status)
        }
      }
      return false
    }
  },
  watch: {
    dyEdit (n) {
      if (n) { // 表单编辑状态, 要隐藏其它按钮
        this.theRestBtnHide = true
      } else {
        this.theRestBtnHide = false
      }
    }
  },
  methods: {
    submited () {
      // DONE: 保存完, 隐藏表单中的按钮;其它按钮再展示
      this.$message.success('提交成功')
      if (this.fromDetail) {
        this.stepOne = false
        this.dyEdit = false
        this.editDyForm()
      } else {
        // 纯新增toast提示
        this.$message({
          message: '数据同步中...',
          duration: 2000,
          onClose: () => {
            this.$emit('close') // 两秒后再查询一遍
          }
        })
        this.handleClose()
      }
      this.theRestBtnHide = false // 其它按钮要展示出来
    },
    handleClose () {
      this.drawer = false
      this.$emit('close')
    },
    async setData (data = {}, directly) {
      if (!isEmptyObject(data)) {
        this.fromDetail = !!data.fromDetail
        this.form = { ...data }
        this.formCode = data.formCode
        // eslint-disable-next-line eqeqeq
        this.dyEdit = false
        this.stepOne = !data.fromDetail
        this.getSign(data.id)
      } else {
        this.fromDetail = false
        this.formCode = this.$store.state.mdy.code
        this.form = {
          id: '',
          slug: '', // 拿数据的
          pName: '',
          entryType: 2,
          status: '',
          isExpert: false,
          idCard: '',
          cellphone: '',
          sign: '',
          remark: ''
        }
        this.stepOne = true
      }
      this.formSlug = data.slug
      await this.handleFormApi()
      if (directly) { // 直接编辑
        this.dyEdit = true
        this.mType = 'ENTRY_CHECK_LIST'
        setTimeout(this.editDyForm, 1000)
      } else {
        this.mType = ''
      }
      this.drawer = true
    },
    getSign (id) {
      if (id) {
        apiMf.getSignImg({ id }).then(res => {
          if (res.success) {
            this.form.sign = res.data.url
          }
        })
      }
    },
    editDyForm (v) {
      // TODO: 编辑的时候只显示表单底部的编辑按钮, 其它按钮不可见
      if (v) {
        // 可以编辑
      }
      this.$refs.mdy.triggerEdit()
    },
    // 控制按钮的展示隐藏
    toggleButtonVisible () {
      this.theRestBtnHide = !this.theRestBtnHide
    },
    async postAudit (data) { // 提交审核
      const resp = await apiMf.postAudit(data)
      if (resp.success) {
        this.handleClose()
      }
    },
    async postAuditData (data) { // 审核结果
      const resp = await apiMf.postAuditData(data)
      if (resp.success) {
        this.handleClose()
      }
    },
    async postData (form) {
      this.form = { ...this.form, ...form }
      await this.handleFormApi()
      this.stepOne = false
    },
    async handleFormApi () {
      const params = {
        orgCode: this.$store.state.mdy.orgCode,
        patientId: this.form.idCard
      }
      this.$store.dispatch('mdy/setData', {
        patientId: this.form.idCard
      })
      // 手动录入的时候, code值是不一样的
      params.code = this.formCode // this.$store.state.mdy.code
      params.slug = this.formSlug
      params.isDetail = !!this.formSlug
      const resp = await apiMf.preInspect(params)
      // 获取表单基本配置
      if (resp.success) {
        let data = resp.data
        const isFormContentExist = !!data.formContent
        this.$store.dispatch('mdy/setData', {
          formAction: data.formContent.formAction,
          formVersion: isFormContentExist ? data.formContent.version : '',
          formContent: data.formContent // 接口统一 获取表单详细配置
        })
        // this.storageFormVersionToLocal(data.formContent && data.formContent.version)
        // 合并是否有介绍页的配置
        data = { ...data, isIntroduction: this.$store.state.app.isIntroduction }
        // debugger
        if (isFormContentExist && data.formContent.resumeOnBreak) { // 查看表单的时候不要请求快照
          apiMf.fetchFormDraft({
            formCode: this.formCode, // this.$store.state.mdy.code,
            formVersion: data.formContent.version,
            patientId: this.form.idCard,
            orgCode: this.$store.state.mdy.orgCode
          }).then(draftResp => {
            if (draftResp.success) {
              const info = draftResp.data
              const hasDraftFormData = info.formData
                ? (!!Object.keys(JSON.parse(info.formData)).length && !!getFormDraftNumber(JSON.parse(info.formData))) // 因为有些表单项有默认值，所以不能单单判断数据的长度, 还需要根据数据的值
                : false
              if (hasDraftFormData) {
                this.$store.dispatch('mdy/setData', {
                  formDraft: JSON.parse(info.formData)
                })
                // this.pageRedirectProcess(data, info)
              } else {
                // this.pageRedirectProcess(data)
              }
            }
          })
        } else {
          // this.pageRedirectProcess(data)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.drawer-body {
  padding-left: 25px;
  .nomal-form {
    flex: 1;
    // position: relative;
    height: calc(100vh - 84px);
    overflow-y: auto;
  }
  .dy-form-outline {
    flex: 0 0 515px;
    width: 515px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 84px);

    .stady-center {
      width: 375px;
      height: 95%;
      overflow-y: auto;
      position: relative;
    }
  }
}
</style>

<style lang="scss">
.stady-center.fixedBottomBtn {
  .home {
    padding-bottom: 100px;

    .mobile-form-item.formFooter_item {
      position: fixed;
      width: 370px;
      height: 76px;
      bottom: 18px;
      right: 75px; // (514 - 375) / 2 + 滚动条宽度 可能还要兼容不同平台的滚动条
      background-color: #fff;
    }
  }
}
</style>
