<template>
  <div class="data-detail">
    <div
      slot="header"
    >
      <div
        class="flex mb-5 search-bar"
      >
        <div class="search-item flex">
          <span
            class="search-item__label"
            style="width: 60px;"
          >问卷ID</span>
          <el-input
            v-model="search.slug"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="searchList"
          />
        </div>
        <div class="search-item flex">
          <span
            class="search-item__label"
            style="width: 60px;"
          >调查员</span>
          <el-input
            v-model="search.doctorName"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="searchList"
          />
        </div>
        <div class="search-item">
          <span
            class="search-item__label"
          >机构</span>
          <el-select
            v-model="search.orgCode"
            size="small"
            clearable
          >
            <el-option
              v-for="(item, key) in orgnizations"
              :key="key"
              :label="item"
              :value="key"
            />
          </el-select>
        </div>
        <div class="search-item flex">
          <span
            class="search-item__label"
            style="width: 60px;"
          >患者</span>
          <el-input
            v-model="search.patientName"
            clearable
            size="small"
            placeholder="请输入"
            @keyup.enter.native="searchList"
          />
        </div>
        <div class="search-item">
          <span class="search-item__label">性别</span>
          <el-select
            v-model="search.sex"
            size="small"
            clearable
          >
            <el-option
              v-for="(item, key) in SexEnum"
              :key="key"
              :label="item"
              :value="key"
            />
          </el-select>
        </div>
      </div>
      <div
        class="flex search-bar"
      >
        <div class="search-item">
          <span class="search-item__label">问卷状态</span>
          <el-select
            v-model="search.status"
            size="small"
            style="width: 120px;"
            clearable
          >
            <el-option
              v-for="(item, key) in STATUS_MAP"
              :key="key"
              :label="item"
              :value="key"
            />
          </el-select>
        </div>
        <div class="search-item">
          <span class="search-item__label">创建时间</span>
          <el-date-picker
            v-model="createTime"
            type="daterange"
            align="right"
            size="small"
            unlink-panels
            clearable
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="PickerOptions"
            value-format="yyyy-MM-dd"
            @change="timePicker($event, 'createTime')"
          />
          <!-- @change="timePicker" -->
        </div>
        <div class="search-item">
          <span class="search-item__label">更新时间</span>
          <el-date-picker
            v-model="updateTime"
            type="daterange"
            align="right"
            size="small"
            unlink-panels
            clearable
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="PickerOptions"
            value-format="yyyy-MM-dd"
            @change="timePicker($event, 'updateTime')"
          />
          <!-- @change="timePicker" -->
        </div>
        <div class="flex flex-auto justify-end">
          <el-button
            size="small"
            @click="clearSearch"
          >
            重置
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="searchList"
          >
            查询
          </el-button>
        </div>
      </div>
    </div>
    <el-card class="mt-4">
      <div class="flex item-centers mb-4">
        <div class="flex items-center text-base">
          问卷列表
        </div>
        <div
          class="flex flex-auto justify-end"
          style="flex: 1;"
        >
          <el-button
            type="primary"
            size="small"
            @click="viewData()"
          >
            数据统计
          </el-button>
          <el-button
            v-per="'surveya002'"
            v-loading.fullscreen.lock="fullscreenLoading2"
            type="primary"
            size="small"
            @click="exportForm()"
          >
            导出问卷
          </el-button>
          <el-button
            v-loading.fullscreen.lock="fullscreenLoading3"
            v-per="'surveya009'"
            type="primary"
            size="small"
            @click="exportImg()"
          >
            下载图片
          </el-button>
          <el-button
            v-loading.fullscreen.lock="fullscreenLoading1"
            v-per="'surveya001'"
            type="primary"
            size="small"
            @click="exportPdf()"
          >
            下载知情同意书
          </el-button>
        </div>
      </div>
      <table-list
        :data="tableData"
        :loading="loading"
        :form-code="formCode"
        @view="openDrawer"
      />
      <div class="pagination">
        <el-pagination
          :current-page="pageBean.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageBean.size"
          layout="total, sizes, prev, pager, next"
          :total="pageBean.total"
          @size-change="changePageSize($event, 'answerList')"
          @current-change="changePage($event, 'answerList')"
        />
      </div>
    </el-card>
    <DetailDrawer
      ref="detail"
      @close="changeVersion"
    />
    <!-- 导出数据确认框 -->
    <ExportDataDialog
      ref="export"
      @confirm="exportConfirm"
    />
    <AfterDownloadDialog
      ref="after"
    />
  </div>
</template>

<script>
import apiPfjq from '@/api/project/pys-pfjq.js'
import apiCommon from '@/api/common.js'
import TableList from './components/table-list'
import DetailDrawer from './components/detail-drawer/index'
import ExportDataDialog from '@/components/export-data-confirm-dialog/index'
import AfterDownloadDialog from '@/components/after-download-dialog/index'

import { PickerOptions, SexEnum } from '@/config/constant.js'
import { downloadSubscribe } from '@/utils/download.js'

import { STATUS_MAP } from './config/constant.js'

export default {
  name: 'Home',
  components: {
    TableList,
    DetailDrawer,
    ExportDataDialog,
    AfterDownloadDialog
  },
  data () {
    return {
      fullscreenLoading1: false,
      fullscreenLoading2: false,
      fullscreenLoading3: false,
      orgnizations: {},
      formCode: '',
      STATUS_MAP,
      SexEnum,
      tableData: [],
      loading: false,
      EntryTypes: Object.freeze({
        1: '患者填写',
        2: '医生录入'
      }),
      versionList: [],
      search: {
        slug: '',
        doctorName: '',
        patientName: '',
        orgCode: undefined,
        sex: undefined,
        status: undefined // 进行中：ONGOING，待上传：WAIT_UPLOAD，已完成：COMPLETED
        // formVersion: '',
        // pname: undefined,
        // entryType: undefined,
      },
      createTime: [],
      updateTime: [],
      overList: [],
      PickerOptions,
      pageBean: {
        page: 1,
        size: 20,
        total: 0
      }
    }
  },
  async created () {
    this.changeVersion()
    this.getOrgs()
  },
  methods: {
    getFormInfo () {
      apiPfjq.getFormInfo().then(resp => {
        if (resp.success) {
          this.formCode = resp.data.formCode
          this.$store.dispatch('mdy/setData', {
            code: this.formCode,
            orgCode: resp.data.orgCode
          })
          this.getFormVersion()
        }
      })
    },
    getFormVersion () {
      apiCommon.getFormVersions({ formCode: this.formCode }).then(resp => {
        if (resp.success) {
          const len = resp.data.length
          this.versionList = resp.data.map((item, idx) => {
            if (idx === 0) {
              this.search.formVersion = item.version
            }
            return {
              value: item.version,
              label: `版本 ${len - idx} 创建于 ${item.createTime}`
            }
          })
          this.changeVersion()
        }
      })
    },
    timePicker (v, prop) {
      console.log('timePicker', v, prop)
      this[prop] = v || []
    },
    clearSearch () {
      this.search.slug = ''
      this.search.doctorName = ''
      this.search.patientName = ''
      this.search.orgCode = undefined
      this.search.sex = undefined
      this.search.status = undefined
      this.createTime = []
      this.updateTime = []
      this.page = 1
      this.searchList()
    },
    changeVersion (v) {
      this.searchList()
    },
    getSearchParams () {
      return {
        ...this.search,
        // formCode: this.formCode,
        page: this.pageBean.page,
        size: this.pageBean.size,
        createStartTime: this.createTime?.[0],
        createEndTime: this.createTime?.[1],
        updateStartTime: this.updateTime?.[0],
        updateEndTime: this.updateTime?.[1]
      }
    },
    async searchList () {
      this.loading = true
      const serch = this.getSearchParams()

      try {
        const resp = await apiPfjq.getParticipantList(serch)
        if (resp.success) {
          this.tableData = resp.data
          this.pageBean.total = resp.paging.total
        }
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    exportConfirm (type) {
      switch (type) {
        case 'know':
          this.exportPdf(1)
          break
        case 'que':
          this.exportForm(1)
          break
        case 'img':
          this.exportImg(1)
          break
      }
    },
    async exportPdf (f) {
      if (f) {
        this.fullscreenLoading1 = true
        const res = await apiPfjq.exportAgreement(this.getSearchParams())
        try {
          const resp = await downloadSubscribe(apiPfjq.getSurveyDataLink, {
            time: 3000,
            params: { downloadKey: res.data.downloadKey }
          })
          if (resp.data && resp.data.downloadUrl) {
            window.open(resp.data.downloadUrl)
          }
        } catch (e) {
        // console.log('object', e)
          this.$message.error(e || '下载失败')
        } finally {
          this.fullscreenLoading1 = false
        }
      } else {
        // 弹窗, 知情同意
        this.$refs.export.setData({ ctype: 'know', username: '' })
      }
    },
    async exportImg (f) {
      if (f) {
        this.fullscreenLoading3 = true
        const res = await apiPfjq.exportImgs(this.getSearchParams())
        try {
          const resp = await downloadSubscribe(apiPfjq.getSurveyDataLink, {
            time: 3000,
            params: { downloadKey: res.data.downloadKey }
          })
          if (resp.data && resp.data.downloadUrl) {
            window.open(resp.data.downloadUrl)
          }
        } catch (e) {
          console.log('object', e)
        // this.$message.error(e)
        } finally {
          this.fullscreenLoading3 = false
        }
      } else {
        // 弹窗、问卷数据
        this.$refs.export.setData({ ctype: 'img', username: '' })
      }
    },
    async exportForm (f) {
      if (f) {
        this.fullscreenLoading2 = true
        const res = await apiPfjq.exportSurveyData(this.getSearchParams())
        try {
          const resp = await downloadSubscribe(apiPfjq.getSurveyDataLink, {
            time: 3000,
            params: { downloadKey: res.data.downloadKey }
          })
          if (resp.data && resp.data.downloadUrl) {
            window.open(resp.data.downloadUrl)
          }
        } catch (e) {
          console.log('object', e)
        // this.$message.error(e)
        } finally {
          this.fullscreenLoading2 = false
        }
      } else {
        // 弹窗、问卷数据
        this.$refs.export.setData({ ctype: 'que', username: '' })
      }
    },
    viewData () {
      // 查看数据
      this.$router.push({
        path: '/pys-pfjq/data'
      })
    },
    openDrawer (row) {
      this.$refs.detail.setData(row)
    },
    async getOrgs () {
      const resp = await apiPfjq.getOrgList()
      if (resp.success) {
        this.orgnizations = resp.data
      }
    },
    changePageSize (size, type) {
      this.pageBean.size = size
      this.pageBean.page = 1
      // if (type) {
      //   sessionStorage.setItem(`${type}_size`, size)
      //   sessionStorage.setItem(`${type}_page`, this.page)
      // }
      this.searchList()
    },
    changePage (page, type) {
      // if (type) {
      //   sessionStorage.setItem(`${type}_page`, page)
      // }
      this.pageBean.page = page
      this.searchList()
    }
  }
}
</script>

<style lang="scss" scoped>
.data-detail {
  padding: 20px;
  background-color: #F7F8FA;
  .version-switch {
    // margin-bottom: 21px;
  }
  .data-overview {
    .overview-item {
      box-sizing: border-box;
      padding: 16px 14px;
      background: #f7f8fa;
      border: 1px solid #ffffff;
      border-radius: 4px;
      flex: 1;
      margin: 0 12px 12px 0;
      width: calc((100% - 36px) / 4);
      min-width: calc((100% - 36px) / 4);
      max-width: calc((100% - 36px) / 4);
      &:nth-child(4) {
        margin-right: 0;
      }

      &-title {
        width: 56px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 22px;
      }

      .number-unit {
        .number {
          // width: 51px;
          // height: 38px;
          font-size: 30px;
          font-family: D, D-Bold;
          font-weight: 700;
          text-align: left;
          color: rgba(0,0,0,0.85);
          line-height: 38px;
        }
        .unit {
          // width: 14px;
          // height: 22px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0,0,0,0.65);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
