<template>
  <div class="flex flex-col h-full">
    <div class="plain-form flex-1">
      <div class="plain-item">
        <div class="plain-item-k">
          问卷ID：
        </div>
        <div class="plain-item-v">
          {{ form.slug }}
        </div>
      </div>
      <div class="plain-item">
        <div class="plain-item-k">
          参与人：
        </div>
        <div class="plain-item-v">
          {{ form.pName }}
        </div>
      </div>
      <div class="plain-item">
        <div class="plain-item-k">
          身份证号：
        </div>
        <div class="plain-item-v">
          {{ form.idCard }}
        </div>
      </div>
      <!-- <div style="height: 30vh;" /> -->
      <div class="plain-item">
        <div class="plain-item-k">
          联系电话：
        </div>
        <div class="plain-item-v">
          {{ form.cellphone }}
        </div>
      </div>
      <div class="plain-item">
        <div class="plain-item-k">
          填写方式：
        </div>
        <div class="plain-item-v">
          {{ form.entryType == 1 ? '患者填写' : '医生录入' }}
        </div>
      </div>
      <div
        v-if="form.status"
        class="plain-item"
      >
        <div class="plain-item-k">
          问卷状态：
        </div>
        <div
          class="plain-item-v"
        >
          <!-- class="status-box" -->
          <span
            :class="form.status | cFilter"
          >{{ STATUS_MAP[form.status] || '状态错误' }}<span /></span>
        </div>
      </div>

      <div
        v-if="form.status === STATUS_ENUM.CHECK_FAIL"
        class="plain-item"
      >
        <div class="plain-item-k">
          驳回原因：
        </div>
        <div class="plain-item-v">
          {{ form.remark }}
        </div>
      </div>
      <!-- remark -->
      <div>
        <el-image
          v-if="form.sign"
          style="width: 100px; height: 100px"
          :src="form.sign"
          :preview-src-list="[form.sign]"
        />
        <div class="state-text">
          知情同意书图片
        </div>
      </div>
      <!-- 待提审、待审核、审核通过、审核驳回 会有审核记录-->
      <template v-if="showLog">
        <div
          class="plain-item-k"
          style="margin: 16px 0 4px;"
        >
          审核记录
        </div>
        <LogBox :log-id="form.id" />
      </template>
      <!-- 待提审 需要有备注说明 -->
      <template
        v-if="form.status === STATUS_ENUM.WAIT_ARRAIGNED"
      >
        <div
          class="plain-item-k"
          style="margin: 16px 0 12px;"
        >
          备注说明：
        </div>
        <div>
          <el-input
            v-model="postAuditRemark"
            type="textarea"
            style="width: 100%;"
            :rows="5"
            placeholder="请输入备注说明"
          />
        </div>
      </template>
    </div>

    <div
      v-if="!btnInvisible"
      class="sticky-bottom"
    >
      <el-button
        v-if="form.status === STATUS_ENUM.WAIT_ARRAIGNED"
        v-per="'surveya008'"
        type="primary"
        size="small"
        @click="postAudit"
      >
        <!-- @click="onSubmit" -->
        提交审核
      </el-button>
      <el-popconfirm
        v-if="form.status === STATUS_ENUM.UNCHECKED"
        v-per="'surveya007'"
        title="确认审核通过吗？通过后不可修改！"
        @confirm="auditFun(1)"
      >
        <el-button
          slot="reference"
          type="primary"
          size="small"
        >
          <!-- @click="onSubmit" -->
          审核通过
        </el-button>
      </el-popconfirm>
      <el-button
        v-if="form.status === STATUS_ENUM.UNCHECKED"
        v-per="'surveya007'"
        type="danger"
        size="small"
        style="margin-left: 8px;"
        @click="dialogVisible = true"
      >
        <!-- @click="onSubmit" -->
        审核驳回
      </el-button>
    </div>
    <el-dialog
      title="填写审核驳回原因"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-input
        v-model="textarea"
        type="textarea"
        style="width: 100%;"
        :rows="5"
        placeholder="请输入内容"
      />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          :disabled="!textarea"
          @click="auditFun(0)"
        >
          提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { STATUS_MAP, STATUS_ENUM } from '../../config/constant.js'
import LogBox from './log-box'

export default {
  components: {
    LogBox
  },
  filters: {
    cFilter (s) {
      let string = `status-box--${s} `
      switch (s) {
        case STATUS_ENUM.CHECK_FAIL:
          string += ' el-icon-error status-box'
          break
        case STATUS_ENUM.CHECK_SUCCESS:
          string += ' el-icon-success status-box'
          break
      }
      return string
    }
  },
  props: {
    form: {
      type: Object,
      default () {
        return {}
      }
    },
    btnInvisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      STATUS_MAP,
      STATUS_ENUM,
      textarea: '',
      postAuditRemark: '',
      dialogVisible: false
    }
  },
  computed: {
    showLog ({ form = {} }) {
      // 待提审、待审核、审核通过、审核驳回 会有审核记录
      // eslint-disable-next-line no-unused-vars
      const { status } = form
      return [
        STATUS_ENUM.WAIT_ARRAIGNED,
        STATUS_ENUM.UNCHECKED,
        STATUS_ENUM.CHECK_SUCCESS,
        STATUS_ENUM.CHECK_FAIL
      ].includes(status)
    }
  },
  methods: {
    reject () {},
    postAudit () {
      this.$emit('post', {
        id: this.form.id,
        remark: this.postAuditRemark
      })
    },
    auditFun (flag) {
      const params = {
        id: this.form.id,
        status: flag,
        remark: flag ? '' : this.textarea
      }
      this.$emit('audit', params)
    }
  }
}
</script>
<style lang="scss" scoped>
.plain-form {
  overflow-y: auto;
  padding-bottom: 60px;
  .plain-item {
    display: flex;
    margin-bottom: 16px;
    &-k {
      flex: 0 0 70px;
      width: 70px;
      height: 14px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 22px;
    }
    &-v {
      flex: 1;
      word-break: break-all;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 22px;
    }
  }
  .state-text {
    width: 100px;
    text-align: center;
    height: 14px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
  }
}

.status-box {
    display: inline-block;
    padding: 7px 12px;
    // width: 113px;
    // height: 32px;
    border-radius: 4px;
    text-align: center;
    // line-height: 32px !important;

    // ONGOING: '进行中',
    // UNCONFIRMED: '待确认',
    // WAIT_ARRAIGNED: '待提审',
    // UNCHECKED: '待审核',
    // CHECK_SUCCESS: '审核通过',
    // CHECK_FAIL: '审核失败'

    &--ONGOING {
      // background-color: #1EDAFA;
    }

    &--WAIT_ARRAIGNED {
      // background-color: #FAA21E;
    }

    &--UNCHECKED {
      // background-color: #1067EE;
    }

    &--CHECK_SUCCESS {
      background-color: #EFF8EB;
      // background: rgba(245,108,108,0.10);
      border: 0.5px solid #67C23A;
      color: #67C23A!important;
    }

    &--CHECK_FAIL {
      background-color: #FEF0F0;
      // background: rgba(245,108,108,0.10);
      border: 0.5px solid #f56c6c;
      color: #F56C6C!important;
    }

    &--UNCONFIRMED {
      // background: #E278FF;
    }
}

.sticky-bottom {
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 255px;
}
</style>
