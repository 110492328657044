<template>
  <div
    class="mf-ctcl log-dialog-body"
    @scroll="scroll"
  >
    <el-steps
      v-if="lists.length"
      direction="vertical"
    >
      <el-step
        v-for="it in lists"
        :key="it.id"
      >
        <div slot="title">
          {{ it.operatorName }} {{ ActionTypeMap[it.operateType] }}
        </div>
        <div slot="description">
          <p v-if="it.remark">
            备注: {{ it.remark }}
          </p>
          <span style="color: #999;">{{ it.createdAt }}</span>
        </div>
      </el-step>
    </el-steps>
    <el-empty
      v-else
      :image-size="100"
      description="暂无记录"
      style="padding-right: 30px;"
    />
  </div>
</template>

<script>
import apiMf from '@/api/project/mf-ctcl.js'
import { setTimeout } from 'timers'

export default {
  name: 'LogBox',
  props: {
    logId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      ActionTypeMap: Object.freeze({
        check_submit: '提交审核',
        check_pass: '审核通过',
        check_fail: '审核驳回'
      }),
      lists: []
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    setTimeout(this.getLogs, 1e3)
  },
  methods: {
    scroll () {},
    async getLogs () {
      try {
        const resp = await apiMf.getOptLog({
          id: this.logId,
          page: 1,
          size: 100 // 审核有100条以上的可能性很低, 不做分页
        })
        if (resp.success) {
          this.lists = resp.data
        }
      } catch (e) {}
    }
  }
}
</script>
<style lang="scss">
.mf-ctcl.log-dialog-body {
  max-height: 245px;
  overflow-y: auto;
  // border: 0.1px solid #ccc;

  .el-steps.el-steps--vertical {
    padding-top: 10px;
  }

  .el-step.is-vertical {

    .el-step__head {
      width: 14px;
    }

    .el-step__main {
      padding-bottom: 14px;
      .el-step__title.is-wait {
        // width: 220px;
        // height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 20px;
        padding-bottom: 4px;
      }

      .el-step__description.is-wait {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 20px;
      }
    }

    .el-step__line {
      width: 1px;
      top: 8px;
      bottom: 0;
      left: 4px;
    }
    .el-step__icon.is-text {
      width: 8px;
      height: 8px;
      border: 2px solid #2080E3;
      .el-step__icon-inner {
        line-height: 10px;
        color: transparent;
      }
    }
  }
}
</style>
