// 状态
const STATUS_MAP = Object.freeze({
  0: '进行中', // 0
  1: '已完成' // 1
})

const STATUS_ENUM = Object.freeze({
  0: 'lightblue',
  1: 'blue'
})

const AgeEnum = Object.freeze({
  1: '0-17岁',
  2: '18-29岁',
  3: '30-39岁',
  4: '40-49岁',
  5: '50-59岁',
  6: '60-70岁',
  7: '70岁以上'
})

module.exports = {
  STATUS_MAP,
  STATUS_ENUM,
  AgeEnum
}
