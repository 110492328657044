var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header", class: _vm.border ? "border" : "" },
    [
      _c("div", { staticClass: "page-header__title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c("div", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }