<template>
  <div class="flex flex-col h-full">
    <el-form
      ref="bForm"
      :model="form"
      :rules="rules"
      class="flex-1"
    >
      <el-form-item
        label="参与人姓名"
        prop="pName"
      >
        <el-input
          v-model="form.pName"
          placeholder="请输入参与人姓名"
        />
      </el-form-item>
      <el-form-item
        label="身份证号"
        prop="idCard"
      >
        <el-input
          v-model="form.idCard"
          placeholder="请输入身份证号"
          :maxlength="18"
        />
      </el-form-item>
      <el-form-item
        label="联系方式"
        prop="cellphone"
      >
        <el-input
          v-model="form.cellphone"
          placeholder="请输入联系方式"
        />
      </el-form-item>
      <el-form-item
        label="知情同意书"
        prop="sign"
      />
      <Uploader
        ref="upload"
        :count="1"
        @change="imgChange"
      />
    </el-form>
    <div
      v-if="!btnInvisible"
      class="sticky-bottom"
    >
      <el-popconfirm
        title="确认保存并下一步吗?保存后不可修改!"
        @confirm="onSubmit('bForm')"
      >
        <el-button
          slot="reference"
          type="primary"
          size="small"
        >
          <!-- @click="onSubmit" -->
          保存并下一步
        </el-button>
      </el-popconfirm>
    </div>
  </div>
  <!-- </ButtonBot> -->
</template>

<script>
import Uploader from '@/components/upload-img/index.vue'
import apiMf from '@/api/project/mf-ctcl.js'

export default {
  components: {
    Uploader
  },
  props: {
    btnInvisible: {
      type: Boolean,
      default: false
    }
  },
  provide () {
    return {
      /**
       * 由于后端区分项目建表,图片上传接口无法统一;
       * 新项目都需要更新接口
       * TODO: 这种方式有缺陷: 一个页面中有不同的图片上传接口会出现问题, 要改
      */
      fatherUpload: apiMf.uploadImg
    }
  },
  data () {
    const required = { required: true, message: '必填项', trigger: ['blur', 'change'] }

    return {
      form: {
        pName: '',
        idCard: '',
        cellphone: '',
        sign: '',
        fileId: ''
      },
      rules: {
        pName: [required],
        idCard: [required],
        cellphone: [required],
        sign: [required]
      }
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    uploadImg (form) {
      // 上传格式是表单, 跟后端商量好
      console.log('伏组件的方法接受', form)
      return apiMf.uploadImg(form)
    },
    imgChange (imgList) {
      if (imgList?.length) {
        this.form.fileId = imgList[0].id
        this.form.sign = imgList[0].url
      } else {
        this.form.fileId = ''
        this.form.sign = ''
      }
      this.$refs.bForm.validateField('sign')
    },
    onSubmit (form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          const data = this.form
          apiMf.addParticipant(data).then(res => {
            this.$emit('success', data)
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.sticky-bottom {
  display: flex;
  align-items: center;
  height: 60px;
}
</style>
