var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col h-full" },
    [
      _c(
        "el-form",
        {
          ref: "bForm",
          staticClass: "flex-1",
          attrs: { model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "参与人姓名", prop: "pName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入参与人姓名" },
                model: {
                  value: _vm.form.pName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pName", $$v)
                  },
                  expression: "form.pName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证号", prop: "idCard" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入身份证号", maxlength: 18 },
                model: {
                  value: _vm.form.idCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "idCard", $$v)
                  },
                  expression: "form.idCard",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系方式", prop: "cellphone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入联系方式" },
                model: {
                  value: _vm.form.cellphone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cellphone", $$v)
                  },
                  expression: "form.cellphone",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "知情同意书", prop: "sign" } }),
          _c("Uploader", {
            ref: "upload",
            attrs: { count: 1 },
            on: { change: _vm.imgChange },
          }),
        ],
        1
      ),
      !_vm.btnInvisible
        ? _c(
            "div",
            { staticClass: "sticky-bottom" },
            [
              _c(
                "el-popconfirm",
                {
                  attrs: { title: "确认保存并下一步吗?保存后不可修改!" },
                  on: {
                    confirm: function ($event) {
                      return _vm.onSubmit("bForm")
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        type: "primary",
                        size: "small",
                      },
                      slot: "reference",
                    },
                    [_vm._v(" 保存并下一步 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }