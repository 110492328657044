<template>
  <div>您访问的页面不存在</div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  }
}
</script>
<style lang="scss" scoped>

</style>
