import ajax from '@/libs/ajax'

const apiCommon = {
  uploadImg: data => ajax.request({
    data,
    method: 'post',
    url: '/bsp/api/admin/attachment/image',
    timeout: 50000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }),
  uploadImgNote: data => ajax.request({
    data,
    method: 'post',
    url: '/bsp/api/admin/attachment/image/description'
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
  }),
  removeImg: (attachmentId, deleteFile = false) => ajax.request({
    url: '/bsp/api/admin/attachment/image/remove',
    method: 'post',
    data: {
      attachmentId,
      deleteFile
    }
  }),
  getTagList: params => ajax.request({
    url: '/bsp/api/admin/content/common/tags',
    params
  }),
  getOrgTree: () => ajax.request({
    url: '/bsp/api/admin/project/org/list'
  }),
  getContentAuditHistory: params => ajax.request({
    url: '/bsp/api/admin/content/common/history',
    params
  }),
  // 获取表单历史版本
  getFormVersions: params => ajax.request({
    url: '/bsp/api/admin/questionnaire/data/version/list',
    method: 'get',
    params
  }),
  /*
  * 白名单列表相关接口
  */
  getWhiteList: params => ajax.request({
    url: '/questionnaire/api/admin/doctor/list',
    method: 'get',
    params
  }),
  createWhiteItem: data => ajax.request({
    url: '/questionnaire/api/admin/doctor/create',
    method: 'post',
    data
  }),
  modifyWhiteItem: data => ajax.request({
    url: '/questionnaire/api/admin/doctor/modify',
    method: 'post',
    data
  }),
  deleteWhiteItem: data => ajax.request({
    url: '/questionnaire/api/admin/doctor/delete',
    method: 'delete',
    data
  }),
  uploadWhiteLst: data => ajax.request({
    url: '/questionnaire/api/admin/doctor/import',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  }),
  getUploadUserLst: params => ajax.request({
    url: '/questionnaire/api/admin/doctor/import/check',
    method: 'get',
    params
  }),
  // 获取角色列表
  getRoleList: params => ajax.request({
    url: '/questionnaire/api/admin/doctor/role/list',
    method: 'get',
    params
  }),
  // 获取调查列表
  getSurveyList: params => ajax.request({
    url: '/questionnaire/api/admin/survey/list',
    method: 'get',
    params
  }),
  // 获取调查统计数据
  getSurveyCount: (params) => ajax.request({
    url: '/questionnaire/api/admin/survey/list/count',
    method: 'get',
    params
  }),

  getStatMetas: params => ajax.request({
    url: '/bsp/api/admin/questionnaire/data/metas',
    method: 'get',
    params
  }),
  getStatDataDatail: params => ajax.request({
    url: '/bsp/api/admin/questionnaire/data/detail',
    method: 'get',
    params
  }),
  exportSurveyData: params => ajax.request({
    url: '/questionnaire/api/admin/survey/export-data',
    method: 'get',
    params
  }),
  // 获取地址
  getSurveyDataLink: params => {
    return ajax.request({
      url: '/questionnaire/api/admin/survey/export-check',
      method: 'get',
      params
    }, {
      isShowErrorMessage: false,
      isSkipAllResponseTransform: true
    })
  },
  // 获取机构分布
  getOrgDistribution: params => ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/org-distributed',
    method: 'get',
    params
  }),
  // 获取用户分布

  getUserDistribution: params => ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/user-distributed',
    method: 'get',
    params
  }),

  // 获取问卷状态分布
  getSurveyDistribution: params => ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/status-distributed',
    method: 'get',
    params
  }),

  // 获取问卷统计信息
  getSurveyAnalysis: params => ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/report',
    method: 'get',
    params
  }),
  // 获取问卷统计信息
  getSurveyTotalStat: params => ajax.request({
    url: '/questionnaire/api/admin/survey/analysis/total',
    method: 'get',
    params
  }),
  // 获取机构列表
  getOrgList: _ => ajax.request({
    url: '/questionnaire/api/admin/survey/organization/list',
    method: 'get'
  }),
  // 获取用户权限
  getUserRole: _ => ajax.request({
    url: '/questionnaire/api/admin/doctor/role/current',
    method: 'get'
  })

}

export default apiCommon
