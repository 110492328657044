import Home from '@/views/pys-pfjq/index.vue'
// import About from '@/views/mf-ctcl/about'
import Data from '@/views/pys-pfjq/data'

export default [
  {
    path: '/pys-pfjq',
    name: 'PFZJHome',
    component: Home,
    async beforeEnter (to, from, next) {
      document.title = '问卷列表'
      next()
    }
  },
  {
    path: '/pys-pfjq/data',
    name: 'PFZJData',
    component: Data,
    async beforeEnter (to, from, next) {
      document.title = '数据详情'
      next()
    }
  }
]
