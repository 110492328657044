var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "charts-data" },
    [
      _c("span", { staticClass: "charts-data_nav" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      JSON.stringify(_vm.options) !== "{}"
        ? _c("v-chart", {
            staticStyle: { height: "203px" },
            attrs: { autoresize: "", option: _vm.options },
          })
        : _c("div", { staticClass: "charts-data_none" }, [
            _c("div", [_vm._v("0")]),
            _c("span", [_vm._v("暂无数据")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }