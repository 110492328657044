import ajax from '@/libs/ajax'

const apiMf = {
  // 获取数据概览
  getDataOverview: params => ajax.request({
    url: '/mf-ctcl/api/admin/overview',
    method: 'get',
    params
  }),
  // 获取患者列表
  getParticipantList: params => ajax.request({
    url: '/mf-ctcl/api/admin/list',
    method: 'GET',
    params
  }),

  // 获取状态列表
  getStatusList: params => ajax.request({
    url: '/mf-ctcl/api/admin/status/list',
    method: 'get',
    params
  }),

  // 获取机构列表
  getOrgList: params => ajax.request({
    url: '/mf-ctcl/api/admin/org/list',
    method: 'get',
    params
  }),
  // 添加参与人
  addParticipant: data => ajax.request({
    url: '/mf-ctcl/api/admin/user/add',
    method: 'POST',
    data
  }),
  // 获取知情同意书
  getSignImg: params => ajax.request({
    url: '/mf-ctcl/api/admin/patient/image',
    method: 'get',
    params
  }),
  // 获取表单信息
  getFormInfo: params => ajax.request({
    url: '/mf-ctcl/api/admin/form/info',
    method: 'get',
    params
  }),
  // 提交审核
  postAudit: data => ajax.request({
    url: '/mf-ctcl/api/admin/check/submit',
    method: 'POST',
    data
  }),
  // 审核表单
  postAuditData: data => ajax.request({
    url: '/mf-ctcl/api/admin/check/handle',
    method: 'POST',
    data
  }),
  // 上传图片
  uploadImg: data => ajax.request({
    data,
    method: 'post',
    url: '/mf-ctcl/api/admin/attachment/image',
    timeout: 50000,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),

  // 导出
  exportAgreement: params => ajax.request({
    url: '/mf-ctcl/api/admin/export-agreement',
    method: 'get',
    params
  }),

  // 导出问卷数据
  exportSurveyData: params => ajax.request({
    url: '/mf-ctcl/api/admin/export-data',
    method: 'get',
    params
  }),

  // 获取地址
  getSurveyDataLink: params => {
    // debugger
    return ajax.request({
      url: '/mf-ctcl/api/admin/export-check',
      method: 'get',
      params
    }, {
      isShowErrorMessage: false,
      isSkipAllResponseTransform: true
    // isReturnNativeResponse: true
    })
  },

  // 操作日志
  getOptLog: params => ajax.request({
    url: '/mf-ctcl/api/admin/log',
    method: 'get',
    params
  }),

  /**
   * 表单相关接口
  */
  // 获取配置
  // getSurveyInfo: params => aj
  // 获取表单结构
  preInspect: params => ajax.request({
    url: '/mf-ctcl/api/admin/questionnaire/inspect-and-fetch',
    params,
    method: 'get'
  }),
  // 获取快照
  fetchFormDraft: params => ajax.request({
    url: '/mf-ctcl/api/admin/questionnaire/snapshot-data',
    params,
    method: 'get'
  }),
  // 提交快照
  postFormDraftData: data => ajax.request({
    url: '/mf-ctcl/api/admin/questionnaire/snapshot-data',
    data,
    method: 'post'
  }),
  // 获取答案
  getFormDataDetail: params => ajax.request({
    url: '/mf-ctcl/api/admin/questionnaire/data-detail',
    params,
    method: 'get'
  }),
  // 提交答案
  postFormDetailData: data => ajax.request({
    url: '/mf-ctcl/api/admin/questionnaire/submit-data',
    data,
    method: 'post'
  }),
  // 修改答案
  postFormModify: data => ajax.request({
    url: '/mf-ctcl/api/admin/questionnaire/modify',
    data,
    method: 'post'
  })
}

export default apiMf
