var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _vm.previewCode
        ? _c("van-notice-bar", {
            attrs: {
              "left-icon": "volume-o",
              text: "当前处于预览模式～～",
              scrollable: "",
            },
          })
        : _vm._e(),
      _vm.formProps.isShowProgress
        ? _c("div", { staticClass: "step-status" }, [
            _c("span", { staticClass: "step-status--cur" }, [
              _vm._v(
                "当前 第" +
                  _vm._s(
                    _vm.formProps.paginationInfo &&
                      _vm.formProps.paginationInfo.curPage + 1
                  ) +
                  "页"
              ),
            ]),
            _c("span", { staticClass: "step-status--total" }, [
              _vm._v(
                "共" +
                  _vm._s(
                    _vm.formProps.paginationInfo &&
                      _vm.formProps.paginationInfo.total
                  ) +
                  "页"
              ),
            ]),
          ])
        : _vm._e(),
      _c("YLMobileForm", {
        attrs: {
          schema: _vm.schema,
          "form-props": _vm.formProps,
          "form-footer": _vm.formFooter,
          "error-schema": _vm.errorSchema,
        },
        on: {
          "on-submit": _vm.submitForm,
          "on-validation-failed": _vm.scrollToFirstError,
          "on-page-change": _vm.handleCurPageChange,
          "on-form-values-change": _vm.handleFormValuesChange,
        },
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }