<template>
  <div>
    <el-card class="stats_report_card">
      <div
        v-for="(item, index) in reportList"
        :key="index"
        class="stats_report_card__box"
      >
        <div>
          <span>{{ item.sequence }}.{{ item.metaLabel }}</span>
          <span
            v-if="!item.activeMetaKey"
            class="expire"
          >
            该题目在最新问卷中已删除
          </span>
        </div>
        <p>回答人数 {{ item.headCount }}</p>
        <p>回答次数 {{ item.count }}</p>
        <div class="stats_report_card__box__table">
          <template v-if="item.extType === 1">
            <el-table
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="item.data.dataMetas"
              style="width: 100%;"
              :header-cell-style="{ background: '#F8F8F8', color: '#333' }"
            >
              <el-table-column
                v-for="(head, headIndex) in item.data.tableHead"
                :key="headIndex"
                :prop="head.prop"
                :label="head.label"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row[head.prop] }} <span
                      v-if="headIndex == 0 && !scope.row.activeDataMeta"
                      class="delete-color"
                    >选项在最新问卷中已删除</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
    </el-card>
    <div
      v-if="page <= total"
      class="load_more"
    >
      <el-button
        type="primary"
        size="medium"
        plain
        @click="loadMore"
      >
        加载更多
      </el-button>
    </div>
  </div>
</template>
<script>
import apiCommon from '@/api/common'

export default {
  data () {
    return {
      page: 1,
      reportList: [],
      total: 0,
      loading: false
    }
  },
  created () {
    this.getStatReport()
  },
  methods: {
    setData () {
      this.reportList = []
      this.page = 1
      this.getStatReport()
    },
    async getStatReport () {
      if (this.page > 0) {
        this.loading = true
        const params = {
          page: this.page,
          size: 10
        }

        const res = await apiCommon.getSurveyAnalysis(params)
        if (res.success) {
          this.reportList = [...this.reportList, ...res.data]
          this.total = res.paging.pages
          if (this.reportList) {
            this.page = this.page + 1
          } else {
            this.page = 0
          }
        }
        this.loading = false
      }
    },
    loadMore () {
      this.getStatReport()
    }
  }
}
</script>
<style lang="scss" scoped>
.stats_report_card {
  &__box {
    line-height: 30px;

    p {
      font-size: 14px;
    }

    &__table {
      margin-bottom: 20px;
    }
  }
}

.load_more {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.expire {
  width: 168px;
  height: 22px;
  margin-left: 10px;
  background: rgba(255, 91, 2, .1);
  border-radius: 4px;
  font-size: 12px;
  color: #ff5b02;
  line-height: 22px;
  text-align: center;
}

.delete-color {
  font-size: 12px;
  color: #ff5b02;
  margin-left: 4px;
}
</style>
